"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LeaveVaultUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const DeleteExternalSharedVault_1 = require("./DeleteExternalSharedVault");
class LeaveVaultUseCase {
    constructor(vaultUserServer, items, mutator, encryption, storage, sync) {
        this.vaultUserServer = vaultUserServer;
        this.items = items;
        this.mutator = mutator;
        this.encryption = encryption;
        this.storage = storage;
        this.sync = sync;
    }
    async execute(params) {
        const latestVaultListing = this.items.findItem(params.sharedVault.uuid);
        if (!latestVaultListing) {
            throw new Error(`LeaveVaultUseCase: Could not find vault ${params.sharedVault.uuid}`);
        }
        const response = await this.vaultUserServer.deleteSharedVaultUser({
            sharedVaultUuid: latestVaultListing.sharing.sharedVaultUuid,
            userUuid: params.userUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to leave vault ${JSON.stringify(response)}`);
        }
        const removeLocalItems = new DeleteExternalSharedVault_1.DeleteExternalSharedVaultUseCase(this.items, this.mutator, this.encryption, this.storage, this.sync);
        await removeLocalItems.execute(latestVaultListing);
    }
}
exports.LeaveVaultUseCase = LeaveVaultUseCase;
