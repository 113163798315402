"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSharedVaultUsersUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
class GetSharedVaultUsersUseCase {
    constructor(vaultUsersServer) {
        this.vaultUsersServer = vaultUsersServer;
    }
    async execute(params) {
        const response = await this.vaultUsersServer.getSharedVaultUsers({ sharedVaultUuid: params.sharedVaultUuid });
        if ((0, responses_1.isErrorResponse)(response)) {
            return undefined;
        }
        return response.data.users;
    }
}
exports.GetSharedVaultUsersUseCase = GetSharedVaultUsersUseCase;
