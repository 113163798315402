"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionController = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
class SubscriptionController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.subscriptionInvitations = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    constructor(application, eventBus, subscriptionManager) {
        super(application, eventBus);
        this.subscriptionManager = subscriptionManager;
        this.ALLOWED_SUBSCRIPTION_INVITATIONS = 5;
        this.subscriptionInvitations = undefined;
        this.onlineSubscription = undefined;
        this.hasAccount = application.hasAccount();
        (0, mobx_1.makeObservable)(this, {
            subscriptionInvitations: mobx_1.observable,
            hasAccount: mobx_1.observable,
            onlineSubscription: mobx_1.observable,
            hasFirstPartyOnlineOrOfflineSubscription: mobx_1.computed,
            usedInvitationsCount: mobx_1.computed,
            allowedInvitationsCount: mobx_1.computed,
            allInvitationsUsed: mobx_1.computed,
        });
        this.disposers.push(application.addEventObserver(async () => {
            if (application.hasAccount()) {
                this.reloadSubscriptionInvitations().catch(console.error);
            }
            (0, mobx_1.runInAction)(() => {
                this.hasAccount = application.hasAccount();
            });
        }, snjs_1.ApplicationEvent.Launched));
        this.disposers.push(application.addEventObserver(async () => {
            this.reloadSubscriptionInvitations().catch(console.error);
            (0, mobx_1.runInAction)(() => {
                this.hasAccount = application.hasAccount();
            });
        }, snjs_1.ApplicationEvent.SignedIn));
        this.disposers.push(application.subscriptions.addEventObserver(async (event) => {
            if (event === snjs_1.SubscriptionManagerEvent.DidFetchSubscription) {
                (0, mobx_1.runInAction)(() => {
                    this.onlineSubscription = application.subscriptions.getOnlineSubscription();
                });
            }
        }));
        this.disposers.push(application.addEventObserver(async () => {
            this.reloadSubscriptionInvitations().catch(console.error);
        }, snjs_1.ApplicationEvent.UserRolesChanged));
    }
    get hasFirstPartyOnlineOrOfflineSubscription() {
        if (this.application.sessions.isSignedIn()) {
            if (!this.application.sessions.isSignedIntoFirstPartyServer()) {
                return false;
            }
            return this.application.subscriptions.getOnlineSubscription() !== undefined;
        }
        else {
            return this.application.features.hasFirstPartyOfflineSubscription();
        }
    }
    get usedInvitationsCount() {
        var _a, _b;
        return ((_b = (_a = this.subscriptionInvitations) === null || _a === void 0 ? void 0 : _a.filter((invitation) => [snjs_1.InvitationStatus.Accepted, snjs_1.InvitationStatus.Sent].includes(invitation.status)).length) !== null && _b !== void 0 ? _b : 0);
    }
    get allowedInvitationsCount() {
        return this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    get allInvitationsUsed() {
        return this.usedInvitationsCount === this.ALLOWED_SUBSCRIPTION_INVITATIONS;
    }
    async sendSubscriptionInvitation(inviteeEmail) {
        const success = await this.subscriptionManager.inviteToSubscription(inviteeEmail);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async cancelSubscriptionInvitation(invitationUuid) {
        const success = await this.subscriptionManager.cancelInvitation(invitationUuid);
        if (success) {
            await this.reloadSubscriptionInvitations();
        }
        return success;
    }
    async reloadSubscriptionInvitations() {
        this.subscriptionInvitations = await this.subscriptionManager.listSubscriptionInvitations();
    }
}
exports.SubscriptionController = SubscriptionController;
