"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getAdjustedStylesForNonPortalPopover = void 0;
const getAbsolutePositionedParent_1 = require("./getAbsolutePositionedParent");
const getAdjustedStylesForNonPortalPopover = (popoverElement, styles, parent) => {
    const absoluteParent = parent || (0, getAbsolutePositionedParent_1.getAbsolutePositionedParent)(popoverElement);
    const translateXProperty = styles === null || styles === void 0 ? void 0 : styles['--translate-x'];
    const translateYProperty = styles === null || styles === void 0 ? void 0 : styles['--translate-y'];
    const parsedTranslateX = translateXProperty ? parseInt(translateXProperty) : 0;
    const parsedTranslateY = translateYProperty ? parseInt(translateYProperty) : 0;
    if (!absoluteParent) {
        return styles;
    }
    const parentRect = absoluteParent.getBoundingClientRect();
    const adjustedTranslateX = parsedTranslateX - parentRect.left;
    const adjustedTranslateY = parsedTranslateY - parentRect.top;
    return {
        ...styles,
        '--translate-x': `${adjustedTranslateX}px`,
        '--translate-y': `${adjustedTranslateY}px`,
    };
};
exports.getAdjustedStylesForNonPortalPopover = getAdjustedStylesForNonPortalPopover;
