"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FindTrustedContactUseCase = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class FindTrustedContactUseCase {
    constructor(items) {
        this.items = items;
    }
    execute(query) {
        if ('userUuid' in query && query.userUuid) {
            return this.items.itemsMatchingPredicate(domain_core_1.ContentType.TYPES.TrustedContact, new models_1.Predicate('contactUuid', '=', query.userUuid))[0];
        }
        if ('signingPublicKey' in query && query.signingPublicKey) {
            const allContacts = this.items.getItems(domain_core_1.ContentType.TYPES.TrustedContact);
            return allContacts.find((contact) => contact.isSigningKeyTrusted(query.signingPublicKey));
        }
        if ('publicKey' in query && query.publicKey) {
            const allContacts = this.items.getItems(domain_core_1.ContentType.TYPES.TrustedContact);
            return allContacts.find((contact) => contact.isPublicKeyTrusted(query.publicKey));
        }
        throw new Error('Invalid query');
    }
}
exports.FindTrustedContactUseCase = FindTrustedContactUseCase;
