"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Pill = exports.LinkButton = exports.Text = exports.SubtitleLight = exports.Subtitle = exports.Title = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const Button_1 = require("@/Components/Button/Button");
const utils_1 = require("@standardnotes/utils");
const Title = ({ children, className }) => ((0, jsx_runtime_1.jsx)("h2", { className: (0, utils_1.classNames)('m-0 mb-1 text-lg font-bold text-info md:text-base', className), children: children }));
exports.Title = Title;
const Subtitle = ({ children, className }) => ((0, jsx_runtime_1.jsx)("h4", { className: (0, utils_1.classNames)('m-0 mb-1 text-base font-medium lg:text-sm', className), children: children }));
exports.Subtitle = Subtitle;
const SubtitleLight = ({ children, className }) => ((0, jsx_runtime_1.jsx)("h4", { className: (0, utils_1.classNames)('m-0 mb-1 text-base font-normal lg:text-sm', className), children: children }));
exports.SubtitleLight = SubtitleLight;
const Text = ({ children, className }) => ((0, jsx_runtime_1.jsx)("p", { className: (0, utils_1.classNames)('text-base lg:text-xs', className), children: children }));
exports.Text = Text;
const buttonClasses = 'block bg-passive-5 text-text rounded border-solid \
border px-4 py-1.5 font-bold text-base lg:text-sm w-fit \
focus:bg-contrast hover:bg-contrast border-border';
const LinkButton = ({ label, link, className, onClick }) => ((0, jsx_runtime_1.jsx)("a", { target: "_blank", className: `${className} ${buttonClasses}`, href: link, onClick: onClick, children: label }));
exports.LinkButton = LinkButton;
const Pill = ({ children, className, style }) => {
    const colorClass = (0, Button_1.getColorsForPrimaryVariant)(style);
    return ((0, jsx_runtime_1.jsx)("div", { className: (0, utils_1.classNames)('ml-2 rounded px-2 py-1 text-[10px] font-bold', className, colorClass), children: children }));
};
exports.Pill = Pill;
