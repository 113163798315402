"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileWebReceiver = void 0;
const snjs_1 = require("@standardnotes/snjs");
class MobileWebReceiver {
    constructor(application) {
        this.application = application;
        this.listeners = new Set();
        this.handleNativeMobileWindowMessage = (event) => {
            const nullOrigin = event.origin === '' || event.origin == null;
            if (!nullOrigin) {
                return;
            }
            const message = event.data;
            try {
                const parsed = JSON.parse(message);
                const { messageType, reactNativeEvent, messageData } = parsed;
                if (messageType === 'event' && reactNativeEvent) {
                    const nativeEvent = reactNativeEvent;
                    this.handleNativeEvent(nativeEvent, messageData);
                }
            }
            catch (error) {
                // eslint-disable-next-line no-console
                console.log('[MobileWebReceiver] Error parsing message from React Native', error);
            }
        };
        this.addReactListener = (listener) => {
            this.listeners.add(listener);
            return () => {
                this.listeners.delete(listener);
            };
        };
        this.listenForNativeMobileEvents();
    }
    deinit() {
        ;
        this.application = undefined;
        window.removeEventListener('message', this.handleNativeMobileWindowMessage);
        document.removeEventListener('message', this.handleNativeMobileWindowMessage);
    }
    listenForNativeMobileEvents() {
        const iOSEventRecipient = window;
        const androidEventRecipient = document;
        iOSEventRecipient.addEventListener('message', this.handleNativeMobileWindowMessage);
        androidEventRecipient.addEventListener('message', this.handleNativeMobileWindowMessage);
    }
    handleNativeEvent(event, messageData) {
        switch (event) {
            case snjs_1.ReactNativeToWebEvent.EnteringBackground:
                void this.application.handleMobileEnteringBackgroundEvent();
                break;
            case snjs_1.ReactNativeToWebEvent.GainingFocus:
                void this.application.handleMobileGainingFocusEvent();
                break;
            case snjs_1.ReactNativeToWebEvent.LosingFocus:
                void this.application.handleMobileLosingFocusEvent();
                break;
            case snjs_1.ReactNativeToWebEvent.ResumingFromBackground:
                void this.application.handleMobileResumingFromBackgroundEvent();
                break;
            case snjs_1.ReactNativeToWebEvent.AndroidBackButtonPressed:
                void this.application.handleAndroidBackButtonPressed();
                break;
            case snjs_1.ReactNativeToWebEvent.ColorSchemeChanged:
                void this.application.handleMobileColorSchemeChangeEvent();
                break;
            case snjs_1.ReactNativeToWebEvent.KeyboardFrameWillChange:
                void this.application.handleMobileKeyboardWillChangeFrameEvent(messageData);
                break;
            case snjs_1.ReactNativeToWebEvent.KeyboardFrameDidChange:
                void this.application.handleMobileKeyboardDidChangeFrameEvent(messageData);
                break;
            case snjs_1.ReactNativeToWebEvent.ReceivedFile:
                void this.application.handleReceivedFileEvent(messageData);
                break;
            case snjs_1.ReactNativeToWebEvent.ReceivedText:
                void this.application.handleReceivedTextEvent(messageData);
                break;
            default:
                break;
        }
        this.listeners.forEach((listener) => listener(event));
    }
}
exports.MobileWebReceiver = MobileWebReceiver;
