"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DevMode = void 0;
const snjs_1 = require("@standardnotes/snjs");
class DevMode {
    constructor(application) {
        this.application = application;
        snjs_1.InternalFeatureService.get().enableFeature(snjs_1.InternalFeature.Vaults);
        snjs_1.InternalFeatureService.get().enableFeature(snjs_1.InternalFeature.HomeServer);
    }
    /** Valid only when running a mock event publisher on port 3124 */
    async purchaseMockSubscription() {
        var _a;
        const subscriptionId = 2002;
        const email = (_a = this.application.getUser()) === null || _a === void 0 ? void 0 : _a.email;
        const response = await fetch('http://localhost:3124/events', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                eventType: 'SUBSCRIPTION_PURCHASED',
                eventPayload: {
                    userEmail: email,
                    subscriptionId: subscriptionId,
                    subscriptionName: 'PRO_PLAN',
                    subscriptionExpiresAt: (new Date().getTime() + 3600000) * 1000,
                    timestamp: Date.now(),
                    offline: false,
                    discountCode: null,
                    limitedDiscountPurchased: false,
                    newSubscriber: true,
                    totalActiveSubscriptionsCount: 1,
                    userRegisteredAt: 1,
                    billingFrequency: 12,
                    payAmount: 59.0,
                },
            }),
        });
        if (!response.ok) {
            console.error(`Failed to publish mocked event: ${response.status} ${response.statusText}`);
        }
    }
}
exports.DevMode = DevMode;
