"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendAsymmetricMessageUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
class SendAsymmetricMessageUseCase {
    constructor(messageServer) {
        this.messageServer = messageServer;
    }
    async execute(params) {
        const response = await this.messageServer.createMessage({
            recipientUuid: params.recipientUuid,
            encryptedMessage: params.encryptedMessage,
            replaceabilityIdentifier: params.replaceabilityIdentifier,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromNetworkError(response);
        }
        return response.data.message;
    }
}
exports.SendAsymmetricMessageUseCase = SendAsymmetricMessageUseCase;
