"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrustedContact = void 0;
const Item_1 = require("../../Abstract/Item");
const ContactPublicKeySet_1 = require("./PublicKeySet/ContactPublicKeySet");
const Predicate_1 = require("../../Runtime/Predicate/Predicate");
class TrustedContact extends Item_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.name = payload.content.name;
        this.contactUuid = payload.content.contactUuid;
        this.publicKeySet = ContactPublicKeySet_1.ContactPublicKeySet.FromJson(payload.content.publicKeySet);
        this.isMe = payload.content.isMe;
    }
    get isSingleton() {
        return true;
    }
    singletonPredicate() {
        return TrustedContact.singletonPredicate;
    }
    findKeySet(params) {
        const set = this.publicKeySet.findKeySet(params);
        if (!set) {
            return undefined;
        }
        return {
            publicKeySet: set,
            current: set === this.publicKeySet,
        };
    }
    isPublicKeyTrusted(encryptionPublicKey) {
        const keySet = this.publicKeySet.findKeySetWithPublicKey(encryptionPublicKey);
        if (keySet && !keySet.isRevoked) {
            return true;
        }
        return false;
    }
    isSigningKeyTrusted(signingKey) {
        const keySet = this.publicKeySet.findKeySetWithSigningKey(signingKey);
        if (keySet && !keySet.isRevoked) {
            return true;
        }
        return false;
    }
    strategyWhenConflictingWithItem(_item, _previousRevision) {
        return Item_1.ConflictStrategy.KeepBase;
    }
}
TrustedContact.singletonPredicate = new Predicate_1.Predicate('isMe', '=', true);
exports.TrustedContact = TrustedContact;
