"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RotateVaultRootKeyUseCase = void 0;
const utils_1 = require("@standardnotes/utils");
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
class RotateVaultRootKeyUseCase {
    constructor(mutator, encryption) {
        this.mutator = mutator;
        this.encryption = encryption;
    }
    async execute(params) {
        const currentRootKey = this.encryption.keys.getPrimaryKeySystemRootKey(params.vault.systemIdentifier);
        if (!currentRootKey) {
            throw new Error('Cannot rotate key system root key; key system root key not found');
        }
        let newRootKey;
        if (currentRootKey.keyParams.passwordType === models_1.KeySystemRootKeyPasswordType.UserInputted) {
            if (!params.userInputtedPassword) {
                throw new Error('Cannot rotate key system root key; user inputted password required');
            }
            newRootKey = this.encryption.createUserInputtedKeySystemRootKey({
                systemIdentifier: params.vault.systemIdentifier,
                userInputtedPassword: params.userInputtedPassword,
            });
        }
        else if (currentRootKey.keyParams.passwordType === models_1.KeySystemRootKeyPasswordType.Randomized) {
            newRootKey = this.encryption.createRandomizedKeySystemRootKey({
                systemIdentifier: params.vault.systemIdentifier,
            });
        }
        if (!newRootKey) {
            throw new Error('Cannot rotate key system root key; new root key not created');
        }
        if (params.vault.keyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            await this.mutator.insertItem(newRootKey, true);
        }
        else {
            this.encryption.keys.intakeNonPersistentKeySystemRootKey(newRootKey, params.vault.keyStorageMode);
        }
        await this.mutator.changeItem(params.vault, (mutator) => {
            (0, utils_1.assert)(newRootKey);
            mutator.rootKeyParams = newRootKey.keyParams;
        });
        const errors = [];
        const updateKeySystemItemsKeyResult = await this.createNewKeySystemItemsKey({
            keySystemIdentifier: params.vault.systemIdentifier,
            sharedVaultUuid: params.sharedVaultUuid,
            rootKeyToken: newRootKey.token,
        });
        if ((0, responses_1.isClientDisplayableError)(updateKeySystemItemsKeyResult)) {
            errors.push(updateKeySystemItemsKeyResult);
        }
        await this.encryption.reencryptKeySystemItemsKeysForVault(params.vault.systemIdentifier);
        return errors;
    }
    async createNewKeySystemItemsKey(params) {
        const newItemsKeyUuid = utils_1.UuidGenerator.GenerateUuid();
        const newItemsKey = this.encryption.createKeySystemItemsKey(newItemsKeyUuid, params.keySystemIdentifier, params.sharedVaultUuid, params.rootKeyToken);
        await this.mutator.insertItem(newItemsKey);
    }
}
exports.RotateVaultRootKeyUseCase = RotateVaultRootKeyUseCase;
