"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const mergeRefs_1 = require("@/Hooks/mergeRefs");
const react_1 = require("@ariakit/react");
const react_2 = require("react");
const useModalAnimation_1 = require("../Modal/useModalAnimation");
const ModalOverlay = (0, react_2.forwardRef)(({ isOpen, children, animationVariant, close, ...props }, ref) => {
    const [isMounted, setElement] = (0, useModalAnimation_1.useModalAnimation)(isOpen, animationVariant);
    const dialog = (0, react_1.useDialogStore)({
        open: isMounted,
        setOpen: (open) => {
            if (!open) {
                close();
            }
        },
    });
    const addCloseMethod = (0, react_2.useCallback)((element) => {
        if (element) {
            ;
            element.close = close;
        }
    }, [close]);
    if (!isMounted) {
        return null;
    }
    return ((0, jsx_runtime_1.jsx)(react_1.Dialog, { tabIndex: 0, className: "fixed left-0 top-0 z-modal h-full w-full", ref: (0, mergeRefs_1.mergeRefs)([setElement, addCloseMethod, ref]), store: dialog, modal: false, portal: true, preventBodyScroll: true, ...props, children: children }));
});
exports.default = ModalOverlay;
