"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserApiService = void 0;
const ErrorMessage_1 = require("../../Error/ErrorMessage");
const ApiCallError_1 = require("../../Error/ApiCallError");
const ApiVersion_1 = require("../../Api/ApiVersion");
const responses_1 = require("@standardnotes/responses");
const UserApiOperations_1 = require("./UserApiOperations");
class UserApiService {
    constructor(userServer, userRequestServer) {
        this.userServer = userServer;
        this.userRequestServer = userRequestServer;
        this.operationsInProgress = new Map();
    }
    deleteAccount(userUuid) {
        return __awaiter(this, void 0, void 0, function* () {
            this.lockOperation(UserApiOperations_1.UserApiOperations.DeletingAccount);
            try {
                const response = yield this.userServer.deleteAccount({
                    userUuid: userUuid,
                });
                this.unlockOperation(UserApiOperations_1.UserApiOperations.DeletingAccount);
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
        });
    }
    submitUserRequest(dto) {
        return __awaiter(this, void 0, void 0, function* () {
            this.lockOperation(UserApiOperations_1.UserApiOperations.SubmittingRequest);
            try {
                const response = yield this.userRequestServer.submitUserRequest({
                    userUuid: dto.userUuid,
                    requestType: dto.requestType,
                });
                this.unlockOperation(UserApiOperations_1.UserApiOperations.SubmittingRequest);
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericRegistrationFail);
            }
        });
    }
    register(registerDTO) {
        return __awaiter(this, void 0, void 0, function* () {
            this.lockOperation(UserApiOperations_1.UserApiOperations.Registering);
            try {
                const response = yield this.userServer.register(Object.assign({ [responses_1.ApiEndpointParam.ApiVersion]: ApiVersion_1.ApiVersion.v0, password: registerDTO.serverPassword, email: registerDTO.email, ephemeral: registerDTO.ephemeral }, registerDTO.keyParams.getPortableValue()));
                this.unlockOperation(UserApiOperations_1.UserApiOperations.Registering);
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericRegistrationFail);
            }
        });
    }
    updateUser(updateDTO) {
        return __awaiter(this, void 0, void 0, function* () {
            this.lockOperation(UserApiOperations_1.UserApiOperations.UpdatingUser);
            try {
                const response = yield this.userServer.update({
                    [responses_1.ApiEndpointParam.ApiVersion]: ApiVersion_1.ApiVersion.v0,
                    user_uuid: updateDTO.userUuid,
                });
                this.unlockOperation(UserApiOperations_1.UserApiOperations.UpdatingUser);
                return response;
            }
            catch (error) {
                throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericFail);
            }
        });
    }
    lockOperation(operation) {
        if (this.operationsInProgress.get(operation)) {
            throw new ApiCallError_1.ApiCallError(ErrorMessage_1.ErrorMessage.GenericInProgress);
        }
        this.operationsInProgress.set(operation, true);
    }
    unlockOperation(operation) {
        this.operationsInProgress.set(operation, false);
    }
}
exports.UserApiService = UserApiService;
