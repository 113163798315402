"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ItemsEncryptionService = void 0;
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const AbstractService_1 = require("../Service/AbstractService");
const domain_core_1 = require("@standardnotes/domain-core");
class ItemsEncryptionService extends AbstractService_1.AbstractService {
    constructor(itemManager, payloadManager, storageService, operatorManager, keys, internalEventBus) {
        super(internalEventBus);
        this.itemManager = itemManager;
        this.payloadManager = payloadManager;
        this.storageService = storageService;
        this.operatorManager = operatorManager;
        this.keys = keys;
        this.internalEventBus = internalEventBus;
        this.removeItemsObserver = this.itemManager.addObserver([domain_core_1.ContentType.TYPES.ItemsKey], ({ changed, inserted }) => {
            if (changed.concat(inserted).length > 0) {
                void this.decryptErroredItemPayloads();
            }
        });
    }
    deinit() {
        ;
        this.itemManager = undefined;
        this.payloadManager = undefined;
        this.storageService = undefined;
        this.operatorManager = undefined;
        this.keys = undefined;
        this.removeItemsObserver();
        this.removeItemsObserver = undefined;
        super.deinit();
    }
    /**
     * If encryption status changes (esp. on mobile, where local storage encryption
     * can be disabled), consumers may call this function to repersist all items to
     * disk using latest encryption status.
     */
    async repersistAllItems() {
        const items = this.itemManager.items;
        const payloads = items.map((item) => item.payload);
        return this.storageService.savePayloads(payloads);
    }
    getItemsKeys() {
        return this.itemManager.getDisplayableItemsKeys();
    }
    itemsKeyForEncryptedPayload(payload) {
        const itemsKeys = this.getItemsKeys();
        const keySystemItemsKeys = this.itemManager.getItems(domain_core_1.ContentType.TYPES.KeySystemItemsKey);
        return [...itemsKeys, ...keySystemItemsKeys].find((key) => key.uuid === payload.items_key_id || key.duplicateOf === payload.items_key_id);
    }
    getDefaultItemsKey() {
        return (0, encryption_1.findDefaultItemsKey)(this.getItemsKeys());
    }
    keyToUseForItemEncryption(payload) {
        if (payload.key_system_identifier) {
            const keySystemItemsKey = this.keys.getPrimaryKeySystemItemsKey(payload.key_system_identifier);
            if (!keySystemItemsKey) {
                return new encryption_1.StandardException('Cannot find key system items key to use for encryption');
            }
            return keySystemItemsKey;
        }
        const defaultKey = this.getDefaultItemsKey();
        let result = undefined;
        if (this.userVersion && this.userVersion !== (defaultKey === null || defaultKey === void 0 ? void 0 : defaultKey.keyVersion)) {
            /**
             * The default key appears to be either newer or older than the user's account version
             * We could throw an exception here, but will instead fall back to a corrective action:
             * return any items key that corresponds to the user's version
             */
            const itemsKeys = this.getItemsKeys();
            result = itemsKeys.find((key) => key.keyVersion === this.userVersion);
        }
        else {
            result = defaultKey;
        }
        if (!result) {
            return new encryption_1.StandardException('Cannot find items key to use for encryption');
        }
        return result;
    }
    keyToUseForDecryptionOfPayload(payload) {
        if (payload.items_key_id) {
            const itemsKey = this.itemsKeyForEncryptedPayload(payload);
            return itemsKey;
        }
        const defaultKey = this.defaultItemsKeyForItemVersion(payload.version);
        return defaultKey;
    }
    async encryptPayloadWithKeyLookup(payload, signingKeyPair) {
        const key = this.keyToUseForItemEncryption(payload);
        if (key instanceof encryption_1.StandardException) {
            throw Error(key.message);
        }
        return this.encryptPayload(payload, key, signingKeyPair);
    }
    async encryptPayload(payload, key, signingKeyPair) {
        if ((0, models_1.isEncryptedPayload)(payload)) {
            throw Error('Attempting to encrypt already encrypted payload.');
        }
        if (!payload.content) {
            throw Error('Attempting to encrypt payload with no content.');
        }
        if (!payload.uuid) {
            throw Error('Attempting to encrypt payload with no UuidGenerator.');
        }
        return (0, encryption_1.encryptPayload)(payload, key, this.operatorManager, signingKeyPair);
    }
    async encryptPayloads(payloads, key, signingKeyPair) {
        return Promise.all(payloads.map((payload) => this.encryptPayload(payload, key, signingKeyPair)));
    }
    async encryptPayloadsWithKeyLookup(payloads, signingKeyPair) {
        return Promise.all(payloads.map((payload) => this.encryptPayloadWithKeyLookup(payload, signingKeyPair)));
    }
    async decryptPayloadWithKeyLookup(payload) {
        const key = this.keyToUseForDecryptionOfPayload(payload);
        if (key == undefined) {
            return {
                uuid: payload.uuid,
                errorDecrypting: true,
                waitingForKey: true,
            };
        }
        return this.decryptPayload(payload, key);
    }
    async decryptPayload(payload, key) {
        if (!payload.content) {
            return {
                uuid: payload.uuid,
                errorDecrypting: true,
            };
        }
        return (0, encryption_1.decryptPayload)(payload, key, this.operatorManager);
    }
    async decryptPayloadsWithKeyLookup(payloads) {
        return Promise.all(payloads.map((payload) => this.decryptPayloadWithKeyLookup(payload)));
    }
    async decryptPayloads(payloads, key) {
        return Promise.all(payloads.map((payload) => this.decryptPayload(payload, key)));
    }
    async decryptErroredItemPayloads() {
        const erroredItemPayloads = this.payloadManager.invalidPayloads.filter((i) => !(0, models_1.ContentTypeUsesRootKeyEncryption)(i.content_type) && !(0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(i.content_type));
        if (erroredItemPayloads.length === 0) {
            return;
        }
        const resultParams = await this.decryptPayloadsWithKeyLookup(erroredItemPayloads);
        const decryptedPayloads = resultParams.map((params) => {
            const original = (0, models_1.SureFindPayload)(erroredItemPayloads, params.uuid);
            if ((0, encryption_1.isErrorDecryptingParameters)(params)) {
                return new models_1.EncryptedPayload({
                    ...original.ejected(),
                    ...params,
                });
            }
            else {
                return new models_1.DecryptedPayload({
                    ...original.ejected(),
                    ...params,
                });
            }
        });
        await this.payloadManager.emitPayloads(decryptedPayloads, models_1.PayloadEmitSource.LocalChanged);
    }
    /**
     * When migrating from non-items key architecture, many items will not have a
     * relationship with any key object. For those items, we can be sure that only 1 key
     * object will correspond to that protocol version.
     * @returns The items key object to decrypt items encrypted
     * with previous protocol version.
     */
    defaultItemsKeyForItemVersion(version, fromKeys) {
        /** Try to find one marked default first */
        const searchKeys = fromKeys || this.getItemsKeys();
        const priorityKey = searchKeys.find((key) => {
            return key.isDefault && key.keyVersion === version;
        });
        if (priorityKey) {
            return priorityKey;
        }
        return searchKeys.find((key) => {
            return key.keyVersion === version;
        });
    }
}
exports.ItemsEncryptionService = ItemsEncryptionService;
