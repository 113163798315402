"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateVaultUseCase = void 0;
const utils_1 = require("@standardnotes/utils");
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class CreateVaultUseCase {
    constructor(mutator, encryption, sync) {
        this.mutator = mutator;
        this.encryption = encryption;
        this.sync = sync;
    }
    async execute(dto) {
        const keySystemIdentifier = utils_1.UuidGenerator.GenerateUuid();
        const rootKey = await this.createKeySystemRootKey({
            keySystemIdentifier,
            vaultName: dto.vaultName,
            vaultDescription: dto.vaultDescription,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: dto.storagePreference,
        });
        await this.createKeySystemItemsKey(keySystemIdentifier, rootKey.token);
        const vaultListing = await this.createVaultListing({
            keySystemIdentifier,
            vaultName: dto.vaultName,
            vaultDescription: dto.vaultDescription,
            passwordType: dto.userInputtedPassword
                ? models_1.KeySystemRootKeyPasswordType.UserInputted
                : models_1.KeySystemRootKeyPasswordType.Randomized,
            rootKeyParams: rootKey.keyParams,
            storage: dto.storagePreference,
        });
        await this.sync.sync();
        return vaultListing;
    }
    async createVaultListing(dto) {
        const content = {
            systemIdentifier: dto.keySystemIdentifier,
            rootKeyParams: dto.rootKeyParams,
            keyStorageMode: dto.storage,
            name: dto.vaultName,
            description: dto.vaultDescription,
        };
        return this.mutator.createItem(domain_core_1.ContentType.TYPES.VaultListing, (0, models_1.FillItemContentSpecialized)(content), true);
    }
    async createKeySystemItemsKey(keySystemIdentifier, rootKeyToken) {
        const keySystemItemsKey = this.encryption.createKeySystemItemsKey(utils_1.UuidGenerator.GenerateUuid(), keySystemIdentifier, undefined, rootKeyToken);
        await this.mutator.insertItem(keySystemItemsKey);
    }
    async createKeySystemRootKey(dto) {
        let newRootKey;
        if (dto.userInputtedPassword) {
            newRootKey = this.encryption.createUserInputtedKeySystemRootKey({
                systemIdentifier: dto.keySystemIdentifier,
                userInputtedPassword: dto.userInputtedPassword,
            });
        }
        else {
            newRootKey = this.encryption.createRandomizedKeySystemRootKey({
                systemIdentifier: dto.keySystemIdentifier,
            });
        }
        if (dto.storagePreference === models_1.KeySystemRootKeyStorageMode.Synced) {
            await this.mutator.insertItem(newRootKey, true);
        }
        else {
            this.encryption.keys.intakeNonPersistentKeySystemRootKey(newRootKey, dto.storagePreference);
        }
        return newRootKey;
    }
}
exports.CreateVaultUseCase = CreateVaultUseCase;
