"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlaintextConverter = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const filepicker_1 = require("@standardnotes/filepicker");
const Utils_1 = require("../Utils");
class PlaintextConverter {
    constructor(application) {
        this.application = application;
    }
    static isValidPlaintextFile(file) {
        return file.type === 'text/plain' || file.type === 'text/markdown';
    }
    async convertPlaintextFileToNote(file) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const { name } = (0, filepicker_1.parseFileName)(file.name);
        const createdAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
        const updatedAtDate = file.lastModified ? new Date(file.lastModified) : new Date();
        return {
            created_at: createdAtDate,
            created_at_timestamp: createdAtDate.getTime(),
            updated_at: updatedAtDate,
            updated_at_timestamp: updatedAtDate.getTime(),
            uuid: this.application.generateUUID(),
            content_type: domain_core_1.ContentType.TYPES.Note,
            content: {
                title: name,
                text: content,
                references: [],
            },
        };
    }
}
exports.PlaintextConverter = PlaintextConverter;
