"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateSharedVaultUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const CreateVault_1 = require("../../Vaults/UseCase/CreateVault");
const MoveItemsToVault_1 = require("../../Vaults/UseCase/MoveItemsToVault");
class CreateSharedVaultUseCase {
    constructor(encryption, items, mutator, sync, files, sharedVaultServer) {
        this.encryption = encryption;
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.files = files;
        this.sharedVaultServer = sharedVaultServer;
    }
    async execute(dto) {
        const usecase = new CreateVault_1.CreateVaultUseCase(this.mutator, this.encryption, this.sync);
        const privateVault = await usecase.execute({
            vaultName: dto.vaultName,
            vaultDescription: dto.vaultDescription,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: dto.storagePreference,
        });
        const serverResult = await this.sharedVaultServer.createSharedVault();
        if ((0, responses_1.isErrorResponse)(serverResult)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to create shared vault ${JSON.stringify(serverResult)}`);
        }
        const serverVaultHash = serverResult.data.sharedVault;
        const sharedVaultListing = await this.mutator.changeItem(privateVault, (mutator) => {
            mutator.sharing = {
                sharedVaultUuid: serverVaultHash.uuid,
                ownerUserUuid: serverVaultHash.user_uuid,
            };
        });
        const vaultItems = this.items.itemsBelongingToKeySystem(sharedVaultListing.systemIdentifier);
        const moveToVaultUsecase = new MoveItemsToVault_1.MoveItemsToVaultUseCase(this.mutator, this.sync, this.files);
        await moveToVaultUsecase.execute({ vault: sharedVaultListing, items: vaultItems });
        return sharedVaultListing;
    }
}
exports.CreateSharedVaultUseCase = CreateSharedVaultUseCase;
