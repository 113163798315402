"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricMessagesPaths = void 0;
exports.AsymmetricMessagesPaths = {
    createMessage: '/v1/asymmetric-messages',
    getMessages: '/v1/asymmetric-messages',
    updateMessage: (messageUuid) => `/v1/asymmetric-messages/${messageUuid}`,
    getInboundUserMessages: () => '/v1/asymmetric-messages',
    getOutboundUserMessages: () => '/v1/asymmetric-messages/outbound',
    deleteMessage: (messageUuid) => `/v1/asymmetric-messages/${messageUuid}`,
    deleteAllInboundMessages: '/v1/asymmetric-messages/inbound',
};
