"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricMessagePayloadType = void 0;
var AsymmetricMessagePayloadType;
(function (AsymmetricMessagePayloadType) {
    AsymmetricMessagePayloadType["ContactShare"] = "contact-share";
    AsymmetricMessagePayloadType["SharedVaultRootKeyChanged"] = "shared-vault-root-key-changed";
    AsymmetricMessagePayloadType["SenderKeypairChanged"] = "sender-keypair-changed";
    AsymmetricMessagePayloadType["SharedVaultMetadataChanged"] = "shared-vault-metadata-changed";
    /**
     * Shared Vault Invites conform to the asymmetric message protocol, but are sent via the dedicated
     * SharedVaultInvite model and not the AsymmetricMessage model on the server side.
     */
    AsymmetricMessagePayloadType["SharedVaultInvite"] = "shared-vault-invite";
})(AsymmetricMessagePayloadType = exports.AsymmetricMessagePayloadType || (exports.AsymmetricMessagePayloadType = {}));
