"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAsymmetricMessageUntrustedPayload = void 0;
class GetAsymmetricMessageUntrustedPayload {
    constructor(encryption) {
        this.encryption = encryption;
    }
    execute(dto) {
        const decryptionResult = this.encryption.asymmetricallyDecryptMessage({
            encryptedString: dto.message.encrypted_message,
            trustedSender: undefined,
            privateKey: dto.privateKey,
        });
        return decryptionResult;
    }
}
exports.GetAsymmetricMessageUntrustedPayload = GetAsymmetricMessageUntrustedPayload;
