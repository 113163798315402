"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UpdateTrustedContactUseCase = void 0;
class UpdateTrustedContactUseCase {
    constructor(mutator, sync) {
        this.mutator = mutator;
        this.sync = sync;
    }
    async execute(contact, params) {
        const updatedContact = await this.mutator.changeItem(contact, (mutator) => {
            mutator.name = params.name;
            if (params.publicKey !== contact.publicKeySet.encryption ||
                params.signingPublicKey !== contact.publicKeySet.signing) {
                mutator.addPublicKey({
                    encryption: params.publicKey,
                    signing: params.signingPublicKey,
                });
            }
        });
        await this.sync.sync();
        return updatedContact;
    }
}
exports.UpdateTrustedContactUseCase = UpdateTrustedContactUseCase;
