"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeManager = void 0;
const toast_1 = require("@standardnotes/toast");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const services_1 = require("@standardnotes/services");
const features_1 = require("@standardnotes/features");
const AbstractUIService_1 = require("../Abstract/AbstractUIService");
const GetAllThemesUseCase_1 = require("./GetAllThemesUseCase");
const CachedThemesKey = 'cachedThemes';
const TimeBeforeApplyingColorScheme = 5;
const DefaultThemeIdentifier = 'Default';
class ThemeManager extends AbstractUIService_1.AbstractUIServicee {
    constructor(application, preferences, components, internalEventBus) {
        super(application, internalEventBus);
        this.preferences = preferences;
        this.components = components;
        this.themesActiveInTheUI = [];
        this.lastUseDeviceThemeSettings = false;
        this.colorSchemeEventHandler = this.colorSchemeEventHandler.bind(this);
    }
    async onAppStart() {
        const desktopService = this.application.getDesktopService();
        if (desktopService) {
            this.eventDisposers.push(desktopService.registerUpdateObserver((component) => {
                const uiFeature = new models_1.UIFeature(component);
                if (uiFeature.isThemeComponent) {
                    if (this.components.isThemeActive(uiFeature)) {
                        this.deactivateThemeInTheUI(uiFeature.uniqueIdentifier);
                        setTimeout(() => {
                            this.activateTheme(uiFeature);
                            this.cacheThemeState().catch(console.error);
                        }, 10);
                    }
                }
            }));
        }
        this.eventDisposers.push(this.preferences.addEventObserver(async (event) => {
            var _a;
            if (event !== services_1.PreferencesServiceEvent.PreferencesChanged) {
                return;
            }
            let hasChange = false;
            const activeThemes = this.components.getActiveThemesIdentifiers();
            for (const uiActiveTheme of this.themesActiveInTheUI) {
                if (!activeThemes.includes(uiActiveTheme)) {
                    this.deactivateThemeInTheUI(uiActiveTheme);
                    hasChange = true;
                }
            }
            for (const activeTheme of activeThemes) {
                if (!this.themesActiveInTheUI.includes(activeTheme)) {
                    const theme = (_a = (0, features_1.FindNativeTheme)(activeTheme)) !== null && _a !== void 0 ? _a : this.application.items.findItem(activeTheme);
                    if (theme) {
                        const uiFeature = new models_1.UIFeature(theme);
                        this.activateTheme(uiFeature);
                        hasChange = true;
                    }
                }
            }
            if (hasChange) {
                this.cacheThemeState().catch(console.error);
            }
        }));
    }
    async onAppEvent(event) {
        var _a;
        switch (event) {
            case services_1.ApplicationEvent.SignedOut: {
                this.deactivateAllThemes();
                this.themesActiveInTheUI = [];
                (_a = this.application) === null || _a === void 0 ? void 0 : _a.removeValue(CachedThemesKey, services_1.StorageValueModes.Nonwrapped).catch(console.error);
                break;
            }
            case services_1.ApplicationEvent.StorageReady: {
                await this.activateCachedThemes();
                break;
            }
            case services_1.ApplicationEvent.FeaturesAvailabilityChanged: {
                this.handleFeaturesAvailabilityChanged();
                break;
            }
            case services_1.ApplicationEvent.Launched: {
                if (!this.application.isNativeMobileWeb()) {
                    const mq = window.matchMedia('(prefers-color-scheme: dark)');
                    if (mq.addEventListener != undefined) {
                        mq.addEventListener('change', this.colorSchemeEventHandler);
                    }
                    else {
                        mq.addListener(this.colorSchemeEventHandler);
                    }
                }
                break;
            }
            case services_1.ApplicationEvent.PreferencesChanged: {
                void this.handlePreferencesChangeEvent();
                break;
            }
        }
    }
    async handleMobileColorSchemeChangeEvent() {
        const useDeviceThemeSettings = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        if (useDeviceThemeSettings) {
            const prefersDarkColorScheme = (await this.application.mobileDevice().getColorScheme()) === 'dark';
            this.setThemeAsPerColorScheme(prefersDarkColorScheme);
        }
    }
    async handlePreferencesChangeEvent() {
        const useDeviceThemeSettings = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        const hasPreferenceChanged = useDeviceThemeSettings !== this.lastUseDeviceThemeSettings;
        if (hasPreferenceChanged) {
            this.lastUseDeviceThemeSettings = useDeviceThemeSettings;
        }
        if (hasPreferenceChanged && useDeviceThemeSettings) {
            let prefersDarkColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (this.application.isNativeMobileWeb()) {
                prefersDarkColorScheme = (await this.application.mobileDevice().getColorScheme()) === 'dark';
            }
            this.setThemeAsPerColorScheme(prefersDarkColorScheme);
        }
    }
    deinit() {
        this.themesActiveInTheUI = [];
        const mq = window.matchMedia('(prefers-color-scheme: dark)');
        if (mq.removeEventListener != undefined) {
            mq.removeEventListener('change', this.colorSchemeEventHandler);
        }
        else {
            mq.removeListener(this.colorSchemeEventHandler);
        }
        super.deinit();
    }
    handleFeaturesAvailabilityChanged() {
        let hasChange = false;
        for (const themeUuid of this.themesActiveInTheUI) {
            const theme = this.application.items.findItem(themeUuid);
            if (!theme) {
                this.deactivateThemeInTheUI(themeUuid);
                hasChange = true;
                continue;
            }
            const status = this.application.features.getFeatureStatus(theme.identifier);
            if (status !== services_1.FeatureStatus.Entitled) {
                this.deactivateThemeInTheUI(theme.uuid);
                hasChange = true;
            }
        }
        const activeThemes = this.components.getActiveThemes();
        for (const theme of activeThemes) {
            if (!this.themesActiveInTheUI.includes(theme.uniqueIdentifier)) {
                this.activateTheme(theme);
                hasChange = true;
            }
        }
        if (hasChange) {
            void this.cacheThemeState();
        }
    }
    colorSchemeEventHandler(event) {
        const shouldChangeTheme = this.application.getPreference(models_1.PrefKey.UseSystemColorScheme, false);
        if (shouldChangeTheme) {
            this.setThemeAsPerColorScheme(event.matches);
        }
    }
    showColorSchemeToast(setThemeCallback) {
        const [toastId, intervalId] = (0, toast_1.addTimedToast)({
            type: toast_1.ToastType.Regular,
            message: (timeRemaining) => `Applying system color scheme in ${timeRemaining}s...`,
            actions: [
                {
                    label: 'Keep current theme',
                    handler: () => {
                        (0, toast_1.dismissToast)(toastId);
                        clearInterval(intervalId);
                    },
                },
                {
                    label: 'Apply now',
                    handler: () => {
                        (0, toast_1.dismissToast)(toastId);
                        clearInterval(intervalId);
                        setThemeCallback();
                    },
                },
            ],
        }, setThemeCallback, TimeBeforeApplyingColorScheme);
    }
    setThemeAsPerColorScheme(prefersDarkColorScheme) {
        const preference = prefersDarkColorScheme ? models_1.PrefKey.AutoDarkThemeIdentifier : models_1.PrefKey.AutoLightThemeIdentifier;
        const preferenceDefault = preference === models_1.PrefKey.AutoDarkThemeIdentifier ? features_1.FeatureIdentifier.DarkTheme : DefaultThemeIdentifier;
        const usecase = new GetAllThemesUseCase_1.GetAllThemesUseCase(this.application.items);
        const { thirdParty, native } = usecase.execute({ excludeLayerable: false });
        const themes = [...thirdParty, ...native];
        const activeTheme = themes.find((theme) => this.components.isThemeActive(theme) && !theme.layerable);
        const activeThemeIdentifier = activeTheme ? activeTheme.featureIdentifier : DefaultThemeIdentifier;
        const themeIdentifier = this.preferences.getValue(preference, preferenceDefault);
        const toggleActiveTheme = () => {
            if (activeTheme) {
                void this.components.toggleTheme(activeTheme);
            }
        };
        const setTheme = () => {
            if (themeIdentifier === DefaultThemeIdentifier) {
                toggleActiveTheme();
            }
            else {
                const theme = themes.find((theme) => theme.featureIdentifier === themeIdentifier);
                if (theme && !this.components.isThemeActive(theme)) {
                    this.components.toggleTheme(theme).catch(console.error);
                }
            }
        };
        const isPreferredThemeNotActive = activeThemeIdentifier !== themeIdentifier;
        if (isPreferredThemeNotActive) {
            this.showColorSchemeToast(setTheme);
        }
    }
    async activateCachedThemes() {
        const cachedThemes = this.getCachedThemes();
        for (const theme of cachedThemes) {
            this.activateTheme(theme, true);
        }
    }
    deactivateAllThemes() {
        const activeThemes = this.themesActiveInTheUI.slice();
        for (const uuid of activeThemes) {
            this.deactivateThemeInTheUI(uuid);
        }
    }
    activateTheme(theme, skipEntitlementCheck = false) {
        if (this.themesActiveInTheUI.find((uuid) => uuid === theme.uniqueIdentifier)) {
            return;
        }
        if (!skipEntitlementCheck &&
            this.application.features.getFeatureStatus(theme.featureIdentifier) !== services_1.FeatureStatus.Entitled) {
            return;
        }
        const url = this.application.componentManager.urlForFeature(theme);
        if (!url) {
            return;
        }
        this.themesActiveInTheUI.push(theme.uniqueIdentifier);
        const link = document.createElement('link');
        link.href = url;
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.media = 'screen,print';
        link.id = theme.uniqueIdentifier;
        link.onload = () => {
            this.syncThemeColorMetadata();
            if (this.application.isNativeMobileWeb()) {
                const packageInfo = theme.featureDescription;
                setTimeout(() => {
                    var _a;
                    this.application
                        .mobileDevice()
                        .handleThemeSchemeChange((_a = packageInfo.isDark) !== null && _a !== void 0 ? _a : false, this.getBackgroundColor());
                });
            }
        };
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    deactivateThemeInTheUI(uuid) {
        var _a;
        if (!this.themesActiveInTheUI.includes(uuid)) {
            return;
        }
        const element = document.getElementById(uuid);
        if (element) {
            element.disabled = true;
            (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.removeChild(element);
        }
        (0, utils_1.removeFromArray)(this.themesActiveInTheUI, uuid);
        if (this.themesActiveInTheUI.length === 0 && this.application.isNativeMobileWeb()) {
            this.application.mobileDevice().handleThemeSchemeChange(false, '#ffffff');
        }
    }
    getBackgroundColor() {
        const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--sn-stylekit-background-color').trim();
        return bgColor.length ? bgColor : '#ffffff';
    }
    /**
     * Syncs the active theme's background color to the 'theme-color' meta tag
     * https://developer.mozilla.org/en-US/docs/Web/HTML/Element/meta/name/theme-color
     */
    syncThemeColorMetadata() {
        const themeColorMetaElement = document.querySelector('meta[name="theme-color"]');
        if (!themeColorMetaElement) {
            return;
        }
        themeColorMetaElement.setAttribute('content', this.getBackgroundColor());
    }
    async cacheThemeState() {
        const themes = this.application.items.findItems(this.themesActiveInTheUI);
        const mapped = themes.map((theme) => {
            const payload = theme.payloadRepresentation();
            return (0, models_1.CreateDecryptedLocalStorageContextPayload)(payload);
        });
        return this.application.setValue(CachedThemesKey, mapped, services_1.StorageValueModes.Nonwrapped);
    }
    getCachedThemes() {
        const cachedThemes = this.application.getValue(CachedThemesKey, services_1.StorageValueModes.Nonwrapped);
        if (cachedThemes) {
            const themes = [];
            for (const cachedTheme of cachedThemes) {
                const payload = this.application.items.createPayloadFromObject(cachedTheme);
                const theme = this.application.items.createItemFromPayload(payload);
                themes.push(theme);
            }
            return themes.map((theme) => new models_1.UIFeature(theme));
        }
        else {
            return [];
        }
    }
}
exports.ThemeManager = ThemeManager;
