"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendSharedVaultMetadataChangedMessageToAll = void 0;
const models_1 = require("@standardnotes/models");
const responses_1 = require("@standardnotes/responses");
const GetSharedVaultUsers_1 = require("./GetSharedVaultUsers");
const SendAsymmetricMessageUseCase_1 = require("../../AsymmetricMessage/UseCase/SendAsymmetricMessageUseCase");
class SendSharedVaultMetadataChangedMessageToAll {
    constructor(encryption, contacts, vaultUsersServer, messageServer) {
        this.encryption = encryption;
        this.contacts = contacts;
        this.vaultUsersServer = vaultUsersServer;
        this.messageServer = messageServer;
    }
    async execute(params) {
        const errors = [];
        const getUsersUseCase = new GetSharedVaultUsers_1.GetSharedVaultUsersUseCase(this.vaultUsersServer);
        const users = await getUsersUseCase.execute({ sharedVaultUuid: params.vault.sharing.sharedVaultUuid });
        if (!users) {
            return [responses_1.ClientDisplayableError.FromString('Cannot send metadata changed message; users not found')];
        }
        for (const user of users) {
            if (user.user_uuid === params.senderUuid) {
                continue;
            }
            const trustedContact = this.contacts.findTrustedContact(user.user_uuid);
            if (!trustedContact) {
                continue;
            }
            const sendMessageResult = await this.sendToContact({
                vault: params.vault,
                senderKeyPair: params.senderEncryptionKeyPair,
                senderSigningKeyPair: params.senderSigningKeyPair,
                contact: trustedContact,
            });
            if ((0, responses_1.isClientDisplayableError)(sendMessageResult)) {
                errors.push(sendMessageResult);
            }
        }
        return errors;
    }
    async sendToContact(params) {
        const message = {
            type: models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged,
            data: {
                recipientUuid: params.contact.contactUuid,
                sharedVaultUuid: params.vault.sharing.sharedVaultUuid,
                name: params.vault.name,
                description: params.vault.description,
            },
        };
        const encryptedMessage = this.encryption.asymmetricallyEncryptMessage({
            message: message,
            senderKeyPair: params.senderKeyPair,
            senderSigningKeyPair: params.senderSigningKeyPair,
            recipientPublicKey: params.contact.publicKeySet.encryption,
        });
        const replaceabilityIdentifier = [
            models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged,
            params.vault.sharing.sharedVaultUuid,
            params.vault.systemIdentifier,
        ].join(':');
        const sendMessageUseCase = new SendAsymmetricMessageUseCase_1.SendAsymmetricMessageUseCase(this.messageServer);
        const sendMessageResult = await sendMessageUseCase.execute({
            recipientUuid: params.contact.contactUuid,
            encryptedMessage,
            replaceabilityIdentifier,
        });
        return sendMessageResult;
    }
}
exports.SendSharedVaultMetadataChangedMessageToAll = SendSharedVaultMetadataChangedMessageToAll;
