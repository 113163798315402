"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FilesBackupService = exports.FileBackupsDirectoryName = exports.TextBackupsDirectoryName = void 0;
const features_1 = require("@standardnotes/features");
const ApplicationStage_1 = require("./../Application/ApplicationStage");
const models_1 = require("@standardnotes/models");
const responses_1 = require("@standardnotes/responses");
const AbstractService_1 = require("../Service/AbstractService");
const StorageKeys_1 = require("../Storage/StorageKeys");
const domain_core_1 = require("@standardnotes/domain-core");
const PlaintextBackupsDirectoryName = 'Plaintext Backups';
exports.TextBackupsDirectoryName = 'Text Backups';
exports.FileBackupsDirectoryName = 'File Backups';
class FilesBackupService extends AbstractService_1.AbstractService {
    constructor(items, api, encryptor, device, status, crypto, storage, session, payloads, history, directory, internalEventBus) {
        super(internalEventBus);
        this.items = items;
        this.api = api;
        this.encryptor = encryptor;
        this.device = device;
        this.status = status;
        this.crypto = crypto;
        this.storage = storage;
        this.session = session;
        this.payloads = payloads;
        this.history = history;
        this.directory = directory;
        this.internalEventBus = internalEventBus;
        this.pendingFiles = new Set();
        this.filesObserverDisposer = items.addObserver(domain_core_1.ContentType.TYPES.File, ({ changed, inserted, source }) => {
            const applicableSources = [
                models_1.PayloadEmitSource.LocalDatabaseLoaded,
                models_1.PayloadEmitSource.RemoteSaved,
                models_1.PayloadEmitSource.RemoteRetrieved,
            ];
            if (applicableSources.includes(source)) {
                void this.handleChangedFiles([...changed, ...inserted]);
            }
        });
        const noteAndTagSources = [
            models_1.PayloadEmitSource.RemoteSaved,
            models_1.PayloadEmitSource.RemoteRetrieved,
            models_1.PayloadEmitSource.OfflineSyncSaved,
        ];
        this.notesObserverDisposer = items.addObserver(domain_core_1.ContentType.TYPES.Note, ({ changed, inserted, source }) => {
            if (noteAndTagSources.includes(source)) {
                void this.handleChangedNotes([...changed, ...inserted]);
            }
        });
        this.tagsObserverDisposer = items.addObserver(domain_core_1.ContentType.TYPES.Tag, ({ changed, inserted, source }) => {
            if (noteAndTagSources.includes(source)) {
                void this.handleChangedTags([...changed, ...inserted]);
            }
        });
    }
    setSuperConverter(converter) {
        this.markdownConverter = converter;
    }
    async importWatchedDirectoryChanges(changes) {
        for (const change of changes) {
            const existingItem = this.items.findItem(change.itemUuid);
            if (!existingItem) {
                continue;
            }
            if (!(0, models_1.isNote)(existingItem)) {
                continue;
            }
            const newContent = {
                ...existingItem.payload.content,
                preview_html: undefined,
                preview_plain: undefined,
                text: change.content,
            };
            const payloadCopy = existingItem.payload.copy({
                content: newContent,
            });
            await this.payloads.importPayloads([payloadCopy], this.history.getHistoryMapCopy());
        }
    }
    deinit() {
        super.deinit();
        this.filesObserverDisposer();
        this.notesObserverDisposer();
        this.tagsObserverDisposer();
        this.items = undefined;
        this.api = undefined;
        this.encryptor = undefined;
        this.device = undefined;
        this.status = undefined;
        this.crypto = undefined;
        this.storage = undefined;
        this.session = undefined;
    }
    async handleApplicationStage(stage) {
        if (stage === ApplicationStage_1.ApplicationStage.Launched_10) {
            void this.automaticallyEnableTextBackupsIfPreferenceNotSet();
        }
    }
    async automaticallyEnableTextBackupsIfPreferenceNotSet() {
        if (this.storage.getValue(StorageKeys_1.StorageKey.TextBackupsEnabled) == undefined) {
            this.storage.setValue(StorageKeys_1.StorageKey.TextBackupsEnabled, true);
            const location = await this.device.joinPaths(await this.device.getUserDocumentsDirectory(), await this.prependWorkspacePathForPath(exports.TextBackupsDirectoryName));
            this.storage.setValue(StorageKeys_1.StorageKey.TextBackupsLocation, location);
        }
    }
    openAllDirectoriesContainingBackupFiles() {
        const fileBackupsLocation = this.getFilesBackupsLocation();
        const plaintextBackupsLocation = this.getPlaintextBackupsLocation();
        const textBackupsLocation = this.getTextBackupsLocation();
        if (fileBackupsLocation) {
            void this.directory.openLocation(fileBackupsLocation);
        }
        if (plaintextBackupsLocation) {
            void this.directory.openLocation(plaintextBackupsLocation);
        }
        if (textBackupsLocation) {
            void this.directory.openLocation(textBackupsLocation);
        }
    }
    isFilesBackupsEnabled() {
        return this.storage.getValue(StorageKeys_1.StorageKey.FileBackupsEnabled, undefined, false);
    }
    getFilesBackupsLocation() {
        return this.storage.getValue(StorageKeys_1.StorageKey.FileBackupsLocation);
    }
    isTextBackupsEnabled() {
        return this.storage.getValue(StorageKeys_1.StorageKey.TextBackupsEnabled, undefined, true);
    }
    async prependWorkspacePathForPath(path) {
        const workspacePath = this.session.getWorkspaceDisplayIdentifier();
        return this.device.joinPaths(workspacePath, path);
    }
    async enableTextBackups() {
        let location = this.getTextBackupsLocation();
        if (!location) {
            location = await this.directory.presentDirectoryPickerForLocationChangeAndTransferOld(await this.prependWorkspacePathForPath(exports.TextBackupsDirectoryName));
            if (!location) {
                return;
            }
        }
        this.storage.setValue(StorageKeys_1.StorageKey.TextBackupsEnabled, true);
        this.storage.setValue(StorageKeys_1.StorageKey.TextBackupsLocation, location);
    }
    disableTextBackups() {
        this.storage.setValue(StorageKeys_1.StorageKey.TextBackupsEnabled, false);
    }
    getTextBackupsLocation() {
        return this.storage.getValue(StorageKeys_1.StorageKey.TextBackupsLocation);
    }
    async openTextBackupsLocation() {
        const location = this.getTextBackupsLocation();
        if (location) {
            void this.directory.openLocation(location);
        }
    }
    async changeTextBackupsLocation() {
        const oldLocation = this.getTextBackupsLocation();
        const newLocation = await this.directory.presentDirectoryPickerForLocationChangeAndTransferOld(await this.prependWorkspacePathForPath(exports.TextBackupsDirectoryName), oldLocation);
        if (!newLocation) {
            return undefined;
        }
        this.storage.setValue(StorageKeys_1.StorageKey.TextBackupsLocation, newLocation);
        return newLocation;
    }
    async saveTextBackupData(data) {
        const location = this.getTextBackupsLocation();
        if (!location) {
            return;
        }
        return this.device.saveTextBackupData(location, data);
    }
    isPlaintextBackupsEnabled() {
        return this.storage.getValue(StorageKeys_1.StorageKey.PlaintextBackupsEnabled, undefined, false);
    }
    async enablePlaintextBackups() {
        let location = this.getPlaintextBackupsLocation();
        if (!location) {
            location = await this.directory.presentDirectoryPickerForLocationChangeAndTransferOld(await this.prependWorkspacePathForPath(PlaintextBackupsDirectoryName));
            if (!location) {
                return;
            }
        }
        this.storage.setValue(StorageKeys_1.StorageKey.PlaintextBackupsEnabled, true);
        this.storage.setValue(StorageKeys_1.StorageKey.PlaintextBackupsLocation, location);
        void this.handleChangedNotes(this.items.getItems(domain_core_1.ContentType.TYPES.Note));
    }
    disablePlaintextBackups() {
        this.storage.setValue(StorageKeys_1.StorageKey.PlaintextBackupsEnabled, false);
        this.storage.setValue(StorageKeys_1.StorageKey.PlaintextBackupsLocation, undefined);
    }
    getPlaintextBackupsLocation() {
        return this.storage.getValue(StorageKeys_1.StorageKey.PlaintextBackupsLocation);
    }
    async openPlaintextBackupsLocation() {
        const location = this.getPlaintextBackupsLocation();
        if (location) {
            void this.directory.openLocation(location);
        }
    }
    async changePlaintextBackupsLocation() {
        const oldLocation = this.getPlaintextBackupsLocation();
        const newLocation = await this.directory.presentDirectoryPickerForLocationChangeAndTransferOld(await this.prependWorkspacePathForPath(PlaintextBackupsDirectoryName), oldLocation);
        if (!newLocation) {
            return undefined;
        }
        this.storage.setValue(StorageKeys_1.StorageKey.PlaintextBackupsLocation, newLocation);
        return newLocation;
    }
    async enableFilesBackups() {
        let location = this.getFilesBackupsLocation();
        if (!location) {
            location = await this.directory.presentDirectoryPickerForLocationChangeAndTransferOld(await this.prependWorkspacePathForPath(exports.FileBackupsDirectoryName));
            if (!location) {
                return;
            }
        }
        this.storage.setValue(StorageKeys_1.StorageKey.FileBackupsEnabled, true);
        this.storage.setValue(StorageKeys_1.StorageKey.FileBackupsLocation, location);
        this.backupAllFiles();
    }
    backupAllFiles() {
        const files = this.items.getItems(domain_core_1.ContentType.TYPES.File);
        void this.handleChangedFiles(files);
    }
    disableFilesBackups() {
        this.storage.setValue(StorageKeys_1.StorageKey.FileBackupsEnabled, false);
    }
    async changeFilesBackupsLocation() {
        const oldLocation = this.getFilesBackupsLocation();
        const newLocation = await this.directory.presentDirectoryPickerForLocationChangeAndTransferOld(await this.prependWorkspacePathForPath(exports.FileBackupsDirectoryName), oldLocation);
        if (!newLocation) {
            return undefined;
        }
        this.storage.setValue(StorageKeys_1.StorageKey.FileBackupsLocation, newLocation);
        return newLocation;
    }
    async openFilesBackupsLocation() {
        const location = this.getFilesBackupsLocation();
        if (location) {
            void this.directory.openLocation(location);
        }
    }
    async getBackupsMappingFromDisk() {
        const location = this.getFilesBackupsLocation();
        if (!location) {
            return undefined;
        }
        const result = (await this.device.getFilesBackupsMappingFile(location)).files;
        this.mappingCache = result;
        return result;
    }
    invalidateMappingCache() {
        this.mappingCache = undefined;
    }
    async getBackupsMappingFromCache() {
        var _a;
        return (_a = this.mappingCache) !== null && _a !== void 0 ? _a : (await this.getBackupsMappingFromDisk());
    }
    async getFileBackupInfo(file) {
        const mapping = await this.getBackupsMappingFromCache();
        if (!mapping) {
            return undefined;
        }
        const record = mapping[file.uuid];
        return record;
    }
    getFileBackupAbsolutePath(record) {
        const location = this.getFilesBackupsLocation();
        if (!location) {
            throw new responses_1.ClientDisplayableError('No files backups location set');
        }
        return this.device.joinPaths(location, record.relativePath);
    }
    async openFileBackup(record) {
        const location = await this.getFileBackupAbsolutePath(record);
        await this.directory.openLocation(location);
    }
    async handleChangedFiles(files) {
        if (files.length === 0 || !this.isFilesBackupsEnabled()) {
            return;
        }
        const mapping = await this.getBackupsMappingFromDisk();
        if (!mapping) {
            throw new responses_1.ClientDisplayableError('No backups mapping found');
        }
        for (const file of files) {
            if (this.pendingFiles.has(file.uuid)) {
                continue;
            }
            const record = mapping[file.uuid];
            if (record == undefined) {
                this.pendingFiles.add(file.uuid);
                await this.performBackupOperation(file);
                this.pendingFiles.delete(file.uuid);
            }
        }
        this.invalidateMappingCache();
    }
    async handleChangedNotes(notes) {
        if (notes.length === 0 || !this.isPlaintextBackupsEnabled()) {
            return;
        }
        const location = this.getPlaintextBackupsLocation();
        if (!location) {
            throw new responses_1.ClientDisplayableError('No plaintext backups location found');
        }
        if (!this.markdownConverter) {
            throw 'Super markdown converter not initialized';
        }
        for (const note of notes) {
            const tags = this.items.getSortedTagsForItem(note);
            const tagNames = tags.map((tag) => this.items.getTagLongTitle(tag));
            const text = note.noteType === features_1.NoteType.Super ? this.markdownConverter.convertString(note.text, 'md') : note.text;
            await this.device.savePlaintextNoteBackup(location, note.uuid, note.title, tagNames, text);
        }
        await this.device.persistPlaintextBackupsMappingFile(location);
    }
    async handleChangedTags(tags) {
        if (tags.length === 0 || !this.isPlaintextBackupsEnabled()) {
            return;
        }
        for (const tag of tags) {
            const notes = this.items.referencesForItem(tag, domain_core_1.ContentType.TYPES.Note);
            await this.handleChangedNotes(notes);
        }
    }
    async readEncryptedFileFromBackup(uuid, onChunk) {
        const fileBackup = await this.getFileBackupInfo({ uuid });
        if (!fileBackup) {
            return 'failed';
        }
        const fileBackupsLocation = this.getFilesBackupsLocation();
        if (!fileBackupsLocation) {
            return 'failed';
        }
        const path = await this.device.joinPaths(fileBackupsLocation, fileBackup.relativePath, fileBackup.binaryFileName);
        const token = await this.device.getFileBackupReadToken(path);
        let readMore = true;
        let index = 0;
        while (readMore) {
            const { chunk, isLast, progress } = await this.device.readNextChunk(token);
            await onChunk({ data: chunk, index, isLast, progress });
            readMore = !isLast;
            index++;
        }
        return 'success';
    }
    async performBackupOperation(file) {
        const location = this.getFilesBackupsLocation();
        if (!location) {
            return 'failed';
        }
        const messageId = this.status.addMessage(`Backing up file ${file.name}...`);
        const encryptedFile = await this.encryptor.encryptSplitSingle({
            usesItemsKeyWithKeyLookup: {
                items: [file.payload],
            },
        });
        const itemsKey = this.items.getDisplayableItemsKeys().find((k) => k.uuid === encryptedFile.items_key_id);
        if (!itemsKey) {
            this.status.removeMessage(messageId);
            return 'failed';
        }
        const encryptedItemsKey = await this.encryptor.encryptSplitSingle({
            usesRootKeyWithKeyLookup: {
                items: [itemsKey.payload],
            },
        });
        const token = await this.api.createUserFileValetToken(file.remoteIdentifier, 'read');
        if (token instanceof responses_1.ClientDisplayableError) {
            this.status.removeMessage(messageId);
            return 'failed';
        }
        const metaFile = {
            info: {
                warning: 'Do not edit this file.',
                information: 'The file and key data below is encrypted with your account password.',
                instructions: 'Drag and drop this metadata file into the File Backups preferences pane in the Standard Notes desktop or web application interface.',
            },
            file: (0, models_1.CreateEncryptedBackupFileContextPayload)(encryptedFile.ejected()),
            itemsKey: (0, models_1.CreateEncryptedBackupFileContextPayload)(encryptedItemsKey.ejected()),
            version: '1.0.0',
        };
        const metaFileAsString = JSON.stringify(metaFile, null, 2);
        const downloadType = !file.user_uuid || file.user_uuid === this.session.getSureUser().uuid ? 'user' : 'shared-vault';
        const result = await this.device.saveFilesBackupsFile(location, file.uuid, metaFileAsString, {
            chunkSizes: file.encryptedChunkSizes,
            url: this.api.getFilesDownloadUrl(downloadType),
            valetToken: token,
        });
        this.status.removeMessage(messageId);
        if (result === 'failed') {
            const failMessageId = this.status.addMessage(`Failed to back up ${file.name}...`);
            setTimeout(() => {
                this.status.removeMessage(failMessageId);
            }, 2000);
        }
        return result;
    }
    /**
     * Not presently used or enabled. It works, but presently has the following edge cases:
     * 1. Editing the note directly in SN triggers an immediate backup which triggers a file change which triggers the observer
     * 2. Since changes are based on filenames, a note with the same title as another may not properly map to the correct uuid
     * 3. Opening the file triggers a watch event from Node's watch API.
     * 4. Gives web code ability to monitor arbitrary locations. Needs whitelisting mechanism.
     */
    disabledExperimental_monitorPlaintextBackups() {
        const location = this.getPlaintextBackupsLocation();
        if (location) {
            void this.device.monitorPlaintextBackupsLocationForChanges(location);
        }
    }
}
exports.FilesBackupService = FilesBackupService;
