"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultSelectionMenuController = void 0;
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
class VaultSelectionMenuController extends AbstractViewController_1.AbstractViewController {
    constructor(application, eventBus) {
        super(application, eventBus);
        this.open = false;
        this.shouldAnimateCloseMenu = false;
        this.setOpen = (open) => {
            this.open = open;
        };
        this.setShouldAnimateCloseMenu = (shouldAnimate) => {
            this.shouldAnimateCloseMenu = shouldAnimate;
        };
        this.toggle = () => {
            if (this.open) {
                this.closeVaultSelectionMenu();
            }
            else {
                this.setOpen(true);
            }
        };
        this.closeVaultSelectionMenu = () => {
            this.setShouldAnimateCloseMenu(true);
            setTimeout(() => {
                this.setOpen(false);
                this.setShouldAnimateCloseMenu(false);
            }, 150);
        };
        (0, mobx_1.makeObservable)(this, {
            open: mobx_1.observable,
            shouldAnimateCloseMenu: mobx_1.observable,
            setOpen: mobx_1.action,
            setShouldAnimateCloseMenu: mobx_1.action,
            toggle: mobx_1.action,
            closeVaultSelectionMenu: mobx_1.action,
        });
    }
}
exports.VaultSelectionMenuController = VaultSelectionMenuController;
