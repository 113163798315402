"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOrEditTrustedContactUseCase = void 0;
const models_1 = require("@standardnotes/models");
const FindTrustedContact_1 = require("./FindTrustedContact");
const UnknownContactName_1 = require("../UnknownContactName");
const UpdateTrustedContact_1 = require("./UpdateTrustedContact");
const domain_core_1 = require("@standardnotes/domain-core");
class CreateOrEditTrustedContactUseCase {
    constructor(items, mutator, sync) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
    }
    async execute(params) {
        var _a, _b, _c;
        const findUsecase = new FindTrustedContact_1.FindTrustedContactUseCase(this.items);
        const existingContact = findUsecase.execute({ userUuid: params.contactUuid });
        if (existingContact) {
            const updateUsecase = new UpdateTrustedContact_1.UpdateTrustedContactUseCase(this.mutator, this.sync);
            await updateUsecase.execute(existingContact, { ...params, name: (_a = params.name) !== null && _a !== void 0 ? _a : existingContact.name });
            return existingContact;
        }
        const content = {
            name: (_b = params.name) !== null && _b !== void 0 ? _b : UnknownContactName_1.UnknownContactName,
            publicKeySet: models_1.ContactPublicKeySet.FromJson({
                encryption: params.publicKey,
                signing: params.signingPublicKey,
                isRevoked: false,
                timestamp: new Date(),
            }),
            contactUuid: params.contactUuid,
            isMe: (_c = params.isMe) !== null && _c !== void 0 ? _c : false,
        };
        const contact = await this.mutator.createItem(domain_core_1.ContentType.TYPES.TrustedContact, (0, models_1.FillItemContent)(content), true);
        await this.sync.sync();
        return contact;
    }
}
exports.CreateOrEditTrustedContactUseCase = CreateOrEditTrustedContactUseCase;
