"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetVaultUseCase = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
class GetVaultUseCase {
    constructor(items) {
        this.items = items;
    }
    execute(query) {
        const vaults = this.items.getItems(domain_core_1.ContentType.TYPES.VaultListing);
        if ('keySystemIdentifier' in query) {
            return vaults.find((listing) => listing.systemIdentifier === query.keySystemIdentifier);
        }
        else {
            return vaults.find((listing) => { var _a; return ((_a = listing.sharing) === null || _a === void 0 ? void 0 : _a.sharedVaultUuid) === query.sharedVaultUuid; });
        }
    }
}
exports.GetVaultUseCase = GetVaultUseCase;
