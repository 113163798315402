"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetDeprecatedFeatures = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const PermissionName_1 = require("../Permission/PermissionName");
const FeatureIdentifier_1 = require("../Feature/FeatureIdentifier");
const NoteType_1 = require("../Component/NoteType");
const FillEditorComponentDefaults_1 = require("./Utilities/FillEditorComponentDefaults");
const ComponentAction_1 = require("../Component/ComponentAction");
const ComponentArea_1 = require("../Component/ComponentArea");
function GetDeprecatedFeatures() {
    const bold = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Alternative Rich Text',
        identifier: FeatureIdentifier_1.FeatureIdentifier.DeprecatedBoldEditor,
        note_type: NoteType_1.NoteType.RichText,
        file_type: 'html',
        component_permissions: [
            {
                name: ComponentAction_1.ComponentAction.StreamContextItem,
                content_types: [domain_core_1.ContentType.TYPES.Note],
            },
            {
                name: ComponentAction_1.ComponentAction.StreamItems,
                content_types: [
                    domain_core_1.ContentType.TYPES.FilesafeCredentials,
                    domain_core_1.ContentType.TYPES.FilesafeFileMetadata,
                    domain_core_1.ContentType.TYPES.FilesafeIntegration,
                ],
            },
        ],
        spellcheckControl: true,
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.BoldEditor,
        description: 'A simple and peaceful rich editor that helps you write and think clearly.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/bold.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownBasic = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Basic Markdown',
        identifier: FeatureIdentifier_1.FeatureIdentifier.DeprecatedMarkdownBasicEditor,
        note_type: NoteType_1.NoteType.Markdown,
        spellcheckControl: true,
        file_type: 'md',
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.MarkdownBasicEditor,
        description: 'A Markdown editor with dynamic split-pane preview.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/simple-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownAlt = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Markdown Alternative',
        identifier: FeatureIdentifier_1.FeatureIdentifier.DeprecatedMarkdownVisualEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        deprecated: true,
        permission_name: PermissionName_1.PermissionName.MarkdownVisualEditor,
        spellcheckControl: true,
        description: 'A WYSIWYG-style Markdown editor that renders Markdown in preview-mode while you type without displaying any syntax.',
        index_path: 'build/index.html',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownMinimist = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Minimal Markdown',
        identifier: FeatureIdentifier_1.FeatureIdentifier.DeprecatedMarkdownMinimistEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        index_path: 'index.html',
        permission_name: PermissionName_1.PermissionName.MarkdownMinimistEditor,
        spellcheckControl: true,
        deprecated: true,
        description: 'A minimal Markdown editor with live rendering and in-text search via Ctrl/Cmd + F',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/min-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const markdownMath = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'Markdown with Math',
        identifier: FeatureIdentifier_1.FeatureIdentifier.DeprecatedMarkdownMathEditor,
        spellcheckControl: true,
        permission_name: PermissionName_1.PermissionName.MarkdownMathEditor,
        note_type: NoteType_1.NoteType.Markdown,
        file_type: 'md',
        deprecated: true,
        index_path: 'index.html',
        description: 'A beautiful split-pane Markdown editor with synced-scroll, LaTeX support, and colorful syntax.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/editors/fancy-markdown.jpg',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    const filesafe = (0, FillEditorComponentDefaults_1.FillIframeEditorDefaults)({
        name: 'FileSafe',
        identifier: FeatureIdentifier_1.FeatureIdentifier.DeprecatedFileSafe,
        component_permissions: [
            {
                name: ComponentAction_1.ComponentAction.StreamContextItem,
                content_types: [domain_core_1.ContentType.TYPES.Note],
            },
            {
                name: ComponentAction_1.ComponentAction.StreamItems,
                content_types: [
                    domain_core_1.ContentType.TYPES.FilesafeCredentials,
                    domain_core_1.ContentType.TYPES.FilesafeFileMetadata,
                    domain_core_1.ContentType.TYPES.FilesafeIntegration,
                ],
            },
        ],
        permission_name: PermissionName_1.PermissionName.ComponentFilesafe,
        area: ComponentArea_1.ComponentArea.EditorStack,
        deprecated: true,
        description: 'Encrypted attachments for your notes using your Dropbox, Google Drive, or WebDAV server. Limited to 50MB per file.',
        thumbnail_url: 'https://assets.standardnotes.com/screenshots/models/FileSafe-banner.png',
        availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
    });
    return [bold, markdownBasic, markdownMinimist, markdownMath, markdownAlt, filesafe];
}
exports.GetDeprecatedFeatures = GetDeprecatedFeatures;
