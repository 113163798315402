"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultService = void 0;
const InviteContactToSharedVault_1 = require("./UseCase/InviteContactToSharedVault");
const responses_1 = require("@standardnotes/responses");
const api_1 = require("@standardnotes/api");
const models_1 = require("@standardnotes/models");
const SharedVaultServiceEvent_1 = require("./SharedVaultServiceEvent");
const GetSharedVaultUsers_1 = require("./UseCase/GetSharedVaultUsers");
const RemoveSharedVaultMember_1 = require("./UseCase/RemoveSharedVaultMember");
const AbstractService_1 = require("../Service/AbstractService");
const SyncEvent_1 = require("../Event/SyncEvent");
const SessionEvent_1 = require("../Session/SessionEvent");
const LeaveSharedVault_1 = require("./UseCase/LeaveSharedVault");
const UserEventServiceEvent_1 = require("../UserEvent/UserEventServiceEvent");
const DeleteExternalSharedVault_1 = require("./UseCase/DeleteExternalSharedVault");
const DeleteSharedVault_1 = require("./UseCase/DeleteSharedVault");
const VaultServiceEvent_1 = require("../Vaults/VaultServiceEvent");
const AcceptTrustedSharedVaultInvite_1 = require("./UseCase/AcceptTrustedSharedVaultInvite");
const GetAsymmetricMessageTrustedPayload_1 = require("../AsymmetricMessage/UseCase/GetAsymmetricMessageTrustedPayload");
const GetAsymmetricMessageUntrustedPayload_1 = require("../AsymmetricMessage/UseCase/GetAsymmetricMessageUntrustedPayload");
const ShareContactWithAllMembersOfSharedVault_1 = require("./UseCase/ShareContactWithAllMembersOfSharedVault");
const GetSharedVaultTrustedContacts_1 = require("./UseCase/GetSharedVaultTrustedContacts");
const NotifySharedVaultUsersOfRootKeyRotation_1 = require("./UseCase/NotifySharedVaultUsersOfRootKeyRotation");
const CreateSharedVault_1 = require("./UseCase/CreateSharedVault");
const SendSharedVaultMetadataChangedMessageToAll_1 = require("./UseCase/SendSharedVaultMetadataChangedMessageToAll");
const ConvertToSharedVault_1 = require("./UseCase/ConvertToSharedVault");
const GetVault_1 = require("../Vaults/UseCase/GetVault");
const domain_core_1 = require("@standardnotes/domain-core");
class SharedVaultService extends AbstractService_1.AbstractService {
    constructor(http, sync, items, mutator, encryption, session, contacts, files, vaults, storage, eventBus) {
        super(eventBus);
        this.sync = sync;
        this.items = items;
        this.mutator = mutator;
        this.encryption = encryption;
        this.session = session;
        this.contacts = contacts;
        this.files = files;
        this.vaults = vaults;
        this.storage = storage;
        this.pendingInvites = {};
        eventBus.addEventHandler(this, SessionEvent_1.SessionEvent.UserKeyPairChanged);
        eventBus.addEventHandler(this, UserEventServiceEvent_1.UserEventServiceEvent.UserEventReceived);
        eventBus.addEventHandler(this, VaultServiceEvent_1.VaultServiceEvent.VaultRootKeyRotated);
        this.server = new api_1.SharedVaultServer(http);
        this.usersServer = new api_1.SharedVaultUsersServer(http);
        this.invitesServer = new api_1.SharedVaultInvitesServer(http);
        this.messageServer = new api_1.AsymmetricMessageServer(http);
        this.eventDisposers.push(sync.addEventObserver(async (event, data) => {
            if (event === SyncEvent_1.SyncEvent.ReceivedSharedVaultInvites) {
                void this.processInboundInvites(data);
            }
            else if (event === SyncEvent_1.SyncEvent.ReceivedRemoteSharedVaults) {
                void this.notifyCollaborationStatusChanged();
            }
        }));
        this.eventDisposers.push(items.addObserver(domain_core_1.ContentType.TYPES.TrustedContact, async ({ changed, inserted, source }) => {
            await this.reprocessCachedInvitesTrustStatusAfterTrustedContactsChange();
            if (source === models_1.PayloadEmitSource.LocalChanged && inserted.length > 0) {
                void this.handleCreationOfNewTrustedContacts(inserted);
            }
            if (source === models_1.PayloadEmitSource.LocalChanged && changed.length > 0) {
                void this.handleTrustedContactsChange(changed);
            }
        }));
        this.eventDisposers.push(items.addObserver(domain_core_1.ContentType.TYPES.VaultListing, ({ changed, source }) => {
            if (source === models_1.PayloadEmitSource.LocalChanged && changed.length > 0) {
                void this.handleVaultListingsChange(changed);
            }
        }));
    }
    async handleEvent(event) {
        if (event.type === SessionEvent_1.SessionEvent.UserKeyPairChanged) {
            void this.invitesServer.deleteAllInboundInvites();
        }
        else if (event.type === UserEventServiceEvent_1.UserEventServiceEvent.UserEventReceived) {
            await this.handleUserEvent(event.payload);
        }
        else if (event.type === VaultServiceEvent_1.VaultServiceEvent.VaultRootKeyRotated) {
            const payload = event.payload;
            await this.handleVaultRootKeyRotatedEvent(payload.vault);
        }
    }
    async handleUserEvent(event) {
        if (event.eventPayload.eventType === responses_1.UserEventType.RemovedFromSharedVault) {
            const vault = new GetVault_1.GetVaultUseCase(this.items).execute({ sharedVaultUuid: event.eventPayload.sharedVaultUuid });
            if (vault) {
                const useCase = new DeleteExternalSharedVault_1.DeleteExternalSharedVaultUseCase(this.items, this.mutator, this.encryption, this.storage, this.sync);
                await useCase.execute(vault);
            }
        }
        else if (event.eventPayload.eventType === responses_1.UserEventType.SharedVaultItemRemoved) {
            const item = this.items.findItem(event.eventPayload.itemUuid);
            if (item) {
                this.items.removeItemsLocally([item]);
            }
        }
    }
    async handleVaultRootKeyRotatedEvent(vault) {
        if (!vault.isSharedVaultListing()) {
            return;
        }
        if (!this.isCurrentUserSharedVaultOwner(vault)) {
            return;
        }
        const usecase = new NotifySharedVaultUsersOfRootKeyRotation_1.NotifySharedVaultUsersOfRootKeyRotationUseCase(this.usersServer, this.invitesServer, this.messageServer, this.encryption, this.contacts);
        await usecase.execute({ sharedVault: vault, userUuid: this.session.getSureUser().uuid });
    }
    async createSharedVault(dto) {
        var _a;
        const usecase = new CreateSharedVault_1.CreateSharedVaultUseCase(this.encryption, this.items, this.mutator, this.sync, this.files, this.server);
        return usecase.execute({
            vaultName: dto.name,
            vaultDescription: dto.description,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: (_a = dto.storagePreference) !== null && _a !== void 0 ? _a : models_1.KeySystemRootKeyStorageMode.Synced,
        });
    }
    async convertVaultToSharedVault(vault) {
        const usecase = new ConvertToSharedVault_1.ConvertToSharedVaultUseCase(this.items, this.mutator, this.sync, this.files, this.server);
        return usecase.execute({ vault });
    }
    getCachedPendingInviteRecords() {
        return Object.values(this.pendingInvites);
    }
    getAllSharedVaults() {
        const vaults = this.vaults.getVaults().filter((vault) => vault.isSharedVaultListing());
        return vaults;
    }
    findSharedVault(sharedVaultUuid) {
        return this.getAllSharedVaults().find((vault) => vault.sharing.sharedVaultUuid === sharedVaultUuid);
    }
    isCurrentUserSharedVaultAdmin(sharedVault) {
        if (!sharedVault.sharing.ownerUserUuid) {
            throw new Error(`Shared vault ${sharedVault.sharing.sharedVaultUuid} does not have an owner user uuid`);
        }
        return sharedVault.sharing.ownerUserUuid === this.session.userUuid;
    }
    isCurrentUserSharedVaultOwner(sharedVault) {
        if (!sharedVault.sharing.ownerUserUuid) {
            throw new Error(`Shared vault ${sharedVault.sharing.sharedVaultUuid} does not have an owner user uuid`);
        }
        return sharedVault.sharing.ownerUserUuid === this.session.userUuid;
    }
    isSharedVaultUserSharedVaultOwner(user) {
        const vault = this.findSharedVault(user.shared_vault_uuid);
        return vault != undefined && vault.sharing.ownerUserUuid === user.user_uuid;
    }
    async handleCreationOfNewTrustedContacts(_contacts) {
        await this.downloadInboundInvites();
    }
    async handleTrustedContactsChange(contacts) {
        for (const contact of contacts) {
            await this.shareContactWithUserAdministeredSharedVaults(contact);
        }
    }
    async handleVaultListingsChange(vaults) {
        for (const vault of vaults) {
            if (!vault.isSharedVaultListing()) {
                continue;
            }
            const usecase = new SendSharedVaultMetadataChangedMessageToAll_1.SendSharedVaultMetadataChangedMessageToAll(this.encryption, this.contacts, this.usersServer, this.messageServer);
            await usecase.execute({
                vault,
                senderUuid: this.session.getSureUser().uuid,
                senderEncryptionKeyPair: this.encryption.getKeyPair(),
                senderSigningKeyPair: this.encryption.getSigningKeyPair(),
            });
        }
    }
    async downloadInboundInvites() {
        const response = await this.invitesServer.getInboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to get inbound user invites ${response}`);
        }
        this.pendingInvites = {};
        await this.processInboundInvites(response.data.invites);
        return response.data.invites;
    }
    async getOutboundInvites(sharedVault) {
        const response = await this.invitesServer.getOutboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to get outbound user invites ${response}`);
        }
        if (sharedVault) {
            return response.data.invites.filter((invite) => invite.shared_vault_uuid === sharedVault.sharing.sharedVaultUuid);
        }
        return response.data.invites;
    }
    async deleteInvite(invite) {
        const response = await this.invitesServer.deleteInvite({
            sharedVaultUuid: invite.shared_vault_uuid,
            inviteUuid: invite.uuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to delete invite ${response}`);
        }
        delete this.pendingInvites[invite.uuid];
    }
    async deleteSharedVault(sharedVault) {
        const useCase = new DeleteSharedVault_1.DeleteSharedVaultUseCase(this.server, this.items, this.mutator, this.sync, this.encryption);
        return useCase.execute({ sharedVault });
    }
    async reprocessCachedInvitesTrustStatusAfterTrustedContactsChange() {
        const cachedInvites = this.getCachedPendingInviteRecords().map((record) => record.invite);
        await this.processInboundInvites(cachedInvites);
    }
    async processInboundInvites(invites) {
        if (invites.length === 0) {
            return;
        }
        for (const invite of invites) {
            const trustedMessageUseCase = new GetAsymmetricMessageTrustedPayload_1.GetAsymmetricMessageTrustedPayload(this.encryption, this.contacts);
            const trustedMessage = trustedMessageUseCase.execute({
                message: invite,
                privateKey: this.encryption.getKeyPair().privateKey,
            });
            if (trustedMessage) {
                this.pendingInvites[invite.uuid] = {
                    invite,
                    message: trustedMessage,
                    trusted: true,
                };
                continue;
            }
            const untrustedMessageUseCase = new GetAsymmetricMessageUntrustedPayload_1.GetAsymmetricMessageUntrustedPayload(this.encryption);
            const untrustedMessage = untrustedMessageUseCase.execute({
                message: invite,
                privateKey: this.encryption.getKeyPair().privateKey,
            });
            if (untrustedMessage) {
                this.pendingInvites[invite.uuid] = {
                    invite,
                    message: untrustedMessage,
                    trusted: false,
                };
            }
        }
        await this.notifyCollaborationStatusChanged();
    }
    async notifyCollaborationStatusChanged() {
        await this.notifyEventSync(SharedVaultServiceEvent_1.SharedVaultServiceEvent.SharedVaultStatusChanged);
    }
    async acceptPendingSharedVaultInvite(pendingInvite) {
        if (!pendingInvite.trusted) {
            throw new Error('Cannot accept untrusted invite');
        }
        const useCase = new AcceptTrustedSharedVaultInvite_1.AcceptTrustedSharedVaultInvite(this.invitesServer, this.mutator, this.sync, this.contacts);
        await useCase.execute({ invite: pendingInvite.invite, message: pendingInvite.message });
        delete this.pendingInvites[pendingInvite.invite.uuid];
        void this.sync.sync();
        await this.decryptErroredItemsAfterInviteAccept();
        await this.sync.syncSharedVaultsFromScratch([pendingInvite.invite.shared_vault_uuid]);
    }
    async decryptErroredItemsAfterInviteAccept() {
        await this.encryption.decryptErroredPayloads();
    }
    async getInvitableContactsForSharedVault(sharedVault) {
        const users = await this.getSharedVaultUsers(sharedVault);
        if (!users) {
            return [];
        }
        const contacts = this.contacts.getAllContacts();
        return contacts.filter((contact) => {
            const isContactAlreadyInVault = users.some((user) => user.user_uuid === contact.contactUuid);
            return !isContactAlreadyInVault;
        });
    }
    async getSharedVaultContacts(sharedVault) {
        const usecase = new GetSharedVaultTrustedContacts_1.GetSharedVaultTrustedContacts(this.contacts, this.usersServer);
        const contacts = await usecase.execute(sharedVault);
        if (!contacts) {
            return [];
        }
        return contacts;
    }
    async inviteContactToSharedVault(sharedVault, contact, permissions) {
        const sharedVaultContacts = await this.getSharedVaultContacts(sharedVault);
        const useCase = new InviteContactToSharedVault_1.InviteContactToSharedVaultUseCase(this.encryption, this.invitesServer);
        const result = await useCase.execute({
            senderKeyPair: this.encryption.getKeyPair(),
            senderSigningKeyPair: this.encryption.getSigningKeyPair(),
            sharedVault,
            recipient: contact,
            sharedVaultContacts,
            permissions,
        });
        void this.notifyCollaborationStatusChanged();
        await this.sync.sync();
        return result;
    }
    async removeUserFromSharedVault(sharedVault, userUuid) {
        if (!this.isCurrentUserSharedVaultAdmin(sharedVault)) {
            throw new Error('Only vault admins can remove users');
        }
        if (this.vaults.isVaultLocked(sharedVault)) {
            throw new Error('Cannot remove user from locked vault');
        }
        const useCase = new RemoveSharedVaultMember_1.RemoveVaultMemberUseCase(this.usersServer);
        const result = await useCase.execute({ sharedVaultUuid: sharedVault.sharing.sharedVaultUuid, userUuid });
        if ((0, responses_1.isClientDisplayableError)(result)) {
            return result;
        }
        void this.notifyCollaborationStatusChanged();
        await this.vaults.rotateVaultRootKey(sharedVault);
    }
    async leaveSharedVault(sharedVault) {
        const useCase = new LeaveSharedVault_1.LeaveVaultUseCase(this.usersServer, this.items, this.mutator, this.encryption, this.storage, this.sync);
        const result = await useCase.execute({
            sharedVault: sharedVault,
            userUuid: this.session.getSureUser().uuid,
        });
        if ((0, responses_1.isClientDisplayableError)(result)) {
            return result;
        }
        void this.notifyCollaborationStatusChanged();
    }
    async getSharedVaultUsers(sharedVault) {
        const useCase = new GetSharedVaultUsers_1.GetSharedVaultUsersUseCase(this.usersServer);
        return useCase.execute({ sharedVaultUuid: sharedVault.sharing.sharedVaultUuid });
    }
    async shareContactWithUserAdministeredSharedVaults(contact) {
        const sharedVaults = this.getAllSharedVaults();
        const useCase = new ShareContactWithAllMembersOfSharedVault_1.ShareContactWithAllMembersOfSharedVaultUseCase(this.contacts, this.encryption, this.usersServer, this.messageServer);
        for (const vault of sharedVaults) {
            if (!this.isCurrentUserSharedVaultAdmin(vault)) {
                continue;
            }
            await useCase.execute({
                senderKeyPair: this.encryption.getKeyPair(),
                senderSigningKeyPair: this.encryption.getSigningKeyPair(),
                sharedVault: vault,
                contactToShare: contact,
                senderUserUuid: this.session.getSureUser().uuid,
            });
        }
    }
    getItemLastEditedBy(item) {
        if (!item.last_edited_by_uuid) {
            return undefined;
        }
        const contact = this.contacts.findTrustedContact(item.last_edited_by_uuid);
        return contact;
    }
    getItemSharedBy(item) {
        if (!item.user_uuid || item.user_uuid === this.session.getSureUser().uuid) {
            return undefined;
        }
        const contact = this.contacts.findTrustedContact(item.user_uuid);
        return contact;
    }
    deinit() {
        super.deinit();
        this.contacts = undefined;
        this.encryption = undefined;
        this.files = undefined;
        this.invitesServer = undefined;
        this.items = undefined;
        this.messageServer = undefined;
        this.server = undefined;
        this.session = undefined;
        this.sync = undefined;
        this.usersServer = undefined;
        this.vaults = undefined;
    }
}
exports.SharedVaultService = SharedVaultService;
