"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useItemLinks = void 0;
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const LinkingControllerProvider_1 = require("@/Controllers/LinkingControllerProvider");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
const useItemLinks = (item) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const linkingController = (0, LinkingControllerProvider_1.useLinkingController)();
    const { getLinkedNotesForItem, getNotesLinkingToItem, getFilesLinksForItem, getLinkedTagsForItem } = linkingController;
    const [, refresh] = (0, react_1.useState)(Date.now());
    const notesLinkedToItem = getLinkedNotesForItem(item) || [];
    const notesLinkingToItem = getNotesLinkingToItem(item) || [];
    const { filesLinkedToItem, filesLinkingToItem } = getFilesLinksForItem(item);
    const tagsLinkedToItem = getLinkedTagsForItem(item) || [];
    (0, react_1.useEffect)(() => application.streamItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.File, snjs_1.ContentType.TYPES.Tag], () => {
        refresh(Date.now());
    }), [application]);
    return {
        notesLinkedToItem,
        notesLinkingToItem,
        filesLinkedToItem,
        filesLinkingToItem,
        tagsLinkedToItem,
    };
};
exports.useItemLinks = useItemLinks;
