"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricEncryptUseCase = void 0;
const Algorithm_1 = require("../../../../Algorithm");
const V004AlgorithmTypes_1 = require("../../V004AlgorithmTypes");
const CreateConsistentBase64JsonPayload_1 = require("../Utils/CreateConsistentBase64JsonPayload");
class AsymmetricEncryptUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.base64DataUsecase = new CreateConsistentBase64JsonPayload_1.CreateConsistentBase64JsonPayloadUseCase(this.crypto);
    }
    execute(dto) {
        const nonce = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.AsymmetricEncryptionNonceLength);
        const ciphertext = this.crypto.sodiumCryptoBoxEasyEncrypt(dto.stringToEncrypt, nonce, dto.senderKeyPair.privateKey, dto.recipientPublicKey);
        const additionalData = {
            signingData: {
                publicKey: dto.senderSigningKeyPair.publicKey,
                signature: this.crypto.sodiumCryptoSign(ciphertext, dto.senderSigningKeyPair.privateKey),
            },
            senderPublicKey: dto.senderKeyPair.publicKey,
        };
        const components = [
            V004AlgorithmTypes_1.V004AsymmetricCiphertextPrefix,
            nonce,
            ciphertext,
            this.base64DataUsecase.execute(additionalData),
        ];
        return components.join(':');
    }
}
exports.AsymmetricEncryptUseCase = AsymmetricEncryptUseCase;
