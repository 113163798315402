"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimplenoteConverter = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const Utils_1 = require("../Utils");
const isSimplenoteEntry = (entry) => entry.id && entry.content && entry.creationDate && entry.lastModified;
class SimplenoteConverter {
    constructor(application) {
        this.application = application;
    }
    static isValidSimplenoteJson(json) {
        return ((json.activeNotes && json.activeNotes.every(isSimplenoteEntry)) ||
            (json.trashedNotes && json.trashedNotes.every(isSimplenoteEntry)));
    }
    async convertSimplenoteBackupFileToNotes(file) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const notes = this.parse(content);
        if (!notes) {
            throw new Error('Could not parse notes');
        }
        return notes;
    }
    createNoteFromItem(item, trashed) {
        const createdAtDate = new Date(item.creationDate);
        const updatedAtDate = new Date(item.lastModified);
        const splitItemContent = item.content.split('\r\n');
        const hasTitleAndContent = splitItemContent.length === 2;
        const title = hasTitleAndContent && splitItemContent[0].length ? splitItemContent[0] : createdAtDate.toLocaleString();
        const content = hasTitleAndContent && splitItemContent[1].length ? splitItemContent[1] : item.content;
        return {
            created_at: createdAtDate,
            created_at_timestamp: createdAtDate.getTime(),
            updated_at: updatedAtDate,
            updated_at_timestamp: updatedAtDate.getTime(),
            uuid: this.application.generateUUID(),
            content_type: domain_core_1.ContentType.TYPES.Note,
            content: {
                title,
                text: content,
                references: [],
                trashed,
                appData: {
                    'org.standardnotes.sn': {
                        client_updated_at: updatedAtDate,
                    },
                },
            },
        };
    }
    parse(data) {
        try {
            const parsed = JSON.parse(data);
            const activeNotes = parsed.activeNotes.reverse().map((item) => this.createNoteFromItem(item, false));
            const trashedNotes = parsed.trashedNotes.reverse().map((item) => this.createNoteFromItem(item, true));
            return [...activeNotes, ...trashedNotes];
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
}
exports.SimplenoteConverter = SimplenoteConverter;
