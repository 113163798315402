"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewControllerManager = void 0;
const PaneController_1 = require("./PaneController/PaneController");
const ui_services_1 = require("@standardnotes/ui-services");
const AccountMenuController_1 = require("@/Controllers/AccountMenu/AccountMenuController");
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const ActionsMenuController_1 = require("./ActionsMenuController");
const FeaturesController_1 = require("./FeaturesController");
const FilesController_1 = require("./FilesController");
const NotesController_1 = require("./NotesController/NotesController");
const ItemListController_1 = require("./ItemList/ItemListController");
const NoAccountWarningController_1 = require("./NoAccountWarningController");
const PreferencesController_1 = require("./PreferencesController");
const PurchaseFlowController_1 = require("./PurchaseFlow/PurchaseFlowController");
const QuickSettingsController_1 = require("./QuickSettingsController");
const SearchOptionsController_1 = require("./SearchOptionsController");
const SubscriptionController_1 = require("./Subscription/SubscriptionController");
const SyncStatusController_1 = require("./SyncStatusController");
const NavigationController_1 = require("./Navigation/NavigationController");
const FilePreviewModalController_1 = require("./FilePreviewModalController");
const SelectedItemsController_1 = require("./SelectedItemsController");
const HistoryModalController_1 = require("./NoteHistory/HistoryModalController");
const LinkingController_1 = require("./LinkingController");
const PersistenceService_1 = require("./Abstract/PersistenceService");
const CrossControllerEvent_1 = require("./CrossControllerEvent");
const ApplicationEventObserver_1 = require("@/Event/ApplicationEventObserver");
const ImportModalController_1 = require("./ImportModalController");
const VaultSelectionMenuController_1 = require("./VaultSelectionMenuController");
class ViewControllerManager {
    constructor(application, device) {
        var _a;
        this.application = application;
        this.device = device;
        this.enableUnfinishedFeatures = window === null || window === void 0 ? void 0 : window.enabledUnfinishedFeatures;
        this.dealloced = false;
        this.actionsMenuController = new ActionsMenuController_1.ActionsMenuController();
        this.syncStatusController = new SyncStatusController_1.SyncStatusController();
        this.isSessionsModalVisible = false;
        this.appEventObserverRemovers = [];
        this.openSessionsModal = () => {
            this.isSessionsModalVisible = true;
        };
        this.closeSessionsModal = () => {
            this.isSessionsModalVisible = false;
        };
        this.persistValues = () => {
            const values = {
                [ui_services_1.PersistenceKey.SelectedItemsController]: this.selectionController.getPersistableValue(),
                [ui_services_1.PersistenceKey.NavigationController]: this.navigationController.getPersistableValue(),
            };
            this.persistenceService.persistValues(values);
            const selectedItemsState = values['selected-items-controller'];
            const navigationSelectionState = values['navigation-controller'];
            const launchPriorityUuids = [];
            if (selectedItemsState.selectedUuids.length) {
                launchPriorityUuids.push(...selectedItemsState.selectedUuids);
            }
            if (navigationSelectionState.selectedTagUuid) {
                launchPriorityUuids.push(navigationSelectionState.selectedTagUuid);
            }
            this.application.sync.setLaunchPriorityUuids(launchPriorityUuids);
        };
        this.clearPersistedValues = () => {
            this.persistenceService.clearPersistedValues();
        };
        this.hydrateFromPersistedValues = (values) => {
            const navigationState = values === null || values === void 0 ? void 0 : values[ui_services_1.PersistenceKey.NavigationController];
            this.navigationController.hydrateFromPersistedValue(navigationState);
            const selectedItemsState = values === null || values === void 0 ? void 0 : values[ui_services_1.PersistenceKey.SelectedItemsController];
            this.selectionController.hydrateFromPersistedValue(selectedItemsState);
        };
        const eventBus = application.internalEventBus;
        this.persistenceService = new PersistenceService_1.PersistenceService(application, eventBus);
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.HydrateFromPersistedValues);
        eventBus.addEventHandler(this, CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence);
        this.subscriptionManager = application.subscriptions;
        this.filePreviewModalController = new FilePreviewModalController_1.FilePreviewModalController(application);
        this.quickSettingsMenuController = new QuickSettingsController_1.QuickSettingsController(application, eventBus);
        this.vaultSelectionController = new VaultSelectionMenuController_1.VaultSelectionMenuController(application, eventBus);
        this.paneController = new PaneController_1.PaneController(application, eventBus);
        this.preferencesController = new PreferencesController_1.PreferencesController(application, eventBus);
        this.selectionController = new SelectedItemsController_1.SelectedItemsController(application, eventBus);
        this.featuresController = new FeaturesController_1.FeaturesController(application, eventBus);
        this.navigationController = new NavigationController_1.NavigationController(application, this.featuresController, eventBus);
        this.notesController = new NotesController_1.NotesController(application, this.selectionController, this.navigationController, eventBus);
        this.searchOptionsController = new SearchOptionsController_1.SearchOptionsController(application, eventBus);
        this.linkingController = new LinkingController_1.LinkingController(application, this.navigationController, this.selectionController, eventBus);
        this.itemListController = new ItemListController_1.ItemListController(application, this.navigationController, this.searchOptionsController, this.selectionController, this.notesController, eventBus);
        this.notesController.setServicesPostConstruction(this.itemListController);
        this.selectionController.setServicesPostConstruction(this.itemListController);
        this.noAccountWarningController = new NoAccountWarningController_1.NoAccountWarningController(application, eventBus);
        this.accountMenuController = new AccountMenuController_1.AccountMenuController(application, eventBus);
        this.subscriptionController = new SubscriptionController_1.SubscriptionController(application, eventBus, this.subscriptionManager);
        this.purchaseFlowController = new PurchaseFlowController_1.PurchaseFlowController(application, eventBus);
        this.filesController = new FilesController_1.FilesController(application, this.notesController, this.filePreviewModalController, eventBus);
        this.linkingController.setServicesPostConstruction(this.itemListController, this.filesController, this.subscriptionController);
        this.historyModalController = new HistoryModalController_1.HistoryModalController(this.application, eventBus, this.notesController);
        this.importModalController = new ImportModalController_1.ImportModalController(this.application, this.navigationController);
        this.toastService = new ui_services_1.ToastService();
        this.applicationEventObserver = new ApplicationEventObserver_1.ApplicationEventObserver(application, application.routeService, this.purchaseFlowController, this.accountMenuController, this.preferencesController, this.syncStatusController, application.sync, application.sessions, application.subscriptions, this.toastService, application.user);
        this.addAppEventObserver();
        if (this.device.appVersion.includes('-beta')) {
            this.showBetaWarning = (_a = ui_services_1.storage.get(ui_services_1.StorageKey.ShowBetaWarning)) !== null && _a !== void 0 ? _a : true;
        }
        else {
            this.showBetaWarning = false;
        }
        (0, mobx_1.makeObservable)(this, {
            showBetaWarning: mobx_1.observable,
            isSessionsModalVisible: mobx_1.observable,
            preferencesController: mobx_1.observable,
            openSessionsModal: mobx_1.action,
            closeSessionsModal: mobx_1.action,
        });
    }
    deinit(source) {
        var _a;
        this.dealloced = true;
        this.application = undefined;
        if (source === snjs_1.DeinitSource.SignOut) {
            ui_services_1.storage.remove(ui_services_1.StorageKey.ShowBetaWarning);
            this.noAccountWarningController.reset();
        }
        (_a = this.unsubAppEventObserver) === null || _a === void 0 ? void 0 : _a.call(this);
        this.unsubAppEventObserver = undefined;
        this.appEventObserverRemovers.forEach((remover) => remover());
        this.appEventObserverRemovers.length = 0;
        this.device = undefined;
        this.filePreviewModalController.deinit();
        this.filePreviewModalController = undefined;
        this.preferencesController = undefined;
        this.quickSettingsMenuController = undefined;
        this.vaultSelectionController = undefined;
        this.syncStatusController = undefined;
        this.persistenceService.deinit();
        this.persistenceService = undefined;
        this.actionsMenuController.reset();
        this.actionsMenuController = undefined;
        this.featuresController.deinit();
        this.featuresController = undefined;
        this.accountMenuController.deinit();
        this.accountMenuController = undefined;
        this.filesController.deinit();
        this.filesController = undefined;
        this.noAccountWarningController.deinit();
        this.noAccountWarningController = undefined;
        this.notesController.deinit();
        this.notesController = undefined;
        this.itemListController.deinit();
        this.itemListController = undefined;
        this.linkingController.deinit();
        this.linkingController = undefined;
        this.purchaseFlowController.deinit();
        this.purchaseFlowController = undefined;
        this.searchOptionsController.deinit();
        this.searchOptionsController = undefined;
        this.subscriptionController.deinit();
        this.subscriptionController = undefined;
        this.navigationController.deinit();
        this.navigationController = undefined;
        this.historyModalController.deinit();
        this.historyModalController = undefined;
        this.paneController.deinit();
        this.paneController = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    addAppEventObserver() {
        this.unsubAppEventObserver = this.application.addEventObserver(this.applicationEventObserver.handle.bind(this.applicationEventObserver));
    }
    async handleEvent(event) {
        if (event.type === CrossControllerEvent_1.CrossControllerEvent.HydrateFromPersistedValues) {
            this.hydrateFromPersistedValues(event.payload);
        }
        else if (event.type === CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence) {
            this.persistValues();
        }
    }
}
exports.ViewControllerManager = ViewControllerManager;
