"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteSyncController = void 0;
const models_1 = require("@standardnotes/models");
const snjs_1 = require("@standardnotes/snjs");
const utils_1 = require("@standardnotes/utils");
const EditorSaveTimeoutDebounce_1 = require("../Components/NoteView/Controller/EditorSaveTimeoutDebounce");
const NotePreviewCharLimit = 160;
class NoteSyncController {
    constructor(application, item) {
        this.application = application;
        this.item = item;
        this.savingLocallyPromise = null;
    }
    setItem(item) {
        this.item = item;
    }
    deinit() {
        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
        }
        if (this.savingLocallyPromise) {
            this.savingLocallyPromise.reject();
        }
        this.savingLocallyPromise = null;
        this.saveTimeout = undefined;
        this.application = undefined;
        this.item = undefined;
    }
    async saveAndAwaitLocalPropagation(params) {
        this.savingLocallyPromise = (0, utils_1.Deferred)();
        if (this.saveTimeout) {
            clearTimeout(this.saveTimeout);
        }
        const noDebounce = params.bypassDebouncer || this.application.noAccount();
        const syncDebouceMs = noDebounce
            ? EditorSaveTimeoutDebounce_1.EditorSaveTimeoutDebounce.ImmediateChange
            : this.application.isNativeMobileWeb()
                ? EditorSaveTimeoutDebounce_1.EditorSaveTimeoutDebounce.NativeMobileWeb
                : EditorSaveTimeoutDebounce_1.EditorSaveTimeoutDebounce.Desktop;
        return new Promise((resolve) => {
            this.saveTimeout = setTimeout(() => {
                void this.undebouncedSave({
                    ...params,
                    onLocalPropagationComplete: () => {
                        if (this.savingLocallyPromise) {
                            this.savingLocallyPromise.resolve();
                        }
                        resolve();
                    },
                });
            }, syncDebouceMs);
        });
    }
    async undebouncedSave(params) {
        var _a;
        if (!this.application.items.findItem(this.item.uuid)) {
            void this.application.alertService.alert(snjs_1.InfoStrings.InvalidNote);
            return;
        }
        await this.application.mutator.changeItem(this.item, (mutator) => {
            const noteMutator = mutator;
            if (params.customMutate) {
                params.customMutate(noteMutator);
            }
            if (params.title != undefined) {
                noteMutator.title = params.title;
            }
            if (params.text != undefined) {
                noteMutator.text = params.text;
            }
            if (params.previews) {
                noteMutator.preview_plain = params.previews.previewPlain;
                noteMutator.preview_html = params.previews.previewHtml;
            }
            else if (!params.dontGeneratePreviews && params.text != undefined) {
                const noteText = params.text || '';
                const truncate = noteText.length > NotePreviewCharLimit;
                const substring = noteText.substring(0, NotePreviewCharLimit);
                const previewPlain = substring + (truncate ? '...' : '');
                noteMutator.preview_plain = previewPlain;
                noteMutator.preview_html = undefined;
            }
        }, params.isUserModified ? models_1.MutationType.UpdateUserTimestamps : models_1.MutationType.NoUpdateUserTimestamps);
        void this.application.sync.sync().then(() => {
            var _a;
            (_a = params.onRemoteSyncComplete) === null || _a === void 0 ? void 0 : _a.call(params);
        });
        (_a = params.onLocalPropagationComplete) === null || _a === void 0 ? void 0 : _a.call(params);
    }
}
exports.NoteSyncController = NoteSyncController;
