"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeriveRootKeyUseCase = void 0;
const utils_1 = require("@standardnotes/utils");
const V004AlgorithmTypes_1 = require("../../V004AlgorithmTypes");
const Algorithm_1 = require("../../../../Algorithm");
const Functions_1 = require("../../../../Keys/RootKey/Functions");
const common_1 = require("@standardnotes/common");
class DeriveRootKeyUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    async execute(password, keyParams) {
        const seed = keyParams.content004.pw_nonce;
        const salt = await this.generateSalt(keyParams.content004.identifier, seed);
        const derivedKey = this.crypto.argon2(password, salt, Algorithm_1.V004Algorithm.ArgonIterations, Algorithm_1.V004Algorithm.ArgonMemLimit, Algorithm_1.V004Algorithm.ArgonOutputKeyBytes);
        const partitions = (0, utils_1.splitString)(derivedKey, 2);
        const masterKey = partitions[0];
        const serverPassword = partitions[1];
        const encryptionKeyPairSeed = this.crypto.sodiumCryptoKdfDeriveFromKey(masterKey, Algorithm_1.V004Algorithm.MasterKeyEncryptionKeyPairSubKeyNumber, Algorithm_1.V004Algorithm.MasterKeyEncryptionKeyPairSubKeyBytes, Algorithm_1.V004Algorithm.MasterKeyEncryptionKeyPairSubKeyContext);
        const encryptionKeyPair = this.crypto.sodiumCryptoBoxSeedKeypair(encryptionKeyPairSeed);
        const signingKeyPairSeed = this.crypto.sodiumCryptoKdfDeriveFromKey(masterKey, Algorithm_1.V004Algorithm.MasterKeySigningKeyPairSubKeyNumber, Algorithm_1.V004Algorithm.MasterKeySigningKeyPairSubKeyBytes, Algorithm_1.V004Algorithm.MasterKeySigningKeyPairSubKeyContext);
        const signingKeyPair = this.crypto.sodiumCryptoSignSeedKeypair(signingKeyPairSeed);
        return (0, Functions_1.CreateNewRootKey)({
            masterKey,
            serverPassword,
            version: common_1.ProtocolVersion.V004,
            keyParams: keyParams.getPortableValue(),
            encryptionKeyPair,
            signingKeyPair,
        });
    }
    /**
     * We require both a client-side component and a server-side component in generating a
     * salt. This way, a comprimised server cannot benefit from sending the same seed value
     * for every user. We mix a client-controlled value that is globally unique
     * (their identifier), with a server controlled value to produce a salt for our KDF.
     * @param identifier
     * @param seed
     */
    async generateSalt(identifier, seed) {
        const hash = await this.crypto.sha256([identifier, seed].join(V004AlgorithmTypes_1.V004PartitionCharacter));
        return (0, utils_1.truncateHexString)(hash, Algorithm_1.V004Algorithm.ArgonSaltLength);
    }
}
exports.DeriveRootKeyUseCase = DeriveRootKeyUseCase;
