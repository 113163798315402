"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountMenuController = void 0;
const Utils_1 = require("@/Utils");
const mobx_1 = require("mobx");
const snjs_1 = require("@standardnotes/snjs");
const AccountMenuPane_1 = require("@/Components/AccountMenu/AccountMenuPane");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
class AccountMenuController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.notesAndTags = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    constructor(application, eventBus) {
        super(application, eventBus);
        this.show = false;
        this.signingOut = false;
        this.otherSessionsSignOut = false;
        this.server = undefined;
        this.enableServerOption = false;
        this.notesAndTags = [];
        this.isEncryptionEnabled = false;
        this.encryptionStatusString = '';
        this.isBackupEncrypted = false;
        this.showSignIn = false;
        this.deletingAccount = false;
        this.showRegister = false;
        this.shouldAnimateCloseMenu = false;
        this.currentPane = AccountMenuPane_1.AccountMenuPane.GeneralMenu;
        this.setShow = (show) => {
            this.show = show;
        };
        this.setShouldAnimateClose = (shouldAnimateCloseMenu) => {
            this.shouldAnimateCloseMenu = shouldAnimateCloseMenu;
        };
        this.closeAccountMenu = () => {
            this.setShouldAnimateClose(true);
            setTimeout(() => {
                this.setShow(false);
                this.setShouldAnimateClose(false);
                this.setCurrentPane(AccountMenuPane_1.AccountMenuPane.GeneralMenu);
            }, 150);
        };
        this.setSigningOut = (signingOut) => {
            this.signingOut = signingOut;
        };
        this.setServer = (server) => {
            this.server = server;
        };
        this.setEnableServerOption = (enableServerOption) => {
            this.enableServerOption = enableServerOption;
        };
        this.setIsEncryptionEnabled = (isEncryptionEnabled) => {
            this.isEncryptionEnabled = isEncryptionEnabled;
        };
        this.setEncryptionStatusString = (encryptionStatusString) => {
            this.encryptionStatusString = encryptionStatusString;
        };
        this.setIsBackupEncrypted = (isBackupEncrypted) => {
            this.isBackupEncrypted = isBackupEncrypted;
        };
        this.setShowSignIn = (showSignIn) => {
            this.showSignIn = showSignIn;
        };
        this.setShowRegister = (showRegister) => {
            this.showRegister = showRegister;
        };
        this.toggleShow = () => {
            if (this.show) {
                this.closeAccountMenu();
            }
            else {
                this.setShow(true);
            }
        };
        this.setOtherSessionsSignOut = (otherSessionsSignOut) => {
            this.otherSessionsSignOut = otherSessionsSignOut;
        };
        this.setCurrentPane = (pane) => {
            this.currentPane = pane;
        };
        this.setDeletingAccount = (deletingAccount) => {
            this.deletingAccount = deletingAccount;
        };
        (0, mobx_1.makeObservable)(this, {
            show: mobx_1.observable,
            signingOut: mobx_1.observable,
            otherSessionsSignOut: mobx_1.observable,
            server: mobx_1.observable,
            enableServerOption: mobx_1.observable,
            notesAndTags: mobx_1.observable,
            isEncryptionEnabled: mobx_1.observable,
            encryptionStatusString: mobx_1.observable,
            isBackupEncrypted: mobx_1.observable,
            showSignIn: mobx_1.observable,
            deletingAccount: mobx_1.observable,
            showRegister: mobx_1.observable,
            currentPane: mobx_1.observable,
            shouldAnimateCloseMenu: mobx_1.observable,
            setShow: mobx_1.action,
            setShouldAnimateClose: mobx_1.action,
            toggleShow: mobx_1.action,
            setSigningOut: mobx_1.action,
            setIsEncryptionEnabled: mobx_1.action,
            setEncryptionStatusString: mobx_1.action,
            setIsBackupEncrypted: mobx_1.action,
            setOtherSessionsSignOut: mobx_1.action,
            setCurrentPane: mobx_1.action,
            setEnableServerOption: mobx_1.action,
            setServer: mobx_1.action,
            setDeletingAccount: mobx_1.action,
            notesAndTagsCount: mobx_1.computed,
        });
        this.disposers.push(this.application.addEventObserver(async () => {
            (0, mobx_1.runInAction)(() => {
                if (Utils_1.isDev && window.devAccountServer) {
                    this.setServer(window.devAccountServer);
                    this.application.setCustomHost(window.devAccountServer).catch(console.error);
                }
                else {
                    this.setServer(this.application.getHost());
                }
            });
        }, snjs_1.ApplicationEvent.Launched));
        this.disposers.push(this.application.streamItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.Tag], () => {
            (0, mobx_1.runInAction)(() => {
                this.notesAndTags = this.application.items.getItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.Tag]);
            });
        }));
    }
    get notesAndTagsCount() {
        return this.notesAndTags.length;
    }
}
exports.AccountMenuController = AccountMenuController;
