"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootKeyDecryptPayloadWithKeyLookupUseCase = void 0;
const models_1 = require("@standardnotes/models");
const DecryptPayload_1 = require("./DecryptPayload");
class RootKeyDecryptPayloadWithKeyLookupUseCase {
    constructor(operatorManager, keySystemKeyManager, rootKeyManager) {
        this.operatorManager = operatorManager;
        this.keySystemKeyManager = keySystemKeyManager;
        this.rootKeyManager = rootKeyManager;
    }
    async executeOne(payload) {
        let key;
        if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(payload.content_type)) {
            if (!payload.key_system_identifier) {
                throw Error('Key system root key encrypted payload is missing key_system_identifier');
            }
            key = this.keySystemKeyManager.getPrimaryKeySystemRootKey(payload.key_system_identifier);
        }
        else {
            key = this.rootKeyManager.getRootKey();
        }
        if (key == undefined) {
            return {
                uuid: payload.uuid,
                errorDecrypting: true,
                waitingForKey: true,
            };
        }
        const usecase = new DecryptPayload_1.RootKeyDecryptPayloadUseCase(this.operatorManager);
        return usecase.executeOne(payload, key);
    }
    async executeMany(payloads) {
        return Promise.all(payloads.map((payload) => this.executeOne(payload)));
    }
}
exports.RootKeyDecryptPayloadWithKeyLookupUseCase = RootKeyDecryptPayloadWithKeyLookupUseCase;
