"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.downloadBlobOnAndroid = void 0;
const Utils_1 = require("@/Utils");
const filepicker_1 = require("@standardnotes/filepicker");
const snjs_1 = require("@standardnotes/snjs");
const toast_1 = require("@standardnotes/toast");
const ui_services_1 = require("@standardnotes/ui-services");
const downloadBlobOnAndroid = async (application, blob, filename, showToast = true) => {
    if (!application.isNativeMobileWeb() || application.platform !== snjs_1.Platform.Android) {
        throw new Error('Download function being used on non-android platform');
    }
    let loadingToastId;
    if (showToast) {
        loadingToastId = (0, toast_1.addToast)({
            type: toast_1.ToastType.Loading,
            message: `Downloading ${filename}..`,
        });
    }
    const base64 = await (0, Utils_1.getBase64FromBlob)(blob);
    const { name, ext } = (0, filepicker_1.parseFileName)(filename);
    const sanitizedName = (0, ui_services_1.sanitizeFileName)(name);
    filename = `${sanitizedName}.${ext}`;
    const downloaded = await application.mobileDevice().downloadBase64AsFile(base64, filename);
    if (loadingToastId) {
        (0, toast_1.dismissToast)(loadingToastId);
    }
    if (!showToast) {
        return;
    }
    if (downloaded) {
        (0, toast_1.addToast)({
            type: toast_1.ToastType.Success,
            message: `Downloaded ${filename}`,
        });
    }
    else {
        (0, toast_1.addToast)({
            type: toast_1.ToastType.Error,
            message: `Could not download ${filename}`,
        });
    }
};
exports.downloadBlobOnAndroid = downloadBlobOnAndroid;
