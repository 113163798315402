"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleTrustedSharedVaultRootKeyChangedMessage = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
const GetVault_1 = require("../../Vaults/UseCase/GetVault");
class HandleTrustedSharedVaultRootKeyChangedMessage {
    constructor(mutator, items, sync, encryption) {
        this.mutator = mutator;
        this.items = items;
        this.sync = sync;
        this.encryption = encryption;
    }
    async execute(message) {
        const rootKeyContent = message.data.rootKey;
        await this.mutator.createItem(domain_core_1.ContentType.TYPES.KeySystemRootKey, (0, models_1.FillItemContent)(rootKeyContent), true);
        const vault = new GetVault_1.GetVaultUseCase(this.items).execute({ keySystemIdentifier: rootKeyContent.systemIdentifier });
        if (vault) {
            await this.mutator.changeItem(vault, (mutator) => {
                mutator.rootKeyParams = rootKeyContent.keyParams;
            });
        }
        await this.encryption.decryptErroredPayloads();
        void this.sync.sync({ sourceDescription: 'Not awaiting due to this event handler running from sync response' });
    }
}
exports.HandleTrustedSharedVaultRootKeyChangedMessage = HandleTrustedSharedVaultRootKeyChangedMessage;
