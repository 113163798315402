"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrustedContactMutator = void 0;
const Item_1 = require("../../Abstract/Item");
const ContactPublicKeySet_1 = require("./PublicKeySet/ContactPublicKeySet");
class TrustedContactMutator extends Item_1.DecryptedItemMutator {
    set name(newName) {
        this.mutableContent.name = newName;
    }
    addPublicKey(params) {
        const newKey = new ContactPublicKeySet_1.ContactPublicKeySet(params.encryption, params.signing, new Date(), false, this.immutableItem.publicKeySet);
        this.mutableContent.publicKeySet = newKey;
    }
    replacePublicKeySet(publicKeySet) {
        this.mutableContent.publicKeySet = publicKeySet;
    }
}
exports.TrustedContactMutator = TrustedContactMutator;
