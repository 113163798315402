"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandleTrustedSharedVaultInviteMessage = void 0;
const models_1 = require("@standardnotes/models");
const domain_core_1 = require("@standardnotes/domain-core");
class HandleTrustedSharedVaultInviteMessage {
    constructor(mutator, sync, contacts) {
        this.mutator = mutator;
        this.sync = sync;
        this.contacts = contacts;
    }
    async execute(message, sharedVaultUuid, senderUuid) {
        const { rootKey: rootKeyContent, trustedContacts, metadata } = message.data;
        const content = {
            systemIdentifier: rootKeyContent.systemIdentifier,
            rootKeyParams: rootKeyContent.keyParams,
            keyStorageMode: models_1.KeySystemRootKeyStorageMode.Synced,
            name: metadata.name,
            description: metadata.description,
            sharing: {
                sharedVaultUuid: sharedVaultUuid,
                ownerUserUuid: senderUuid,
            },
        };
        await this.mutator.createItem(domain_core_1.ContentType.TYPES.KeySystemRootKey, (0, models_1.FillItemContent)(rootKeyContent), true);
        await this.mutator.createItem(domain_core_1.ContentType.TYPES.VaultListing, (0, models_1.FillItemContentSpecialized)(content), true);
        for (const contact of trustedContacts) {
            if (contact.isMe) {
                throw new Error('Should not receive isMe contact from invite');
            }
            await this.contacts.createOrEditTrustedContact({
                name: contact.name,
                contactUuid: contact.contactUuid,
                publicKey: contact.publicKeySet.encryption,
                signingPublicKey: contact.publicKeySet.signing,
            });
        }
        void this.sync.sync({ sourceDescription: 'Not awaiting due to this event handler running from sync response' });
    }
}
exports.HandleTrustedSharedVaultInviteMessage = HandleTrustedSharedVaultInviteMessage;
