"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactService = void 0;
const SessionEvent_1 = require("./../Session/SessionEvent");
const models_1 = require("@standardnotes/models");
const AbstractService_1 = require("../Service/AbstractService");
const CollaborationID_1 = require("./CollaborationID");
const ValidateItemSigner_1 = require("./UseCase/ValidateItemSigner");
const FindTrustedContact_1 = require("./UseCase/FindTrustedContact");
const SelfContactManager_1 = require("./Managers/SelfContactManager");
const CreateOrEditTrustedContact_1 = require("./UseCase/CreateOrEditTrustedContact");
const UpdateTrustedContact_1 = require("./UseCase/UpdateTrustedContact");
const domain_core_1 = require("@standardnotes/domain-core");
class ContactService extends AbstractService_1.AbstractService {
    constructor(sync, items, mutator, session, crypto, user, encryption, singletons, eventBus) {
        super(eventBus);
        this.sync = sync;
        this.items = items;
        this.mutator = mutator;
        this.session = session;
        this.crypto = crypto;
        this.user = user;
        this.encryption = encryption;
        this.selfContactManager = new SelfContactManager_1.SelfContactManager(sync, items, mutator, session, singletons);
        eventBus.addEventHandler(this, SessionEvent_1.SessionEvent.UserKeyPairChanged);
    }
    async handleApplicationStage(stage) {
        await super.handleApplicationStage(stage);
        await this.selfContactManager.handleApplicationStage(stage);
    }
    async handleEvent(event) {
        if (event.type === SessionEvent_1.SessionEvent.UserKeyPairChanged) {
            const data = event.payload;
            await this.selfContactManager.updateWithNewPublicKeySet({
                encryption: data.newKeyPair.publicKey,
                signing: data.newSigningKeyPair.publicKey,
            });
        }
    }
    get userUuid() {
        return this.session.getSureUser().uuid;
    }
    getSelfContact() {
        return this.selfContactManager.selfContact;
    }
    isCollaborationEnabled() {
        return !this.session.isUserMissingKeyPair();
    }
    async enableCollaboration() {
        await this.user.updateAccountWithFirstTimeKeyPair();
    }
    getCollaborationID() {
        const publicKey = this.session.getPublicKey();
        if (!publicKey) {
            throw new Error('Collaboration not enabled');
        }
        return this.buildCollaborationId({
            version: CollaborationID_1.Version1CollaborationId,
            userUuid: this.session.getSureUser().uuid,
            publicKey,
            signingPublicKey: this.session.getSigningPublicKey(),
        });
    }
    buildCollaborationId(params) {
        const string = `${params.version}:${params.userUuid}:${params.publicKey}:${params.signingPublicKey}`;
        return this.crypto.base64Encode(string);
    }
    parseCollaborationID(collaborationID) {
        const decoded = this.crypto.base64Decode(collaborationID);
        const [version, userUuid, publicKey, signingPublicKey] = decoded.split(':');
        return { version, userUuid, publicKey, signingPublicKey };
    }
    getCollaborationIDFromInvite(invite) {
        const publicKeySet = this.encryption.getSenderPublicKeySetFromAsymmetricallyEncryptedString(invite.encrypted_message);
        return this.buildCollaborationId({
            version: CollaborationID_1.Version1CollaborationId,
            userUuid: invite.sender_uuid,
            publicKey: publicKeySet.encryption,
            signingPublicKey: publicKeySet.signing,
        });
    }
    addTrustedContactFromCollaborationID(collaborationID, name) {
        const { userUuid, publicKey, signingPublicKey } = this.parseCollaborationID(collaborationID);
        return this.createOrEditTrustedContact({
            name: name !== null && name !== void 0 ? name : '',
            contactUuid: userUuid,
            publicKey,
            signingPublicKey,
        });
    }
    async editTrustedContactFromCollaborationID(contact, params) {
        const { publicKey, signingPublicKey, userUuid } = this.parseCollaborationID(params.collaborationID);
        if (userUuid !== contact.contactUuid) {
            throw new Error("Collaboration ID's user uuid does not match contact UUID");
        }
        const updatedContact = await this.mutator.changeItem(contact, (mutator) => {
            mutator.name = params.name;
            if (publicKey !== contact.publicKeySet.encryption || signingPublicKey !== contact.publicKeySet.signing) {
                mutator.addPublicKey({
                    encryption: publicKey,
                    signing: signingPublicKey,
                });
            }
        });
        await this.sync.sync();
        return updatedContact;
    }
    async updateTrustedContact(contact, params) {
        const usecase = new UpdateTrustedContact_1.UpdateTrustedContactUseCase(this.mutator, this.sync);
        const updatedContact = await usecase.execute(contact, params);
        return updatedContact;
    }
    async createOrUpdateTrustedContactFromContactShare(data) {
        if (data.contactUuid === this.userUuid) {
            throw new Error('Cannot receive self from contact share');
        }
        let contact = this.findTrustedContact(data.contactUuid);
        if (contact) {
            contact = await this.mutator.changeItem(contact, (mutator) => {
                mutator.name = data.name;
                mutator.replacePublicKeySet(data.publicKeySet);
            });
        }
        else {
            contact = await this.mutator.createItem(domain_core_1.ContentType.TYPES.TrustedContact, (0, models_1.FillItemContent)(data), true);
        }
        await this.sync.sync();
        return contact;
    }
    async createOrEditTrustedContact(params) {
        const usecase = new CreateOrEditTrustedContact_1.CreateOrEditTrustedContactUseCase(this.items, this.mutator, this.sync);
        const contact = await usecase.execute(params);
        return contact;
    }
    async deleteContact(contact) {
        if (contact.isMe) {
            throw new Error('Cannot delete self');
        }
        await this.mutator.setItemToBeDeleted(contact);
        await this.sync.sync();
    }
    getAllContacts() {
        return this.items.getItems(domain_core_1.ContentType.TYPES.TrustedContact);
    }
    findTrustedContact(userUuid) {
        const usecase = new FindTrustedContact_1.FindTrustedContactUseCase(this.items);
        return usecase.execute({ userUuid });
    }
    findTrustedContactForServerUser(user) {
        return this.findTrustedContact(user.user_uuid);
    }
    findTrustedContactForInvite(invite) {
        return this.findTrustedContact(invite.sender_uuid);
    }
    getCollaborationIDForTrustedContact(contact) {
        return this.buildCollaborationId({
            version: CollaborationID_1.Version1CollaborationId,
            userUuid: contact.content.contactUuid,
            publicKey: contact.content.publicKeySet.encryption,
            signingPublicKey: contact.content.publicKeySet.signing,
        });
    }
    isItemAuthenticallySigned(item) {
        const usecase = new ValidateItemSigner_1.ValidateItemSignerUseCase(this.items);
        return usecase.execute(item);
    }
    deinit() {
        super.deinit();
        this.selfContactManager.deinit();
        this.sync = undefined;
        this.items = undefined;
        this.selfContactManager = undefined;
    }
}
exports.ContactService = ContactService;
