"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendSharedVaultInviteUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
class SendSharedVaultInviteUseCase {
    constructor(vaultInvitesServer) {
        this.vaultInvitesServer = vaultInvitesServer;
    }
    async execute(params) {
        const response = await this.vaultInvitesServer.createInvite({
            sharedVaultUuid: params.sharedVaultUuid,
            recipientUuid: params.recipientUuid,
            encryptedMessage: params.encryptedMessage,
            permissions: params.permissions,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromNetworkError(response);
        }
        return response.data.invite;
    }
}
exports.SendSharedVaultInviteUseCase = SendSharedVaultInviteUseCase;
