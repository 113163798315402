"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RootKeyEncryptPayloadUseCase = void 0;
const encryption_1 = require("@standardnotes/encryption");
class RootKeyEncryptPayloadUseCase {
    constructor(operatorManager) {
        this.operatorManager = operatorManager;
    }
    async executeOne(payload, key, signingKeyPair) {
        return (0, encryption_1.encryptPayload)(payload, key, this.operatorManager, signingKeyPair);
    }
    async executeMany(payloads, key, signingKeyPair) {
        return Promise.all(payloads.map((payload) => this.executeOne(payload, key, signingKeyPair)));
    }
}
exports.RootKeyEncryptPayloadUseCase = RootKeyEncryptPayloadUseCase;
