"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SNTheme = exports.isTheme = void 0;
const features_1 = require("@standardnotes/features");
const Component_1 = require("../Component/Component");
const domain_core_1 = require("@standardnotes/domain-core");
const utils_1 = require("@standardnotes/utils");
const isTheme = (x) => x.content_type === domain_core_1.ContentType.TYPES.Theme;
exports.isTheme = isTheme;
class SNTheme extends Component_1.SNComponent {
    constructor() {
        super(...arguments);
        this.area = features_1.ComponentArea.Themes;
    }
    get layerable() {
        return (0, utils_1.useBoolean)(this.package_info && this.package_info.layerable, false);
    }
}
exports.SNTheme = SNTheme;
