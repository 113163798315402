"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteVaultUseCase = void 0;
class DeleteVaultUseCase {
    constructor(items, mutator, encryption) {
        this.items = items;
        this.mutator = mutator;
        this.encryption = encryption;
    }
    async execute(vault) {
        if (!vault.systemIdentifier) {
            throw new Error('Vault system identifier is missing');
        }
        await this.encryption.keys.deleteNonPersistentSystemRootKeysForVault(vault.systemIdentifier);
        const rootKeys = this.encryption.keys.getSyncedKeySystemRootKeysForVault(vault.systemIdentifier);
        await this.mutator.setItemsToBeDeleted(rootKeys);
        const itemsKeys = this.encryption.keys.getKeySystemItemsKeys(vault.systemIdentifier);
        await this.mutator.setItemsToBeDeleted(itemsKeys);
        const vaultItems = this.items.itemsBelongingToKeySystem(vault.systemIdentifier);
        await this.mutator.setItemsToBeDeleted(vaultItems);
        await this.mutator.setItemToBeDeleted(vault);
    }
}
exports.DeleteVaultUseCase = DeleteVaultUseCase;
