"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersistenceService = void 0;
const Persistence_1 = require("@/Components/Preferences/Panes/General/Persistence");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const CrossControllerEvent_1 = require("../CrossControllerEvent");
class PersistenceService {
    constructor(application, eventBus) {
        this.application = application;
        this.eventBus = eventBus;
        this.didHydrateOnce = false;
        this.hydratePersistedValues = () => {
            this.eventBus.publish({
                type: CrossControllerEvent_1.CrossControllerEvent.HydrateFromPersistedValues,
                payload: this.persistenceEnabled ? this.getPersistedValues() : undefined,
            });
        };
        this.unsubAppEventObserver = this.application.addEventObserver(async (eventName) => {
            if (!this.application) {
                return;
            }
            void this.onAppEvent(eventName);
        });
    }
    async onAppEvent(eventName) {
        if (eventName === snjs_1.ApplicationEvent.LocalDataLoaded && !this.didHydrateOnce) {
            this.hydratePersistedValues();
            this.didHydrateOnce = true;
        }
        else if (eventName === snjs_1.ApplicationEvent.LocalDataIncrementalLoad) {
            const canHydrate = this.application.items.getItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.Tag]).length > 0;
            if (!canHydrate) {
                return;
            }
            this.hydratePersistedValues();
            this.didHydrateOnce = true;
        }
    }
    get persistenceEnabled() {
        var _a;
        return (_a = this.application.getValue(Persistence_1.ShouldPersistNoteStateKey)) !== null && _a !== void 0 ? _a : true;
    }
    persistValues(values) {
        if (!this.application.isDatabaseLoaded()) {
            return;
        }
        if (!this.persistenceEnabled) {
            return;
        }
        this.application.setValue(ui_services_1.StorageKey.MasterStatePersistenceKey, values);
    }
    clearPersistedValues() {
        if (!this.application.isDatabaseLoaded()) {
            return;
        }
        void this.application.removeValue(ui_services_1.StorageKey.MasterStatePersistenceKey);
    }
    getPersistedValues() {
        return this.application.getValue(ui_services_1.StorageKey.MasterStatePersistenceKey);
    }
    deinit() {
        this.unsubAppEventObserver();
    }
}
exports.PersistenceService = PersistenceService;
