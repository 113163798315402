"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateNewDefaultItemsKeyUseCase = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
/**
 * Creates a new random items key to use for item encryption, and adds it to model management.
 * Consumer must call sync. If the protocol version <= 003, only one items key should be created,
 * and its .itemsKey value should be equal to the root key masterKey value.
 */
class CreateNewDefaultItemsKeyUseCase {
    constructor(mutator, items, operatorManager, rootKeyManager) {
        this.mutator = mutator;
        this.items = items;
        this.operatorManager = operatorManager;
        this.rootKeyManager = rootKeyManager;
    }
    async execute() {
        const rootKey = this.rootKeyManager.getSureRootKey();
        const operatorVersion = rootKey ? rootKey.keyVersion : common_1.ProtocolVersionLatest;
        let itemTemplate;
        if ((0, common_1.compareVersions)(operatorVersion, common_1.ProtocolVersionLastNonrootItemsKey) <= 0) {
            /** Create root key based items key */
            const payload = new models_1.DecryptedPayload({
                uuid: utils_1.UuidGenerator.GenerateUuid(),
                content_type: domain_core_1.ContentType.TYPES.ItemsKey,
                content: (0, models_1.FillItemContentSpecialized)({
                    itemsKey: rootKey.masterKey,
                    dataAuthenticationKey: rootKey.dataAuthenticationKey,
                    version: operatorVersion,
                }),
                ...(0, models_1.PayloadTimestampDefaults)(),
            });
            itemTemplate = (0, models_1.CreateDecryptedItemFromPayload)(payload);
        }
        else {
            /** Create independent items key */
            itemTemplate = this.operatorManager.operatorForVersion(operatorVersion).createItemsKey();
        }
        const itemsKeys = this.items.getDisplayableItemsKeys();
        const defaultKeys = itemsKeys.filter((key) => {
            return key.isDefault;
        });
        for (const key of defaultKeys) {
            await this.mutator.changeItemsKey(key, (mutator) => {
                mutator.isDefault = false;
            });
        }
        const itemsKey = await this.mutator.insertItem(itemTemplate);
        await this.mutator.changeItemsKey(itemsKey, (mutator) => {
            mutator.isDefault = true;
        });
        return itemsKey;
    }
}
exports.CreateNewDefaultItemsKeyUseCase = CreateNewDefaultItemsKeyUseCase;
