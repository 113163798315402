"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorMessage = void 0;
var ErrorMessage;
(function (ErrorMessage) {
    ErrorMessage["RegistrationInProgress"] = "An existing registration request is already in progress.";
    ErrorMessage["GenericRegistrationFail"] = "A server error occurred while trying to register. Please try again.";
    ErrorMessage["RateLimited"] = "Too many successive server requests. Please wait a few minutes and try again.";
    ErrorMessage["InsufficientPasswordMessage"] = "Your password must be at least %LENGTH% characters in length. For your security, please choose a longer password or, ideally, a passphrase, and try again.";
    ErrorMessage["PasscodeRequired"] = "Your passcode is required in order to register for an account.";
    ErrorMessage["GenericInProgress"] = "An existing request is already in progress.";
    ErrorMessage["GenericFail"] = "A server error occurred. Please try again.";
})(ErrorMessage = exports.ErrorMessage || (exports.ErrorMessage = {}));
