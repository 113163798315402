"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultListing = void 0;
const Item_1 = require("../../Abstract/Item");
class VaultListing extends Item_1.DecryptedItem {
    constructor(payload) {
        super(payload);
        this.systemIdentifier = payload.content.systemIdentifier;
        this.rootKeyParams = payload.content.rootKeyParams;
        this.keyStorageMode = payload.content.keyStorageMode;
        this.name = payload.content.name;
        this.description = payload.content.description;
        this.sharing = payload.content.sharing;
    }
    strategyWhenConflictingWithItem(item, _previousRevision) {
        const baseKeyTimestamp = this.rootKeyParams.creationTimestamp;
        const incomingKeyTimestamp = item.rootKeyParams.creationTimestamp;
        return incomingKeyTimestamp > baseKeyTimestamp ? Item_1.ConflictStrategy.KeepApply : Item_1.ConflictStrategy.KeepBase;
    }
    get keyPasswordType() {
        return this.rootKeyParams.passwordType;
    }
    isSharedVaultListing() {
        return this.sharing != undefined;
    }
    get key_system_identifier() {
        return undefined;
    }
    get shared_vault_uuid() {
        return undefined;
    }
}
exports.VaultListing = VaultListing;
