"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionApiOperations = void 0;
var SubscriptionApiOperations;
(function (SubscriptionApiOperations) {
    SubscriptionApiOperations[SubscriptionApiOperations["Inviting"] = 0] = "Inviting";
    SubscriptionApiOperations[SubscriptionApiOperations["CancelingInvite"] = 1] = "CancelingInvite";
    SubscriptionApiOperations[SubscriptionApiOperations["ListingInvites"] = 2] = "ListingInvites";
    SubscriptionApiOperations[SubscriptionApiOperations["AcceptingInvite"] = 3] = "AcceptingInvite";
    SubscriptionApiOperations[SubscriptionApiOperations["ConfirmAppleIAP"] = 4] = "ConfirmAppleIAP";
    SubscriptionApiOperations[SubscriptionApiOperations["GetSubscription"] = 5] = "GetSubscription";
    SubscriptionApiOperations[SubscriptionApiOperations["GetAvailableSubscriptions"] = 6] = "GetAvailableSubscriptions";
})(SubscriptionApiOperations = exports.SubscriptionApiOperations || (exports.SubscriptionApiOperations = {}));
