"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveVaultMemberUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
class RemoveVaultMemberUseCase {
    constructor(vaultUserServer) {
        this.vaultUserServer = vaultUserServer;
    }
    async execute(params) {
        const response = await this.vaultUserServer.deleteSharedVaultUser({
            sharedVaultUuid: params.sharedVaultUuid,
            userUuid: params.userUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromNetworkError(response);
        }
    }
}
exports.RemoveVaultMemberUseCase = RemoveVaultMemberUseCase;
