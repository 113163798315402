"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionManager = void 0;
const StorageKeys_1 = require("./../Storage/StorageKeys");
const ApplicationStage_1 = require("./../Application/ApplicationStage");
const utils_1 = require("@standardnotes/utils");
const ApplicationEvent_1 = require("./../Event/ApplicationEvent");
const AbstractService_1 = require("../Service/AbstractService");
const responses_1 = require("@standardnotes/responses");
const SubscriptionManagerEvent_1 = require("./SubscriptionManagerEvent");
class SubscriptionManager extends AbstractService_1.AbstractService {
    constructor(subscriptionApiService, sessions, storage, internalEventBus) {
        super(internalEventBus);
        this.subscriptionApiService = subscriptionApiService;
        this.sessions = sessions;
        this.storage = storage;
        this.internalEventBus = internalEventBus;
        internalEventBus.addEventHandler(this, ApplicationEvent_1.ApplicationEvent.UserRolesChanged);
        internalEventBus.addEventHandler(this, ApplicationEvent_1.ApplicationEvent.Launched);
        internalEventBus.addEventHandler(this, ApplicationEvent_1.ApplicationEvent.SignedIn);
    }
    async handleEvent(event) {
        switch (event.type) {
            case ApplicationEvent_1.ApplicationEvent.Launched: {
                void this.fetchOnlineSubscription();
                void this.fetchAvailableSubscriptions();
                break;
            }
            case ApplicationEvent_1.ApplicationEvent.UserRolesChanged:
            case ApplicationEvent_1.ApplicationEvent.SignedIn:
                void this.fetchOnlineSubscription();
                break;
        }
    }
    async handleApplicationStage(stage) {
        if (stage === ApplicationStage_1.ApplicationStage.StorageDecrypted_09) {
            this.onlineSubscription = this.storage.getValue(StorageKeys_1.StorageKey.Subscription);
            void this.notifyEvent(SubscriptionManagerEvent_1.SubscriptionManagerEvent.DidFetchSubscription);
        }
    }
    hasOnlineSubscription() {
        return this.onlineSubscription != undefined;
    }
    getOnlineSubscription() {
        return this.onlineSubscription;
    }
    getAvailableSubscriptions() {
        return this.availableSubscriptions;
    }
    get userSubscriptionName() {
        if (!this.onlineSubscription) {
            throw new Error('Attempting to get subscription name without a subscription.');
        }
        if (this.availableSubscriptions &&
            this.availableSubscriptions[this.onlineSubscription.planName]) {
            return this.availableSubscriptions[this.onlineSubscription.planName].name;
        }
        return '';
    }
    get userSubscriptionExpirationDate() {
        if (!this.onlineSubscription) {
            return undefined;
        }
        return new Date((0, utils_1.convertTimestampToMilliseconds)(this.onlineSubscription.endsAt));
    }
    get isUserSubscriptionExpired() {
        if (!this.onlineSubscription) {
            throw new Error('Attempting to check subscription expiration without a subscription.');
        }
        if (!this.userSubscriptionExpirationDate) {
            return false;
        }
        return this.userSubscriptionExpirationDate.getTime() < new Date().getTime();
    }
    get isUserSubscriptionCanceled() {
        if (!this.onlineSubscription) {
            throw new Error('Attempting to check subscription expiration without a subscription.');
        }
        return this.onlineSubscription.cancelled;
    }
    async acceptInvitation(inviteUuid) {
        try {
            const result = await this.subscriptionApiService.acceptInvite(inviteUuid);
            if ((0, responses_1.isErrorResponse)(result)) {
                return { success: false, message: (0, responses_1.getErrorFromErrorResponse)(result).message };
            }
            return result.data;
        }
        catch (error) {
            return { success: false, message: 'Could not accept invitation.' };
        }
    }
    async listSubscriptionInvitations() {
        var _a;
        try {
            const result = await this.subscriptionApiService.listInvites();
            if ((0, responses_1.isErrorResponse)(result)) {
                return [];
            }
            return (_a = result.data.invitations) !== null && _a !== void 0 ? _a : [];
        }
        catch (error) {
            return [];
        }
    }
    async inviteToSubscription(inviteeEmail) {
        try {
            const result = await this.subscriptionApiService.invite(inviteeEmail);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.success === true;
        }
        catch (error) {
            return false;
        }
    }
    async cancelInvitation(inviteUuid) {
        try {
            const result = await this.subscriptionApiService.cancelInvite(inviteUuid);
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.success === true;
        }
        catch (error) {
            return false;
        }
    }
    async fetchOnlineSubscription() {
        if (!this.sessions.isSignedIn()) {
            return;
        }
        try {
            const result = await this.subscriptionApiService.getUserSubscription({ userUuid: this.sessions.userUuid });
            if ((0, responses_1.isErrorResponse)(result)) {
                return;
            }
            const subscription = result.data.subscription;
            this.handleReceivedOnlineSubscriptionFromServer(subscription);
        }
        catch (error) {
            void error;
        }
    }
    handleReceivedOnlineSubscriptionFromServer(subscription) {
        this.onlineSubscription = subscription;
        this.storage.setValue(StorageKeys_1.StorageKey.Subscription, subscription);
        void this.notifyEvent(SubscriptionManagerEvent_1.SubscriptionManagerEvent.DidFetchSubscription);
    }
    async fetchAvailableSubscriptions() {
        try {
            const response = await this.subscriptionApiService.getAvailableSubscriptions();
            if ((0, responses_1.isErrorResponse)(response)) {
                return;
            }
            this.availableSubscriptions = response.data;
        }
        catch (error) {
            void error;
        }
    }
    async confirmAppleIAP(params, subscriptionToken) {
        try {
            const result = await this.subscriptionApiService.confirmAppleIAP({
                ...params,
                subscription_token: subscriptionToken,
            });
            if ((0, responses_1.isErrorResponse)(result)) {
                return { success: false, message: (0, responses_1.getErrorFromErrorResponse)(result).message };
            }
            return result.data;
        }
        catch (error) {
            return { success: false, message: 'Could not confirm IAP.' };
        }
    }
}
exports.SubscriptionManager = SubscriptionManager;
