"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharedVaultInvitesServer = void 0;
const Paths_1 = require("./Paths");
class SharedVaultInvitesServer {
    constructor(httpService) {
        this.httpService = httpService;
    }
    createInvite(params) {
        return this.httpService.post(Paths_1.SharedVaultInvitesPaths.createInvite(params.sharedVaultUuid), {
            recipient_uuid: params.recipientUuid,
            encrypted_message: params.encryptedMessage,
            permissions: params.permissions,
        });
    }
    updateInvite(params) {
        return this.httpService.patch(Paths_1.SharedVaultInvitesPaths.updateInvite(params.sharedVaultUuid, params.inviteUuid), {
            encrypted_message: params.encryptedMessage,
            permissions: params.permissions,
        });
    }
    acceptInvite(params) {
        return this.httpService.post(Paths_1.SharedVaultInvitesPaths.acceptInvite(params.sharedVaultUuid, params.inviteUuid));
    }
    declineInvite(params) {
        return this.httpService.post(Paths_1.SharedVaultInvitesPaths.declineInvite(params.sharedVaultUuid, params.inviteUuid));
    }
    getInboundUserInvites() {
        return this.httpService.get(Paths_1.SharedVaultInvitesPaths.getInboundUserInvites());
    }
    getOutboundUserInvites() {
        return this.httpService.get(Paths_1.SharedVaultInvitesPaths.getOutboundUserInvites());
    }
    getSharedVaultInvites(params) {
        return this.httpService.get(Paths_1.SharedVaultInvitesPaths.getSharedVaultInvites(params.sharedVaultUuid));
    }
    deleteInvite(params) {
        return this.httpService.delete(Paths_1.SharedVaultInvitesPaths.deleteInvite(params.sharedVaultUuid, params.inviteUuid));
    }
    deleteAllSharedVaultInvites(params) {
        return this.httpService.delete(Paths_1.SharedVaultInvitesPaths.deleteAllSharedVaultInvites(params.sharedVaultUuid));
    }
    deleteAllInboundInvites() {
        return this.httpService.delete(Paths_1.SharedVaultInvitesPaths.deleteAllInboundInvites);
    }
}
exports.SharedVaultInvitesServer = SharedVaultInvitesServer;
