"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOutboundAsymmetricMessages = void 0;
const responses_1 = require("@standardnotes/responses");
class GetOutboundAsymmetricMessages {
    constructor(messageServer) {
        this.messageServer = messageServer;
    }
    async execute() {
        const response = await this.messageServer.getOutboundUserMessages();
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromNetworkError(response);
        }
        return response.data.messages;
    }
}
exports.GetOutboundAsymmetricMessages = GetOutboundAsymmetricMessages;
