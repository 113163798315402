"use strict";
/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncSource = void 0;
var SyncSource;
(function (SyncSource) {
    SyncSource["External"] = "External";
    SyncSource["SpawnQueue"] = "SpawnQueue";
    SyncSource["ResolveQueue"] = "ResolveQueue";
    SyncSource["MoreDirtyItems"] = "MoreDirtyItems";
    SyncSource["DownloadFirst"] = "DownloadFirst";
    SyncSource["AfterDownloadFirst"] = "AfterDownloadFirst";
    SyncSource["IntegrityCheck"] = "IntegrityCheck";
    SyncSource["ResolveOutOfSync"] = "ResolveOutOfSync";
})(SyncSource = exports.SyncSource || (exports.SyncSource = {}));
