"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeServerService = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const ApplicationStage_1 = require("../Application/ApplicationStage");
const AbstractService_1 = require("../Service/AbstractService");
const StorageKeys_1 = require("../Storage/StorageKeys");
class HomeServerService extends AbstractService_1.AbstractService {
    constructor(desktopDevice, internalEventBus) {
        super(internalEventBus);
        this.desktopDevice = desktopDevice;
        this.internalEventBus = internalEventBus;
        this.HOME_SERVER_DATA_DIRECTORY_NAME = '.homeserver';
    }
    deinit() {
        ;
        this.desktopDevice = undefined;
        super.deinit();
    }
    async handleApplicationStage(stage) {
        await super.handleApplicationStage(stage);
        switch (stage) {
            case ApplicationStage_1.ApplicationStage.StorageDecrypted_09: {
                await this.setHomeServerDataLocationOnDevice();
                break;
            }
            case ApplicationStage_1.ApplicationStage.Launched_10: {
                await this.startHomeServerIfItIsEnabled();
                break;
            }
        }
    }
    async getHomeServerStatus() {
        const isHomeServerRunning = await this.desktopDevice.isHomeServerRunning();
        if (!isHomeServerRunning) {
            return { status: 'off', errorMessage: await this.desktopDevice.getHomeServerLastErrorMessage() };
        }
        return {
            status: 'on',
            url: await this.getHomeServerUrl(),
        };
    }
    async getHomeServerLogs() {
        return this.desktopDevice.getHomeServerLogs();
    }
    async getHomeServerUrl() {
        return this.desktopDevice.getHomeServerUrl();
    }
    async startHomeServer() {
        return this.desktopDevice.startHomeServer();
    }
    async stopHomeServer() {
        return this.desktopDevice.stopHomeServer();
    }
    async isHomeServerRunning() {
        return this.desktopDevice.isHomeServerRunning();
    }
    async activatePremiumFeatures(username) {
        const result = await this.desktopDevice.activatePremiumFeatures(username);
        if (result !== undefined) {
            return domain_core_1.Result.fail(result);
        }
        return domain_core_1.Result.ok('Premium features activated');
    }
    async setHomeServerConfiguration(config) {
        await this.desktopDevice.setHomeServerConfiguration(JSON.stringify(config));
    }
    async getHomeServerConfiguration() {
        const configurationJSONString = await this.desktopDevice.getHomeServerConfiguration();
        if (!configurationJSONString) {
            return undefined;
        }
        return JSON.parse(configurationJSONString);
    }
    async enableHomeServer() {
        await this.desktopDevice.setRawStorageValue(StorageKeys_1.RawStorageKey.HomeServerEnabled, 'true');
        await this.startHomeServer();
    }
    async isHomeServerEnabled() {
        const value = await this.desktopDevice.getRawStorageValue(StorageKeys_1.RawStorageKey.HomeServerEnabled);
        return value === 'true';
    }
    async getHomeServerDataLocation() {
        return this.desktopDevice.getRawStorageValue(StorageKeys_1.RawStorageKey.HomeServerDataLocation);
    }
    async disableHomeServer() {
        await this.desktopDevice.setRawStorageValue(StorageKeys_1.RawStorageKey.HomeServerEnabled, 'false');
        const result = await this.stopHomeServer();
        if (result !== undefined) {
            return domain_core_1.Result.fail(result);
        }
        return domain_core_1.Result.ok('Home server disabled');
    }
    async changeHomeServerDataLocation() {
        const oldLocation = await this.getHomeServerDataLocation();
        const newLocation = await this.desktopDevice.presentDirectoryPickerForLocationChangeAndTransferOld(this.HOME_SERVER_DATA_DIRECTORY_NAME, oldLocation);
        if (!newLocation) {
            const lastErrorMessage = await this.desktopDevice.getDirectoryManagerLastErrorMessage();
            return domain_core_1.Result.fail(lastErrorMessage !== null && lastErrorMessage !== void 0 ? lastErrorMessage : 'No location selected');
        }
        await this.desktopDevice.setRawStorageValue(StorageKeys_1.RawStorageKey.HomeServerDataLocation, newLocation);
        await this.desktopDevice.setHomeServerDataLocation(newLocation);
        return domain_core_1.Result.ok(newLocation);
    }
    async openHomeServerDataLocation() {
        const location = await this.getHomeServerDataLocation();
        if (location) {
            void this.desktopDevice.openLocation(location);
        }
    }
    async startHomeServerIfItIsEnabled() {
        const homeServerIsEnabled = await this.isHomeServerEnabled();
        if (homeServerIsEnabled) {
            await this.startHomeServer();
        }
    }
    async setHomeServerDataLocationOnDevice() {
        let location = await this.getHomeServerDataLocation();
        if (!location) {
            const documentsDirectory = await this.desktopDevice.getUserDocumentsDirectory();
            location = `${documentsDirectory}/${this.HOME_SERVER_DATA_DIRECTORY_NAME}`;
        }
        await this.desktopDevice.setRawStorageValue(StorageKeys_1.RawStorageKey.HomeServerDataLocation, location);
        await this.desktopDevice.setHomeServerDataLocation(location);
    }
}
exports.HomeServerService = HomeServerService;
