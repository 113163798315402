"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GoogleKeepConverter = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const Utils_1 = require("../Utils");
class GoogleKeepConverter {
    constructor(application) {
        this.application = application;
    }
    async convertGoogleKeepBackupFileToNote(file, stripHtml) {
        const content = await (0, Utils_1.readFileAsText)(file);
        const possiblePayloadFromJson = this.tryParseAsJson(content);
        if (possiblePayloadFromJson) {
            return possiblePayloadFromJson;
        }
        const possiblePayloadFromHtml = this.tryParseAsHtml(content, file, stripHtml);
        if (possiblePayloadFromHtml) {
            return possiblePayloadFromHtml;
        }
        throw new Error('Could not parse Google Keep backup file');
    }
    tryParseAsHtml(data, file, stripHtml) {
        var _a;
        const rootElement = document.createElement('html');
        rootElement.innerHTML = data;
        const contentElement = rootElement.getElementsByClassName('content')[0];
        let content;
        // Replace <br> with \n so line breaks get recognised
        contentElement.innerHTML = contentElement.innerHTML.replace(/<br>/g, '\n');
        if (stripHtml) {
            content = contentElement.textContent;
        }
        else {
            content = contentElement.innerHTML;
        }
        if (!content) {
            throw new Error('Could not parse content');
        }
        const title = ((_a = rootElement.getElementsByClassName('title')[0]) === null || _a === void 0 ? void 0 : _a.textContent) || file.name;
        const date = this.getDateFromGKeepNote(data) || new Date();
        return {
            created_at: date,
            created_at_timestamp: date.getTime(),
            updated_at: date,
            updated_at_timestamp: date.getTime(),
            uuid: this.application.generateUUID(),
            content_type: domain_core_1.ContentType.TYPES.Note,
            content: {
                title: title,
                text: content,
                references: [],
            },
        };
    }
    getDateFromGKeepNote(note) {
        var _a, _b;
        const regexWithTitle = /.*(?=<\/div>\n<div class="title">)/;
        const regexWithoutTitle = /.*(?=<\/div>\n\n<div class="content">)/;
        const possibleDateStringWithTitle = (_a = regexWithTitle.exec(note)) === null || _a === void 0 ? void 0 : _a[0];
        const possibleDateStringWithoutTitle = (_b = regexWithoutTitle.exec(note)) === null || _b === void 0 ? void 0 : _b[0];
        if (possibleDateStringWithTitle) {
            const date = new Date(possibleDateStringWithTitle);
            if (date.toString() !== 'Invalid Date' && date.toString() !== 'NaN') {
                return date;
            }
        }
        if (possibleDateStringWithoutTitle) {
            const date = new Date(possibleDateStringWithoutTitle);
            if (date.toString() !== 'Invalid Date' && date.toString() !== 'NaN') {
                return date;
            }
        }
        return;
    }
    static isValidGoogleKeepJson(json) {
        return (typeof json.title === 'string' &&
            typeof json.textContent === 'string' &&
            typeof json.userEditedTimestampUsec === 'number' &&
            typeof json.isArchived === 'boolean' &&
            typeof json.isTrashed === 'boolean' &&
            typeof json.isPinned === 'boolean' &&
            typeof json.color === 'string');
    }
    tryParseAsJson(data) {
        try {
            const parsed = JSON.parse(data);
            if (!GoogleKeepConverter.isValidGoogleKeepJson(parsed)) {
                return null;
            }
            const date = new Date(parsed.userEditedTimestampUsec / 1000);
            return {
                created_at: date,
                created_at_timestamp: date.getTime(),
                updated_at: date,
                updated_at_timestamp: date.getTime(),
                uuid: this.application.generateUUID(),
                content_type: domain_core_1.ContentType.TYPES.Note,
                content: {
                    title: parsed.title,
                    text: parsed.textContent,
                    references: [],
                    archived: Boolean(parsed.isArchived),
                    trashed: Boolean(parsed.isTrashed),
                    pinned: Boolean(parsed.isPinned),
                },
            };
        }
        catch (e) {
            return null;
        }
    }
}
exports.GoogleKeepConverter = GoogleKeepConverter;
