"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemoveItemsLocallyUseCase = void 0;
const utils_1 = require("@standardnotes/utils");
class RemoveItemsLocallyUseCase {
    constructor(items, storage) {
        this.items = items;
        this.storage = storage;
    }
    async execute(items) {
        this.items.removeItemsLocally(items);
        await this.storage.deletePayloadsWithUuids((0, utils_1.Uuids)(items));
    }
}
exports.RemoveItemsLocallyUseCase = RemoveItemsLocallyUseCase;
