"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateKeySystemItemsKeyUseCase = void 0;
const models_1 = require("@standardnotes/models");
const Algorithm_1 = require("../../../../Algorithm");
const common_1 = require("@standardnotes/common");
const domain_core_1 = require("@standardnotes/domain-core");
class CreateKeySystemItemsKeyUseCase {
    constructor(crypto) {
        this.crypto = crypto;
    }
    execute(dto) {
        const key = this.crypto.generateRandomKey(Algorithm_1.V004Algorithm.EncryptionKeyLength);
        const content = (0, models_1.FillItemContentSpecialized)({
            itemsKey: key,
            creationTimestamp: new Date().getTime(),
            version: common_1.ProtocolVersion.V004,
            rootKeyToken: dto.rootKeyToken,
        });
        const transferPayload = {
            uuid: dto.uuid,
            content_type: domain_core_1.ContentType.TYPES.KeySystemItemsKey,
            key_system_identifier: dto.keySystemIdentifier,
            shared_vault_uuid: dto.sharedVaultUuid,
            content: content,
            dirty: true,
            ...(0, models_1.PayloadTimestampDefaults)(),
        };
        const payload = new models_1.DecryptedPayload(transferPayload);
        return (0, models_1.CreateDecryptedItemFromPayload)(payload);
    }
}
exports.CreateKeySystemItemsKeyUseCase = CreateKeySystemItemsKeyUseCase;
