"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelfContactManager = void 0;
const InternalFeature_1 = require("./../../InternalFeatures/InternalFeature");
const InternalFeatureService_1 = require("../../InternalFeatures/InternalFeatureService");
const ApplicationStage_1 = require("./../../Application/ApplicationStage");
const SyncEvent_1 = require("./../../Event/SyncEvent");
const models_1 = require("@standardnotes/models");
const CreateOrEditTrustedContact_1 = require("../UseCase/CreateOrEditTrustedContact");
const domain_core_1 = require("@standardnotes/domain-core");
class SelfContactManager {
    constructor(sync, items, mutator, session, singletons) {
        this.sync = sync;
        this.items = items;
        this.mutator = mutator;
        this.session = session;
        this.singletons = singletons;
        this.isReloadingSelfContact = false;
        this.eventDisposers = [];
        this.eventDisposers.push(sync.addEventObserver((event) => {
            if (event === SyncEvent_1.SyncEvent.LocalDataIncrementalLoad) {
                this.loadSelfContactFromDatabase();
            }
            if (event === SyncEvent_1.SyncEvent.SyncCompletedWithAllItemsUploaded) {
                void this.reloadSelfContactAndCreateIfNecessary();
            }
        }));
    }
    async handleApplicationStage(stage) {
        if (stage === ApplicationStage_1.ApplicationStage.LoadedDatabase_12) {
            this.loadSelfContactFromDatabase();
        }
    }
    loadSelfContactFromDatabase() {
        if (this.selfContact) {
            return;
        }
        this.selfContact = this.singletons.findSingleton(domain_core_1.ContentType.TYPES.TrustedContact, models_1.TrustedContact.singletonPredicate);
    }
    async updateWithNewPublicKeySet(publicKeySet) {
        if (!InternalFeatureService_1.InternalFeatureService.get().isFeatureEnabled(InternalFeature_1.InternalFeature.Vaults)) {
            return;
        }
        if (!this.selfContact) {
            return;
        }
        const usecase = new CreateOrEditTrustedContact_1.CreateOrEditTrustedContactUseCase(this.items, this.mutator, this.sync);
        await usecase.execute({
            name: 'Me',
            contactUuid: this.selfContact.contactUuid,
            publicKey: publicKeySet.encryption,
            signingPublicKey: publicKeySet.signing,
        });
    }
    async reloadSelfContactAndCreateIfNecessary() {
        if (!InternalFeatureService_1.InternalFeatureService.get().isFeatureEnabled(InternalFeature_1.InternalFeature.Vaults)) {
            return;
        }
        if (this.selfContact) {
            return;
        }
        if (this.isReloadingSelfContact) {
            return;
        }
        if (!this.session.isSignedIn()) {
            return;
        }
        if (this.session.isUserMissingKeyPair()) {
            return;
        }
        this.isReloadingSelfContact = true;
        const content = {
            name: 'Me',
            isMe: true,
            contactUuid: this.session.getSureUser().uuid,
            publicKeySet: models_1.ContactPublicKeySet.FromJson({
                encryption: this.session.getPublicKey(),
                signing: this.session.getSigningPublicKey(),
                isRevoked: false,
                timestamp: new Date(),
            }),
        };
        this.selfContact = await this.singletons.findOrCreateSingleton(models_1.TrustedContact.singletonPredicate, domain_core_1.ContentType.TYPES.TrustedContact, (0, models_1.FillItemContent)(content));
        this.isReloadingSelfContact = false;
    }
    deinit() {
        this.eventDisposers.forEach((disposer) => disposer());
        this.sync = undefined;
        this.items = undefined;
        this.mutator = undefined;
        this.session = undefined;
        this.singletons = undefined;
    }
}
exports.SelfContactManager = SelfContactManager;
