"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShareContactWithAllMembersOfSharedVaultUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
const SendAsymmetricMessageUseCase_1 = require("../../AsymmetricMessage/UseCase/SendAsymmetricMessageUseCase");
class ShareContactWithAllMembersOfSharedVaultUseCase {
    constructor(contacts, encryption, sharedVaultUsersServer, messageServer) {
        this.contacts = contacts;
        this.encryption = encryption;
        this.sharedVaultUsersServer = sharedVaultUsersServer;
        this.messageServer = messageServer;
    }
    async execute(params) {
        if (params.sharedVault.sharing.ownerUserUuid !== params.senderUserUuid) {
            return responses_1.ClientDisplayableError.FromString('Cannot share contact; user is not the owner of the shared vault');
        }
        const usersResponse = await this.sharedVaultUsersServer.getSharedVaultUsers({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
        });
        if ((0, responses_1.isErrorResponse)(usersResponse)) {
            return responses_1.ClientDisplayableError.FromString('Cannot share contact; shared vault users not found');
        }
        const users = usersResponse.data.users;
        if (users.length === 0) {
            return;
        }
        const messageSendUseCase = new SendAsymmetricMessageUseCase_1.SendAsymmetricMessageUseCase(this.messageServer);
        for (const vaultUser of users) {
            if (vaultUser.user_uuid === params.senderUserUuid) {
                continue;
            }
            if (vaultUser.user_uuid === params.contactToShare.contactUuid) {
                continue;
            }
            const vaultUserAsContact = this.contacts.findTrustedContact(vaultUser.user_uuid);
            if (!vaultUserAsContact) {
                continue;
            }
            const encryptedMessage = this.encryption.asymmetricallyEncryptMessage({
                message: {
                    type: models_1.AsymmetricMessagePayloadType.ContactShare,
                    data: { recipientUuid: vaultUserAsContact.contactUuid, trustedContact: params.contactToShare.content },
                },
                senderKeyPair: params.senderKeyPair,
                senderSigningKeyPair: params.senderSigningKeyPair,
                recipientPublicKey: vaultUserAsContact.publicKeySet.encryption,
            });
            await messageSendUseCase.execute({
                recipientUuid: vaultUserAsContact.contactUuid,
                encryptedMessage,
                replaceabilityIdentifier: undefined,
            });
        }
    }
}
exports.ShareContactWithAllMembersOfSharedVaultUseCase = ShareContactWithAllMembersOfSharedVaultUseCase;
