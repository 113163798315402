"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteViewController = void 0;
const features_1 = require("@standardnotes/features");
const models_1 = require("@standardnotes/models");
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
const Logging_1 = require("@/Logging");
const NoteSyncController_1 = require("../../../Controllers/NoteSyncController");
class NoteViewController {
    constructor(application, item, templateNoteOptions) {
        this.application = application;
        this.templateNoteOptions = templateNoteOptions;
        this.dealloced = false;
        this.isTemplateNote = false;
        this.runtimeId = `${Math.random()}`;
        this.needsInit = true;
        this.innerValueChangeObservers = [];
        this.disposers = [];
        if (item) {
            this.item = item;
        }
        if (templateNoteOptions) {
            this.defaultTagUuid = templateNoteOptions.tag;
        }
        if (this.defaultTagUuid) {
            this.defaultTag = this.application.items.findItem(this.defaultTagUuid);
        }
        this.syncController = new NoteSyncController_1.NoteSyncController(this.application, this.item);
    }
    deinit() {
        if (!this.syncController.savingLocallyPromise) {
            this.performDeinitSafely();
            return;
        }
        void this.syncController.savingLocallyPromise.promise.then(() => {
            this.performDeinitSafely();
        });
    }
    performDeinitSafely() {
        this.dealloced = true;
        for (const disposer of this.disposers) {
            disposer();
        }
        this.disposers.length = 0;
        this.application = undefined;
        this.item = undefined;
        this.innerValueChangeObservers.length = 0;
    }
    async initialize() {
        var _a, _b, _c;
        if (!this.needsInit) {
            throw Error('NoteViewController already initialized');
        }
        (0, Logging_1.log)(Logging_1.LoggingDomain.NoteView, 'Initializing NoteViewController');
        this.needsInit = false;
        const addTagHierarchy = this.application.getPreference(models_1.PrefKey.NoteAddToParentFolders, true);
        if (!this.item) {
            (0, Logging_1.log)(Logging_1.LoggingDomain.NoteView, 'Initializing as template note');
            const editorIdentifier = this.application.componentManager.getDefaultEditorIdentifier(this.defaultTag);
            const noteType = (0, features_1.noteTypeForEditorIdentifier)(editorIdentifier);
            const note = this.application.items.createTemplateItem(domain_core_1.ContentType.TYPES.Note, {
                text: '',
                title: ((_a = this.templateNoteOptions) === null || _a === void 0 ? void 0 : _a.title) || '',
                noteType: noteType,
                editorIdentifier: editorIdentifier,
                references: [],
            }, {
                created_at: ((_b = this.templateNoteOptions) === null || _b === void 0 ? void 0 : _b.createdAt) || new Date(),
                ...(0, models_1.PayloadVaultOverrides)((_c = this.templateNoteOptions) === null || _c === void 0 ? void 0 : _c.vault),
            });
            this.isTemplateNote = true;
            this.item = note;
            this.syncController.setItem(this.item);
            if (this.defaultTagUuid) {
                const tag = this.application.items.findItem(this.defaultTagUuid);
                await this.application.mutator.addTagToNote(note, tag, addTagHierarchy);
            }
            this.notifyObservers(this.item, models_1.PayloadEmitSource.InitialObserverRegistrationPush);
        }
        this.streamItems();
    }
    notifyObservers(note, source) {
        for (const observer of this.innerValueChangeObservers) {
            observer(note, source);
        }
    }
    streamItems() {
        if (this.dealloced) {
            return;
        }
        this.disposers.push(this.application.streamItems(domain_core_1.ContentType.TYPES.Note, ({ changed, inserted, source }) => {
            if (this.dealloced) {
                return;
            }
            const notes = changed.concat(inserted);
            const matchingNote = notes.find((item) => {
                return item.uuid === this.item.uuid;
            });
            if (matchingNote) {
                this.isTemplateNote = false;
                this.item = matchingNote;
                this.notifyObservers(matchingNote, source);
            }
        }));
    }
    insertTemplatedNote() {
        (0, Logging_1.log)(Logging_1.LoggingDomain.NoteView, 'Inserting template note');
        this.isTemplateNote = false;
        return this.application.mutator.insertItem(this.item);
    }
    /**
     * Register to be notified when the controller's note's inner values change
     * (and thus a new object reference is created)
     */
    addNoteInnerValueChangeObserver(callback) {
        this.innerValueChangeObservers.push(callback);
        if (this.item) {
            callback(this.item, models_1.PayloadEmitSource.InitialObserverRegistrationPush);
        }
        const thislessChangeObservers = this.innerValueChangeObservers;
        return () => {
            (0, utils_1.removeFromArray)(thislessChangeObservers, callback);
        };
    }
    async saveAndAwaitLocalPropagation(params) {
        if (this.needsInit) {
            throw Error('NoteViewController not initialized');
        }
        (0, Logging_1.log)(Logging_1.LoggingDomain.NoteView, 'Saving note', params);
        const isTemplate = this.isTemplateNote;
        if (isTemplate) {
            await this.insertTemplatedNote();
        }
        await this.syncController.saveAndAwaitLocalPropagation(params);
    }
}
exports.NoteViewController = NoteViewController;
