"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SodiumConstant = void 0;
var SodiumConstant;
(function (SodiumConstant) {
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_STATEBYTES"] = 52] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_STATEBYTES";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_ABYTES"] = 17] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_ABYTES";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_HEADERBYTES"] = 24] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_HEADERBYTES";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_KEYBYTES"] = 32] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_KEYBYTES";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PUSH"] = 0] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PUSH";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PULL"] = 1] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_PULL";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_REKEY"] = 2] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_REKEY";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_FINAL"] = 3] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_TAG_FINAL";
    SodiumConstant[SodiumConstant["CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_MESSAGEBYTES_MAX"] = 274877906816] = "CRYPTO_SECRETSTREAM_XCHACHA20POLY1305_MESSAGEBYTES_MAX";
    SodiumConstant[SodiumConstant["crypto_box_SEEDBYTES"] = 32] = "crypto_box_SEEDBYTES";
    SodiumConstant[SodiumConstant["crypto_sign_SEEDBYTES"] = 32] = "crypto_sign_SEEDBYTES";
    SodiumConstant[SodiumConstant["crypto_generichash_KEYBYTES"] = 32] = "crypto_generichash_KEYBYTES";
})(SodiumConstant = exports.SodiumConstant || (exports.SodiumConstant = {}));
