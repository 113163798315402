"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
const ReadonlyPlugin = ({ note }) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    const [readOnly, setReadOnly] = (0, react_1.useState)(note.locked);
    (0, react_1.useEffect)(() => {
        return application.streamItems(snjs_1.ContentType.TYPES.Note, ({ changed }) => {
            const changedNoteItem = changed.find((changedItem) => changedItem.uuid === note.uuid);
            if (changedNoteItem) {
                setReadOnly(changedNoteItem.locked);
            }
        });
    }, [application, note.uuid]);
    (0, react_1.useEffect)(() => {
        editor.update(() => {
            editor.setEditable(!readOnly);
        });
    }, [editor, readOnly]);
    return null;
};
exports.default = ReadonlyPlugin;
