"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SendOwnContactChangeMessage = void 0;
const models_1 = require("@standardnotes/models");
const SendAsymmetricMessageUseCase_1 = require("./SendAsymmetricMessageUseCase");
class SendOwnContactChangeMessage {
    constructor(encryption, messageServer) {
        this.encryption = encryption;
        this.messageServer = messageServer;
    }
    async execute(params) {
        const message = {
            type: models_1.AsymmetricMessagePayloadType.SenderKeypairChanged,
            data: {
                recipientUuid: params.contact.contactUuid,
                newEncryptionPublicKey: params.senderNewKeyPair.publicKey,
                newSigningPublicKey: params.senderNewSigningKeyPair.publicKey,
            },
        };
        const encryptedMessage = this.encryption.asymmetricallyEncryptMessage({
            message: message,
            senderKeyPair: params.senderOldKeyPair,
            senderSigningKeyPair: params.senderOldSigningKeyPair,
            recipientPublicKey: params.contact.publicKeySet.encryption,
        });
        const sendMessageUseCase = new SendAsymmetricMessageUseCase_1.SendAsymmetricMessageUseCase(this.messageServer);
        const sendMessageResult = await sendMessageUseCase.execute({
            recipientUuid: params.contact.contactUuid,
            encryptedMessage,
            replaceabilityIdentifier: undefined,
        });
        return sendMessageResult;
    }
}
exports.SendOwnContactChangeMessage = SendOwnContactChangeMessage;
