"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuperEditorMetadata = exports.SYNC_TIMEOUT_NO_DEBOUNCE = exports.SYNC_TIMEOUT_DEBOUNCE = exports.SMART_TAGS_FEATURE_NAME = exports.TAG_FOLDERS_FEATURE_TOOLTIP = exports.TAG_FOLDERS_FEATURE_NAME = exports.BYTES_IN_ONE_MEGABYTE = exports.BYTES_IN_ONE_KILOBYTE = exports.DAYS_IN_A_YEAR = exports.DAYS_IN_A_WEEK = exports.MILLISECONDS_IN_A_DAY = exports.NOTES_LIST_SCROLL_THRESHOLD = exports.FOCUSABLE_BUT_NOT_TABBABLE = exports.MAX_MENU_SIZE_MULTIPLIER = exports.MENU_MARGIN_FROM_APP_BORDER = exports.EMAIL_REGEX = exports.PANEL_NAME_NAVIGATION = exports.PANEL_NAME_NOTES = void 0;
exports.PANEL_NAME_NOTES = 'notes';
exports.PANEL_NAME_NAVIGATION = 'navigation';
exports.EMAIL_REGEX = /^([a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/;
exports.MENU_MARGIN_FROM_APP_BORDER = 5;
exports.MAX_MENU_SIZE_MULTIPLIER = 30;
exports.FOCUSABLE_BUT_NOT_TABBABLE = -1;
exports.NOTES_LIST_SCROLL_THRESHOLD = 200;
exports.MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;
exports.DAYS_IN_A_WEEK = 7;
exports.DAYS_IN_A_YEAR = 365;
exports.BYTES_IN_ONE_KILOBYTE = 1000;
exports.BYTES_IN_ONE_MEGABYTE = 1000000;
exports.TAG_FOLDERS_FEATURE_NAME = 'Tag folders';
exports.TAG_FOLDERS_FEATURE_TOOLTIP = 'A Plus or Pro plan is required to enable Tag folders.';
exports.SMART_TAGS_FEATURE_NAME = 'Smart Tags';
exports.SYNC_TIMEOUT_DEBOUNCE = 350;
exports.SYNC_TIMEOUT_NO_DEBOUNCE = 100;
exports.SuperEditorMetadata = {
    icon: 'file-doc',
    subtleIcon: 'format-align-left',
    iconClassName: 'text-accessory-tint-1',
    iconTintNumber: 1,
};
