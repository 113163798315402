"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConflictType = void 0;
var ConflictType;
(function (ConflictType) {
    ConflictType["ConflictingData"] = "sync_conflict";
    ConflictType["UuidConflict"] = "uuid_conflict";
    ConflictType["ContentTypeError"] = "content_type_error";
    ConflictType["ContentError"] = "content_error";
    ConflictType["ReadOnlyError"] = "readonly_error";
    ConflictType["UuidError"] = "uuid_error";
    ConflictType["SharedVaultSnjsVersionError"] = "shared_vault_snjs_version_error";
    ConflictType["SharedVaultInsufficientPermissionsError"] = "shared_vault_insufficient_permissions_error";
    ConflictType["SharedVaultNotMemberError"] = "shared_vault_not_member_error";
    ConflictType["SharedVaultInvalidState"] = "shared_vault_invalid_state";
})(ConflictType = exports.ConflictType || (exports.ConflictType = {}));
