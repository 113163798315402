"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteContactToSharedVaultUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
const SendSharedVaultInviteUseCase_1 = require("./SendSharedVaultInviteUseCase");
class InviteContactToSharedVaultUseCase {
    constructor(encryption, sharedVaultInviteServer) {
        this.encryption = encryption;
        this.sharedVaultInviteServer = sharedVaultInviteServer;
    }
    async execute(params) {
        const keySystemRootKey = this.encryption.keys.getPrimaryKeySystemRootKey(params.sharedVault.systemIdentifier);
        if (!keySystemRootKey) {
            return responses_1.ClientDisplayableError.FromString('Cannot add contact; key system root key not found');
        }
        const delegatedContacts = params.sharedVaultContacts.filter((contact) => !contact.isMe && contact.contactUuid !== params.recipient.contactUuid);
        const encryptedMessage = this.encryption.asymmetricallyEncryptMessage({
            message: {
                type: models_1.AsymmetricMessagePayloadType.SharedVaultInvite,
                data: {
                    recipientUuid: params.recipient.contactUuid,
                    rootKey: keySystemRootKey.content,
                    trustedContacts: delegatedContacts.map((contact) => contact.content),
                    metadata: {
                        name: params.sharedVault.name,
                        description: params.sharedVault.description,
                    },
                },
            },
            senderKeyPair: params.senderKeyPair,
            senderSigningKeyPair: params.senderSigningKeyPair,
            recipientPublicKey: params.recipient.publicKeySet.encryption,
        });
        const createInviteUseCase = new SendSharedVaultInviteUseCase_1.SendSharedVaultInviteUseCase(this.sharedVaultInviteServer);
        const createInviteResult = await createInviteUseCase.execute({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
            recipientUuid: params.recipient.contactUuid,
            encryptedMessage,
            permissions: params.permissions,
        });
        return createInviteResult;
    }
}
exports.InviteContactToSharedVaultUseCase = InviteContactToSharedVaultUseCase;
