"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserService = void 0;
const responses_1 = require("@standardnotes/responses");
const common_1 = require("@standardnotes/common");
const utils_1 = require("@standardnotes/utils");
const Messages = __importStar(require("../Strings/Messages"));
const InfoStrings_1 = require("../Strings/InfoStrings");
const Challenge_1 = require("../Challenge");
const AbstractService_1 = require("../Service/AbstractService");
const DeinitSource_1 = require("../Application/DeinitSource");
const StorageTypes_1 = require("../Storage/StorageTypes");
const AccountEvent_1 = require("./AccountEvent");
class UserService extends AbstractService_1.AbstractService {
    constructor(sessionManager, syncService, storageService, itemManager, encryptionService, alertService, challengeService, protectionService, userApiService, internalEventBus) {
        super(internalEventBus);
        this.sessionManager = sessionManager;
        this.syncService = syncService;
        this.storageService = storageService;
        this.itemManager = itemManager;
        this.encryptionService = encryptionService;
        this.alertService = alertService;
        this.challengeService = challengeService;
        this.protectionService = protectionService;
        this.userApiService = userApiService;
        this.internalEventBus = internalEventBus;
        this.signingIn = false;
        this.registering = false;
        this.MINIMUM_PASSCODE_LENGTH = 1;
        this.MINIMUM_PASSWORD_LENGTH = 8;
    }
    async handleEvent(event) {
        if (event.type === AccountEvent_1.AccountEvent.SignedInOrRegistered) {
            const payload = event.payload.payload;
            this.syncService.resetSyncState();
            await this.storageService.setPersistencePolicy(payload.ephemeral ? StorageTypes_1.StoragePersistencePolicies.Ephemeral : StorageTypes_1.StoragePersistencePolicies.Default);
            if (payload.mergeLocal) {
                await this.syncService.markAllItemsAsNeedingSyncAndPersist();
            }
            else {
                void this.itemManager.removeAllItemsFromMemory();
                await this.clearDatabase();
            }
            this.unlockSyncing();
            const syncPromise = this.syncService
                .downloadFirstSync(1000, {
                checkIntegrity: payload.checkIntegrity,
                awaitAll: payload.awaitSync,
            })
                .then(() => {
                if (!payload.awaitSync) {
                    void this.encryptionService.decryptErroredPayloads();
                }
            });
            if (payload.awaitSync) {
                await syncPromise;
                await this.encryptionService.decryptErroredPayloads();
            }
        }
    }
    deinit() {
        super.deinit();
        this.sessionManager = undefined;
        this.syncService = undefined;
        this.storageService = undefined;
        this.itemManager = undefined;
        this.encryptionService = undefined;
        this.alertService = undefined;
        this.challengeService = undefined;
        this.protectionService = undefined;
        this.userApiService = undefined;
    }
    getUserUuid() {
        return this.sessionManager.userUuid;
    }
    isSignedIn() {
        return this.sessionManager.isSignedIn();
    }
    /**
     *  @param mergeLocal  Whether to merge existing offline data into account. If false,
     *                     any pre-existing data will be fully deleted upon success.
     */
    async register(email, password, ephemeral = false, mergeLocal = true) {
        if (this.encryptionService.hasAccount()) {
            throw Error('Tried to register when an account already exists.');
        }
        if (this.registering) {
            throw Error('Already registering.');
        }
        this.registering = true;
        try {
            this.lockSyncing();
            const response = await this.sessionManager.register(email, password, ephemeral);
            await this.notifyEventSync(AccountEvent_1.AccountEvent.SignedInOrRegistered, {
                payload: {
                    ephemeral,
                    mergeLocal,
                    awaitSync: true,
                    checkIntegrity: false,
                },
            });
            this.registering = false;
            return response;
        }
        catch (error) {
            this.unlockSyncing();
            this.registering = false;
            throw error;
        }
    }
    /**
     * @param mergeLocal  Whether to merge existing offline data into account.
     * If false, any pre-existing data will be fully deleted upon success.
     */
    async signIn(email, password, strict = false, ephemeral = false, mergeLocal = true, awaitSync = false) {
        if (this.encryptionService.hasAccount()) {
            throw Error('Tried to sign in when an account already exists.');
        }
        if (this.signingIn) {
            throw Error('Already signing in.');
        }
        this.signingIn = true;
        try {
            /** Prevent a timed sync from occuring while signing in. */
            this.lockSyncing();
            const { response } = await this.sessionManager.signIn(email, password, strict, ephemeral);
            if (!(0, responses_1.isErrorResponse)(response)) {
                const notifyingFunction = awaitSync ? this.notifyEventSync.bind(this) : this.notifyEvent.bind(this);
                await notifyingFunction(AccountEvent_1.AccountEvent.SignedInOrRegistered, {
                    payload: {
                        mergeLocal,
                        awaitSync,
                        ephemeral,
                        checkIntegrity: true,
                    },
                });
            }
            else {
                this.unlockSyncing();
            }
            return response;
        }
        finally {
            this.signingIn = false;
        }
    }
    async deleteAccount() {
        if (!(await this.protectionService.authorizeAction(Challenge_1.ChallengeReason.DeleteAccount, {
            fallBackToAccountPassword: true,
            requireAccountPassword: true,
            forcePrompt: false,
        }))) {
            return {
                error: true,
                message: Messages.INVALID_PASSWORD,
            };
        }
        const uuid = this.sessionManager.getSureUser().uuid;
        const response = await this.userApiService.deleteAccount(uuid);
        if ((0, responses_1.isErrorResponse)(response)) {
            return {
                error: true,
                message: (0, responses_1.getErrorFromErrorResponse)(response).message,
            };
        }
        await this.signOut(true);
        void this.alertService.alert(InfoStrings_1.InfoStrings.AccountDeleted);
        return {
            error: false,
        };
    }
    async submitUserRequest(requestType) {
        const userUuid = this.sessionManager.getSureUser().uuid;
        try {
            const result = await this.userApiService.submitUserRequest({
                userUuid,
                requestType,
            });
            if ((0, responses_1.isErrorResponse)(result)) {
                return false;
            }
            return result.data.success;
        }
        catch (error) {
            return false;
        }
    }
    /**
     * A sign in request that occurs while the user was previously signed in, to correct
     * for missing keys or storage values. Unlike regular sign in, this doesn't worry about
     * performing one of marking all items as needing sync or deleting all local data.
     */
    async correctiveSignIn(rootKey) {
        this.lockSyncing();
        const response = await this.sessionManager.bypassChecksAndSignInWithRootKey(rootKey.keyParams.identifier, rootKey, false);
        if (!(0, responses_1.isErrorResponse)(response)) {
            await this.notifyEvent(AccountEvent_1.AccountEvent.SignedInOrRegistered, {
                payload: {
                    mergeLocal: true,
                    awaitSync: true,
                    ephemeral: false,
                    checkIntegrity: true,
                },
            });
        }
        this.unlockSyncing();
        return response;
    }
    /**
     * @param passcode - Changing the account password or email requires the local
     * passcode if configured (to rewrap the account key with passcode). If the passcode
     * is not passed in, the user will be prompted for the passcode. However if the consumer
     * already has reference to the passcode, they can pass it in here so that the user
     * is not prompted again.
     */
    async changeCredentials(parameters) {
        const result = await this.performCredentialsChange(parameters);
        if (result.error) {
            void this.alertService.alert(result.error.message);
        }
        return result;
    }
    async signOut(force = false, source = DeinitSource_1.DeinitSource.SignOut) {
        const performSignOut = async () => {
            await this.sessionManager.signOut();
            await this.encryptionService.deleteWorkspaceSpecificKeyStateFromDevice();
            await this.storageService.clearAllData();
            await this.notifyEvent(AccountEvent_1.AccountEvent.SignedOut, { payload: { source } });
        };
        if (force) {
            await performSignOut();
            return;
        }
        const dirtyItems = this.itemManager.getDirtyItems();
        if (dirtyItems.length > 0) {
            const singular = dirtyItems.length === 1;
            const didConfirm = await this.alertService.confirm(`There ${singular ? 'is' : 'are'} ${dirtyItems.length} ${singular ? 'item' : 'items'} with unsynced changes. If you sign out, these changes will be lost forever. Are you sure you want to sign out?`);
            if (didConfirm) {
                await performSignOut();
            }
        }
        else {
            await performSignOut();
        }
    }
    async updateAccountWithFirstTimeKeyPair() {
        if (!this.sessionManager.isUserMissingKeyPair()) {
            throw Error('Cannot update account with first time keypair if user already has a keypair');
        }
        const result = await this.performProtocolUpgrade();
        return result;
    }
    async performProtocolUpgrade() {
        const hasPasscode = this.encryptionService.hasPasscode();
        const hasAccount = this.encryptionService.hasAccount();
        const prompts = [];
        if (hasPasscode) {
            prompts.push(new Challenge_1.ChallengePrompt(Challenge_1.ChallengeValidation.LocalPasscode, undefined, Messages.ChallengeStrings.LocalPasscodePlaceholder));
        }
        if (hasAccount) {
            prompts.push(new Challenge_1.ChallengePrompt(Challenge_1.ChallengeValidation.AccountPassword, undefined, Messages.ChallengeStrings.AccountPasswordPlaceholder));
        }
        const challenge = new Challenge_1.Challenge(prompts, Challenge_1.ChallengeReason.ProtocolUpgrade, true);
        const response = await this.challengeService.promptForChallengeResponse(challenge);
        if (!response) {
            return { canceled: true };
        }
        const dismissBlockingDialog = await this.alertService.blockingDialog(Messages.DO_NOT_CLOSE_APPLICATION, Messages.UPGRADING_ENCRYPTION);
        try {
            let passcode;
            if (hasPasscode) {
                /* Upgrade passcode version */
                const value = response.getValueForType(Challenge_1.ChallengeValidation.LocalPasscode);
                passcode = value.value;
            }
            if (hasAccount) {
                /* Upgrade account version */
                const value = response.getValueForType(Challenge_1.ChallengeValidation.AccountPassword);
                const password = value.value;
                const changeResponse = await this.changeCredentials({
                    currentPassword: password,
                    newPassword: password,
                    passcode,
                    origination: common_1.KeyParamsOrigination.ProtocolUpgrade,
                    validateNewPasswordStrength: false,
                });
                if (changeResponse === null || changeResponse === void 0 ? void 0 : changeResponse.error) {
                    return { error: changeResponse.error };
                }
            }
            if (hasPasscode) {
                /* Upgrade passcode version */
                await this.removePasscodeWithoutWarning();
                await this.setPasscodeWithoutWarning(passcode, common_1.KeyParamsOrigination.ProtocolUpgrade);
            }
            return { success: true };
        }
        catch (error) {
            return { error: error };
        }
        finally {
            dismissBlockingDialog();
        }
    }
    async addPasscode(passcode) {
        if (passcode.length < this.MINIMUM_PASSCODE_LENGTH) {
            return false;
        }
        if (!(await this.protectionService.authorizeAddingPasscode())) {
            return false;
        }
        const dismissBlockingDialog = await this.alertService.blockingDialog(Messages.DO_NOT_CLOSE_APPLICATION, Messages.SETTING_PASSCODE);
        try {
            await this.setPasscodeWithoutWarning(passcode, common_1.KeyParamsOrigination.PasscodeCreate);
            return true;
        }
        finally {
            dismissBlockingDialog();
        }
    }
    async removePasscode() {
        if (!(await this.protectionService.authorizeRemovingPasscode())) {
            return false;
        }
        const dismissBlockingDialog = await this.alertService.blockingDialog(Messages.DO_NOT_CLOSE_APPLICATION, Messages.REMOVING_PASSCODE);
        try {
            await this.removePasscodeWithoutWarning();
            return true;
        }
        finally {
            dismissBlockingDialog();
        }
    }
    /**
     * @returns whether the passcode was successfuly changed or not
     */
    async changePasscode(newPasscode, origination = common_1.KeyParamsOrigination.PasscodeChange) {
        if (newPasscode.length < this.MINIMUM_PASSCODE_LENGTH) {
            return false;
        }
        if (!(await this.protectionService.authorizeChangingPasscode())) {
            return false;
        }
        const dismissBlockingDialog = await this.alertService.blockingDialog(Messages.DO_NOT_CLOSE_APPLICATION, origination === common_1.KeyParamsOrigination.ProtocolUpgrade
            ? Messages.ProtocolUpgradeStrings.UpgradingPasscode
            : Messages.CHANGING_PASSCODE);
        try {
            await this.removePasscodeWithoutWarning();
            await this.setPasscodeWithoutWarning(newPasscode, origination);
            return true;
        }
        finally {
            dismissBlockingDialog();
        }
    }
    async populateSessionFromDemoShareToken(token) {
        await this.sessionManager.populateSessionFromDemoShareToken(token);
        await this.notifyEvent(AccountEvent_1.AccountEvent.SignedInOrRegistered, {
            payload: {
                ephemeral: false,
                mergeLocal: false,
                checkIntegrity: false,
                awaitSync: true,
            },
        });
    }
    async setPasscodeWithoutWarning(passcode, origination) {
        const identifier = utils_1.UuidGenerator.GenerateUuid();
        const key = await this.encryptionService.createRootKey(identifier, passcode, origination);
        await this.encryptionService.setNewRootKeyWrapper(key);
        await this.rewriteItemsKeys();
        await this.syncService.sync();
    }
    async removePasscodeWithoutWarning() {
        await this.encryptionService.removePasscode();
        await this.rewriteItemsKeys();
    }
    /**
     * Allows items keys to be rewritten to local db on local credential status change,
     * such as if passcode is added, changed, or removed.
     * This allows IndexedDB unencrypted logs to be deleted
     * `deletePayloads` will remove data from backing store,
     * but not from working memory See:
     * https://github.com/standardnotes/desktop/issues/131
     */
    async rewriteItemsKeys() {
        const itemsKeys = this.itemManager.getDisplayableItemsKeys();
        const payloads = itemsKeys.map((key) => key.payloadRepresentation());
        await this.storageService.deletePayloads(payloads);
        await this.syncService.persistPayloads(payloads);
    }
    lockSyncing() {
        this.syncService.lockSyncing();
    }
    unlockSyncing() {
        this.syncService.unlockSyncing();
    }
    clearDatabase() {
        return this.storageService.clearAllPayloads();
    }
    async performCredentialsChange(parameters) {
        var _a;
        const { wrappingKey, canceled } = await this.challengeService.getWrappingKeyIfApplicable(parameters.passcode);
        if (canceled) {
            return { error: Error(Messages.CredentialsChangeStrings.PasscodeRequired) };
        }
        if (parameters.newPassword !== undefined && parameters.validateNewPasswordStrength) {
            if (parameters.newPassword.length < this.MINIMUM_PASSWORD_LENGTH) {
                return {
                    error: Error(Messages.InsufficientPasswordMessage(this.MINIMUM_PASSWORD_LENGTH)),
                };
            }
        }
        const accountPasswordValidation = await this.encryptionService.validateAccountPassword(parameters.currentPassword);
        if (!accountPasswordValidation.valid) {
            return {
                error: Error(Messages.INVALID_PASSWORD),
            };
        }
        const user = this.sessionManager.getUser();
        const currentEmail = user.email;
        const { currentRootKey, newRootKey } = await this.recomputeRootKeysForCredentialChange({
            currentPassword: parameters.currentPassword,
            currentEmail,
            origination: parameters.origination,
            newEmail: parameters.newEmail,
            newPassword: parameters.newPassword,
        });
        this.lockSyncing();
        const { response } = await this.sessionManager.changeCredentials({
            currentServerPassword: currentRootKey.serverPassword,
            newRootKey: newRootKey,
            wrappingKey,
            newEmail: parameters.newEmail,
        });
        this.unlockSyncing();
        if ((0, responses_1.isErrorResponse)(response)) {
            return { error: Error((_a = response.data.error) === null || _a === void 0 ? void 0 : _a.message) };
        }
        const rollback = await this.encryptionService.createNewItemsKeyWithRollback();
        await this.encryptionService.reencryptApplicableItemsAfterUserRootKeyChange();
        await this.syncService.sync({ awaitAll: true });
        const defaultItemsKey = this.encryptionService.getSureDefaultItemsKey();
        const itemsKeyWasSynced = !defaultItemsKey.neverSynced;
        if (!itemsKeyWasSynced) {
            await this.sessionManager.changeCredentials({
                currentServerPassword: newRootKey.serverPassword,
                newRootKey: currentRootKey,
                wrappingKey,
            });
            await this.encryptionService.reencryptApplicableItemsAfterUserRootKeyChange();
            await rollback();
            await this.syncService.sync({ awaitAll: true });
            return { error: Error(Messages.CredentialsChangeStrings.Failed) };
        }
        return {};
    }
    async recomputeRootKeysForCredentialChange(parameters) {
        var _a, _b;
        const currentRootKey = await this.encryptionService.computeRootKey(parameters.currentPassword, (await this.encryptionService.getRootKeyParams()));
        const newRootKey = await this.encryptionService.createRootKey((_a = parameters.newEmail) !== null && _a !== void 0 ? _a : parameters.currentEmail, (_b = parameters.newPassword) !== null && _b !== void 0 ? _b : parameters.currentPassword, parameters.origination);
        return {
            currentRootKey,
            newRootKey,
        };
    }
}
exports.UserService = UserService;
