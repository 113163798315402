"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const Commands_1 = require("../Commands");
const LexicalComposerContext_1 = require("@lexical/react/LexicalComposerContext");
const react_1 = require("react");
const FileNode_1 = require("./Nodes/FileNode");
const lexical_1 = require("lexical");
const FileUtils_1 = require("./Nodes/FileUtils");
const utils_1 = require("@lexical/utils");
const FilesControllerProvider_1 = require("@/Controllers/FilesControllerProvider");
const FilesController_1 = require("@/Controllers/FilesController");
const LinkingControllerProvider_1 = require("@/Controllers/LinkingControllerProvider");
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
function FilePlugin({ currentNote }) {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [editor] = (0, LexicalComposerContext_1.useLexicalComposerContext)();
    const filesController = (0, FilesControllerProvider_1.useFilesController)();
    const linkingController = (0, LinkingControllerProvider_1.useLinkingController)();
    (0, react_1.useEffect)(() => {
        if (!editor.hasNodes([FileNode_1.FileNode])) {
            throw new Error('FilePlugin: FileNode not registered on editor');
        }
        const uploadFilesList = (files) => {
            Array.from(files).forEach(async (file) => {
                try {
                    const uploadedFile = await filesController.uploadNewFile(file);
                    if (uploadedFile) {
                        editor.dispatchCommand(Commands_1.INSERT_FILE_COMMAND, uploadedFile.uuid);
                        void linkingController.linkItemToSelectedItem(uploadedFile);
                        void application.changeAndSaveItem(uploadedFile, (mutator) => {
                            mutator.protected = currentNote.protected;
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                }
            });
        };
        return (0, utils_1.mergeRegister)(editor.registerCommand(Commands_1.INSERT_FILE_COMMAND, (payload) => {
            const fileNode = (0, FileUtils_1.$createFileNode)(payload);
            (0, lexical_1.$insertNodes)([fileNode]);
            if ((0, lexical_1.$isRootOrShadowRoot)(fileNode.getParentOrThrow())) {
                (0, utils_1.$wrapNodeInElement)(fileNode, lexical_1.$createParagraphNode).selectEnd();
            }
            const newLineNode = (0, lexical_1.$createParagraphNode)();
            (0, lexical_1.$insertNodes)([newLineNode]);
            return true;
        }, lexical_1.COMMAND_PRIORITY_EDITOR), editor.registerCommand(lexical_1.PASTE_COMMAND, (payload) => {
            var _a;
            const files = payload instanceof ClipboardEvent ? (_a = payload.clipboardData) === null || _a === void 0 ? void 0 : _a.files : null;
            if (files === null || files === void 0 ? void 0 : files.length) {
                uploadFilesList(files);
                return true;
            }
            return false;
        }, lexical_1.COMMAND_PRIORITY_NORMAL));
    }, [application, currentNote.protected, editor, filesController, linkingController]);
    (0, react_1.useEffect)(() => {
        const disposer = filesController.addEventObserver((event, data) => {
            if (event === FilesController_1.FilesControllerEvent.FileUploadedToNote) {
                const fileUuid = data[FilesController_1.FilesControllerEvent.FileUploadedToNote].uuid;
                editor.dispatchCommand(Commands_1.INSERT_FILE_COMMAND, fileUuid);
            }
        });
        return disposer;
    }, [filesController, editor]);
    return null;
}
exports.default = FilePlugin;
