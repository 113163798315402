"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NoteHistoryController = exports.RevisionContentState = void 0;
const RevisionType_1 = require("@/Components/RevisionHistoryModal/RevisionType");
const utils_1 = require("@/Components/RevisionHistoryModal/utils");
const Strings_1 = require("@/Constants/Strings");
const ui_services_1 = require("@standardnotes/ui-services");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
var RevisionContentState;
(function (RevisionContentState) {
    RevisionContentState[RevisionContentState["Idle"] = 0] = "Idle";
    RevisionContentState[RevisionContentState["Loading"] = 1] = "Loading";
    RevisionContentState[RevisionContentState["Loaded"] = 2] = "Loaded";
    RevisionContentState[RevisionContentState["NotEntitled"] = 3] = "NotEntitled";
})(RevisionContentState = exports.RevisionContentState || (exports.RevisionContentState = {}));
class NoteHistoryController {
    constructor(application, note, selectionController) {
        this.application = application;
        this.note = note;
        this.selectionController = selectionController;
        this.remoteHistory = [];
        this.isFetchingRemoteHistory = false;
        this.sessionHistory = [];
        this.legacyHistory = [];
        this.selectedRevision = undefined;
        this.selectedEntry = undefined;
        this.contentState = RevisionContentState.Idle;
        this.currentTab = RevisionType_1.RevisionType.Remote;
        this.setSelectedRevision = (revision) => {
            this.selectedRevision = revision;
        };
        this.setSelectedEntry = (entry) => {
            this.selectedEntry = entry;
        };
        this.clearSelection = () => {
            this.setSelectedEntry(undefined);
            this.setSelectedRevision(undefined);
        };
        this.selectTab = (tab) => {
            this.currentTab = tab;
            this.clearSelection();
            this.setContentState(RevisionContentState.Idle);
            this.selectFirstRevision();
        };
        this.setIsFetchingRemoteHistory = (value) => {
            this.isFetchingRemoteHistory = value;
        };
        this.setContentState = (contentState) => {
            this.contentState = contentState;
        };
        this.selectRemoteRevision = async (entry) => {
            if (!this.note) {
                return;
            }
            if (!this.application.features.hasMinimumRole(entry.required_role)) {
                this.setContentState(RevisionContentState.NotEntitled);
                this.setSelectedRevision(undefined);
                return;
            }
            this.setContentState(RevisionContentState.Loading);
            this.clearSelection();
            try {
                this.setSelectedEntry(entry);
                const remoteRevisionOrError = await this.application.getRevision.execute({
                    itemUuid: this.note.uuid,
                    revisionUuid: entry.uuid,
                });
                if (remoteRevisionOrError.isFailed()) {
                    throw new Error(remoteRevisionOrError.getError());
                }
                const remoteRevision = remoteRevisionOrError.getValue();
                this.setSelectedRevision(remoteRevision);
            }
            catch (err) {
                this.clearSelection();
                console.error(err);
            }
            finally {
                this.setContentState(RevisionContentState.Loaded);
            }
        };
        this.selectLegacyRevision = async (entry) => {
            var _a;
            this.clearSelection();
            this.setContentState(RevisionContentState.Loading);
            if (!this.note) {
                return;
            }
            try {
                if (!((_a = entry.subactions) === null || _a === void 0 ? void 0 : _a[0])) {
                    throw new Error('Could not find revision action url');
                }
                this.setSelectedEntry(entry);
                const response = await this.application.actionsManager.runAction(entry.subactions[0], this.note);
                if (!response) {
                    throw new Error('Could not fetch revision');
                }
                this.setSelectedRevision(response.item);
            }
            catch (error) {
                console.error(error);
                this.setSelectedRevision(undefined);
            }
            finally {
                this.setContentState(RevisionContentState.Loaded);
            }
        };
        this.selectSessionRevision = (entry) => {
            this.clearSelection();
            this.setSelectedEntry(entry);
            this.setSelectedRevision(entry);
            this.setContentState(RevisionContentState.Loaded);
        };
        this.selectFirstRevision = () => {
            switch (this.currentTab) {
                case RevisionType_1.RevisionType.Remote: {
                    const firstEntry = this.flattenedRemoteHistory[0];
                    if (firstEntry) {
                        void this.selectRemoteRevision(firstEntry);
                    }
                    break;
                }
                case RevisionType_1.RevisionType.Session: {
                    const firstEntry = this.flattenedSessionHistory[0];
                    if (firstEntry) {
                        void this.selectSessionRevision(firstEntry);
                    }
                    break;
                }
                case RevisionType_1.RevisionType.Legacy: {
                    const firstEntry = this.legacyHistory[0];
                    if (firstEntry) {
                        void this.selectLegacyRevision(firstEntry);
                    }
                    break;
                }
            }
        };
        this.selectPrevOrNextRemoteRevision = (revisionEntry) => {
            const currentIndex = this.flattenedRemoteHistory.findIndex((entry) => (entry === null || entry === void 0 ? void 0 : entry.uuid) === revisionEntry.uuid);
            const previousEntry = this.flattenedRemoteHistory[currentIndex - 1];
            const nextEntry = this.flattenedRemoteHistory[currentIndex + 1];
            if (previousEntry) {
                void this.selectRemoteRevision(previousEntry);
            }
            else if (nextEntry) {
                void this.selectRemoteRevision(nextEntry);
            }
        };
        this.setRemoteHistory = (remoteHistory) => {
            this.remoteHistory = remoteHistory;
        };
        this.fetchRemoteHistory = async () => {
            this.setRemoteHistory([]);
            if (this.note) {
                this.setIsFetchingRemoteHistory(true);
                try {
                    const revisionsListOrError = await this.application.listRevisions.execute({ itemUuid: this.note.uuid });
                    if (revisionsListOrError.isFailed()) {
                        throw new Error(revisionsListOrError.getError());
                    }
                    const revisionsList = revisionsListOrError.getValue();
                    this.setRemoteHistory((0, utils_1.sortRevisionListIntoGroups)(revisionsList));
                }
                catch (err) {
                    console.error(err);
                }
                finally {
                    this.setIsFetchingRemoteHistory(false);
                }
            }
        };
        this.setLegacyHistory = (legacyHistory) => {
            this.legacyHistory = legacyHistory;
        };
        this.fetchLegacyHistory = async () => {
            const actionExtensions = this.application.actionsManager.getExtensions();
            actionExtensions.forEach(async (ext) => {
                if (!this.note) {
                    return;
                }
                const actionExtension = await this.application.actionsManager.loadExtensionInContextOfItem(ext, this.note);
                if (!actionExtension) {
                    return;
                }
                const isLegacyNoteHistoryExt = actionExtension === null || actionExtension === void 0 ? void 0 : actionExtension.actions.some((action) => action.verb === snjs_1.ActionVerb.Nested);
                if (!isLegacyNoteHistoryExt) {
                    return;
                }
                this.setLegacyHistory(actionExtension.actions.filter((action) => { var _a; return (_a = action.subactions) === null || _a === void 0 ? void 0 : _a[0]; }));
            });
        };
        this.setSessionHistory = (sessionHistory) => {
            this.sessionHistory = sessionHistory;
        };
        this.fetchAllHistory = async () => {
            this.resetHistoryState();
            if (!this.note) {
                return;
            }
            this.setSessionHistory((0, utils_1.sortRevisionListIntoGroups)(this.application.historyManager.sessionHistoryForItem(this.note)));
            await this.fetchRemoteHistory();
            await this.fetchLegacyHistory();
            this.selectFirstRevision();
        };
        this.resetHistoryState = () => {
            this.remoteHistory = [];
            this.sessionHistory = [];
            this.legacyHistory = [];
        };
        this.restoreRevision = async (revision) => {
            const originalNote = this.application.items.findItem(revision.payload.uuid);
            if (originalNote === null || originalNote === void 0 ? void 0 : originalNote.locked) {
                this.application.alertService.alert(Strings_1.STRING_RESTORE_LOCKED_ATTEMPT).catch(console.error);
                return;
            }
            const didConfirm = await (0, ui_services_1.confirmDialog)({
                text: "Are you sure you want to replace the current note's contents with what you see in this preview?",
                confirmButtonStyle: 'danger',
            });
            if (!originalNote) {
                throw new Error('Original note not found.');
            }
            if (didConfirm) {
                void this.application.changeAndSaveItem(originalNote, (mutator) => {
                    mutator.setCustomContent(revision.payload.content);
                }, true, snjs_1.PayloadEmitSource.RemoteRetrieved);
            }
        };
        this.restoreRevisionAsCopy = async (revision) => {
            const originalNote = this.application.items.findSureItem(revision.payload.uuid);
            const duplicatedItem = await this.application.mutator.duplicateItem(originalNote, false, {
                ...revision.payload.content,
                title: revision.payload.content.title ? revision.payload.content.title + ' (copy)' : undefined,
            });
            void this.application.sync.sync();
            this.selectionController.selectItem(duplicatedItem.uuid).catch(console.error);
        };
        this.deleteRemoteRevision = async (revisionEntry) => {
            const shouldDelete = await this.application.alertService.confirm('Are you sure you want to delete this revision?', 'Delete revision?', 'Delete revision', snjs_1.ButtonType.Danger, 'Cancel');
            if (!shouldDelete || !this.note) {
                return;
            }
            const deleteRevisionOrError = await this.application.deleteRevision.execute({
                itemUuid: this.note.uuid,
                revisionUuid: revisionEntry.uuid,
            });
            if (deleteRevisionOrError.isFailed()) {
                throw new Error(deleteRevisionOrError.getError());
            }
            this.clearSelection();
            this.selectPrevOrNextRemoteRevision(revisionEntry);
            await this.fetchRemoteHistory();
        };
        void this.fetchAllHistory();
        (0, mobx_1.makeObservable)(this, {
            selectedRevision: mobx_1.observable,
            setSelectedRevision: mobx_1.action,
            selectedEntry: mobx_1.observable,
            setSelectedEntry: mobx_1.action,
            remoteHistory: mobx_1.observable,
            setRemoteHistory: mobx_1.action,
            isFetchingRemoteHistory: mobx_1.observable,
            setIsFetchingRemoteHistory: mobx_1.action,
            sessionHistory: mobx_1.observable,
            setSessionHistory: mobx_1.action,
            legacyHistory: mobx_1.observable,
            setLegacyHistory: mobx_1.action,
            resetHistoryState: mobx_1.action,
            currentTab: mobx_1.observable,
            selectTab: mobx_1.action,
            contentState: mobx_1.observable,
            setContentState: mobx_1.action,
        });
    }
    get flattenedRemoteHistory() {
        return this.remoteHistory.map((group) => group.entries).flat();
    }
    get flattenedSessionHistory() {
        return this.sessionHistory.map((group) => group.entries).flat();
    }
}
exports.NoteHistoryController = NoteHistoryController;
