"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.serverFeatures = void 0;
const PermissionName_1 = require("../Permission/PermissionName");
const FeatureIdentifier_1 = require("../Feature/FeatureIdentifier");
const domain_core_1 = require("@standardnotes/domain-core");
function serverFeatures() {
    return [
        {
            name: 'Two factor authentication',
            identifier: FeatureIdentifier_1.FeatureIdentifier.TwoFactorAuth,
            permission_name: PermissionName_1.PermissionName.TwoFactorAuth,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: 'U2F authentication',
            identifier: FeatureIdentifier_1.FeatureIdentifier.UniversalSecondFactor,
            permission_name: PermissionName_1.PermissionName.UniversalSecondFactor,
            availableInRoles: [domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: 'Unlimited note history',
            identifier: FeatureIdentifier_1.FeatureIdentifier.NoteHistoryUnlimited,
            permission_name: PermissionName_1.PermissionName.NoteHistoryUnlimited,
            availableInRoles: [domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: '365 days note history',
            identifier: FeatureIdentifier_1.FeatureIdentifier.NoteHistory365Days,
            permission_name: PermissionName_1.PermissionName.NoteHistory365Days,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser],
        },
        {
            name: 'Email backups',
            identifier: FeatureIdentifier_1.FeatureIdentifier.DailyEmailBackup,
            permission_name: PermissionName_1.PermissionName.DailyEmailBackup,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: 'Sign-in email alerts',
            identifier: FeatureIdentifier_1.FeatureIdentifier.SignInAlerts,
            permission_name: PermissionName_1.PermissionName.SignInAlerts,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: 'Files maximum storage tier',
            identifier: FeatureIdentifier_1.FeatureIdentifier.FilesMaximumStorageTier,
            permission_name: PermissionName_1.PermissionName.FilesMaximumStorageTier,
            availableInRoles: [domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: 'Files low storage tier',
            identifier: FeatureIdentifier_1.FeatureIdentifier.FilesLowStorageTier,
            permission_name: PermissionName_1.PermissionName.FilesLowStorageTier,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser],
        },
        {
            name: 'Files medium storage tier',
            identifier: FeatureIdentifier_1.FeatureIdentifier.SubscriptionSharing,
            permission_name: PermissionName_1.PermissionName.SubscriptionSharing,
            availableInRoles: [domain_core_1.RoleName.NAMES.ProUser],
        },
        {
            name: 'Listed Custom Domain',
            identifier: FeatureIdentifier_1.FeatureIdentifier.ListedCustomDomain,
            permission_name: PermissionName_1.PermissionName.ListedCustomDomain,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
        },
    ];
}
exports.serverFeatures = serverFeatures;
