"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeVaultKeyOptionsUseCase = void 0;
const models_1 = require("@standardnotes/models");
const GetVault_1 = require("./GetVault");
const utils_1 = require("@standardnotes/utils");
class ChangeVaultKeyOptionsUseCase {
    constructor(items, mutator, sync, encryption) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.encryption = encryption;
    }
    get keys() {
        return this.encryption.keys;
    }
    async execute(dto) {
        var _a;
        const useStorageMode = (_a = dto.newKeyStorageMode) !== null && _a !== void 0 ? _a : dto.vault.keyStorageMode;
        if (dto.newPasswordType) {
            if (dto.vault.keyPasswordType === dto.newPasswordType.passwordType) {
                throw new Error('Vault password type is already set to this type');
            }
            if (dto.newPasswordType.passwordType === models_1.KeySystemRootKeyPasswordType.UserInputted) {
                if (!dto.newPasswordType.userInputtedPassword) {
                    throw new Error('User inputted password is required');
                }
                await this.changePasswordTypeToUserInputted(dto.vault, dto.newPasswordType.userInputtedPassword, useStorageMode);
            }
            else if (dto.newPasswordType.passwordType === models_1.KeySystemRootKeyPasswordType.Randomized) {
                await this.changePasswordTypeToRandomized(dto.vault, useStorageMode);
            }
        }
        if (dto.newKeyStorageMode) {
            const usecase = new GetVault_1.GetVaultUseCase(this.items);
            const latestVault = usecase.execute({ keySystemIdentifier: dto.vault.systemIdentifier });
            (0, utils_1.assert)(latestVault);
            if (latestVault.rootKeyParams.passwordType !== models_1.KeySystemRootKeyPasswordType.UserInputted) {
                throw new Error('Vault uses randomized password and cannot change its storage preference');
            }
            if (dto.newKeyStorageMode === latestVault.keyStorageMode) {
                throw new Error('Vault already uses this storage preference');
            }
            if (dto.newKeyStorageMode === models_1.KeySystemRootKeyStorageMode.Local ||
                dto.newKeyStorageMode === models_1.KeySystemRootKeyStorageMode.Ephemeral) {
                await this.changeStorageModeToLocalOrEphemeral(latestVault, dto.newKeyStorageMode);
            }
            else if (dto.newKeyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
                await this.changeStorageModeToSynced(latestVault);
            }
        }
        await this.sync.sync();
    }
    async changePasswordTypeToUserInputted(vault, userInputtedPassword, storageMode) {
        const newRootKey = this.encryption.createUserInputtedKeySystemRootKey({
            systemIdentifier: vault.systemIdentifier,
            userInputtedPassword: userInputtedPassword,
        });
        if (storageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            await this.mutator.insertItem(newRootKey, true);
        }
        else {
            this.encryption.keys.intakeNonPersistentKeySystemRootKey(newRootKey, storageMode);
        }
        await this.mutator.changeItem(vault, (mutator) => {
            mutator.rootKeyParams = newRootKey.keyParams;
        });
        await this.encryption.reencryptKeySystemItemsKeysForVault(vault.systemIdentifier);
    }
    async changePasswordTypeToRandomized(vault, storageMode) {
        const newRootKey = this.encryption.createRandomizedKeySystemRootKey({
            systemIdentifier: vault.systemIdentifier,
        });
        if (storageMode !== models_1.KeySystemRootKeyStorageMode.Synced) {
            throw new Error('Cannot change to randomized password if root key storage is not synced');
        }
        await this.mutator.changeItem(vault, (mutator) => {
            mutator.rootKeyParams = newRootKey.keyParams;
        });
        await this.mutator.insertItem(newRootKey, true);
        await this.encryption.reencryptKeySystemItemsKeysForVault(vault.systemIdentifier);
    }
    async changeStorageModeToLocalOrEphemeral(vault, newKeyStorageMode) {
        const primaryKey = this.keys.getPrimaryKeySystemRootKey(vault.systemIdentifier);
        if (!primaryKey) {
            throw new Error('No primary key found');
        }
        this.keys.intakeNonPersistentKeySystemRootKey(primaryKey, newKeyStorageMode);
        await this.keys.deleteAllSyncedKeySystemRootKeys(vault.systemIdentifier);
        await this.mutator.changeItem(vault, (mutator) => {
            mutator.keyStorageMode = newKeyStorageMode;
        });
        await this.sync.sync();
    }
    async changeStorageModeToSynced(vault) {
        const allRootKeys = this.keys.getAllKeySystemRootKeysForVault(vault.systemIdentifier);
        const syncedRootKeys = this.keys.getSyncedKeySystemRootKeysForVault(vault.systemIdentifier);
        for (const key of allRootKeys) {
            const existingSyncedKey = syncedRootKeys.find((syncedKey) => syncedKey.token === key.token);
            if (existingSyncedKey) {
                continue;
            }
            await this.mutator.insertItem(key);
        }
        await this.mutator.changeItem(vault, (mutator) => {
            mutator.keyStorageMode = models_1.KeySystemRootKeyStorageMode.Synced;
        });
    }
}
exports.ChangeVaultKeyOptionsUseCase = ChangeVaultKeyOptionsUseCase;
