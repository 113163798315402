"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidateItemSignerUseCase = void 0;
const V004AlgorithmHelpers_1 = require("@standardnotes/encryption/src/Domain/Operator/004/V004AlgorithmHelpers");
const models_1 = require("@standardnotes/models");
const FindTrustedContact_1 = require("./FindTrustedContact");
class ValidateItemSignerUseCase {
    constructor(items) {
        this.items = items;
        this.findContactUseCase = new FindTrustedContact_1.FindTrustedContactUseCase(this.items);
    }
    execute(item) {
        const uuidOfLastEditor = item.last_edited_by_uuid;
        if (uuidOfLastEditor) {
            return this.validateSignatureWithLastEditedByUuid(item, uuidOfLastEditor);
        }
        else {
            return this.validateSignatureWithNoLastEditedByUuid(item);
        }
    }
    isItemLocallyCreatedAndDoesNotRequireSignature(item) {
        return item.payload.source === models_1.PayloadSource.Constructor;
    }
    isItemResutOfRemoteSaveAndDoesNotRequireSignature(item) {
        return item.payload.source === models_1.PayloadSource.RemoteSaved;
    }
    validateSignatureWithLastEditedByUuid(item, uuidOfLastEditor) {
        const requiresSignature = (0, V004AlgorithmHelpers_1.doesPayloadRequireSigning)(item);
        const trustedContact = this.findContactUseCase.execute({ userUuid: uuidOfLastEditor });
        if (!trustedContact) {
            if (requiresSignature) {
                return 'no';
            }
            else {
                return 'not-applicable';
            }
        }
        if (!item.signatureData) {
            if (this.isItemLocallyCreatedAndDoesNotRequireSignature(item) ||
                this.isItemResutOfRemoteSaveAndDoesNotRequireSignature(item)) {
                return 'not-applicable';
            }
            if (requiresSignature) {
                return 'no';
            }
            return 'not-applicable';
        }
        const signatureData = item.signatureData;
        if (!signatureData.result) {
            if (signatureData.required) {
                return 'no';
            }
            return 'not-applicable';
        }
        const signatureResult = signatureData.result;
        if (!signatureResult.passes) {
            return 'no';
        }
        const signerPublicKey = signatureResult.publicKey;
        if (trustedContact.isSigningKeyTrusted(signerPublicKey)) {
            return 'yes';
        }
        return 'no';
    }
    validateSignatureWithNoLastEditedByUuid(item) {
        const requiresSignature = (0, V004AlgorithmHelpers_1.doesPayloadRequireSigning)(item);
        if (!item.signatureData) {
            if (this.isItemLocallyCreatedAndDoesNotRequireSignature(item) ||
                this.isItemResutOfRemoteSaveAndDoesNotRequireSignature(item)) {
                return 'not-applicable';
            }
            if (requiresSignature) {
                return 'no';
            }
            return 'not-applicable';
        }
        const signatureData = item.signatureData;
        if (!signatureData.result) {
            if (signatureData.required) {
                return 'no';
            }
            return 'not-applicable';
        }
        const signatureResult = signatureData.result;
        if (!signatureResult.passes) {
            return 'no';
        }
        const signerPublicKey = signatureResult.publicKey;
        const trustedContact = this.findContactUseCase.execute({ signingPublicKey: signerPublicKey });
        if (trustedContact) {
            return 'yes';
        }
        return 'no';
    }
}
exports.ValidateItemSignerUseCase = ValidateItemSignerUseCase;
