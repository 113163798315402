"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GenerateAuthenticatedDataUseCase = void 0;
const models_1 = require("@standardnotes/models");
const common_1 = require("@standardnotes/common");
const ItemsKey_1 = require("../../../../Keys/ItemsKey/ItemsKey");
const KeySystemItemsKey_1 = require("../../../../Keys/KeySystemItemsKey/KeySystemItemsKey");
class GenerateAuthenticatedDataUseCase {
    execute(payload, key) {
        const baseData = {
            u: payload.uuid,
            v: common_1.ProtocolVersion.V004,
        };
        if (payload.key_system_identifier) {
            baseData.ksi = payload.key_system_identifier;
        }
        if (payload.shared_vault_uuid) {
            baseData.svu = payload.shared_vault_uuid;
        }
        if ((0, models_1.ContentTypeUsesRootKeyEncryption)(payload.content_type)) {
            return {
                ...baseData,
                kp: key.keyParams.content,
            };
        }
        else if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(payload.content_type)) {
            if (!(0, models_1.isKeySystemRootKey)(key)) {
                throw Error(`Attempting to use non-key system root key ${key.content_type} for item content type ${payload.content_type}`);
            }
            return {
                ...baseData,
                kp: key.keyParams,
            };
        }
        else {
            if (!(0, ItemsKey_1.isItemsKey)(key) && !(0, KeySystemItemsKey_1.isKeySystemItemsKey)(key)) {
                throw Error('Attempting to use non-items key for regular item.');
            }
            return baseData;
        }
    }
}
exports.GenerateAuthenticatedDataUseCase = GenerateAuthenticatedDataUseCase;
