"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SelectedItemsController = void 0;
const Utils_1 = require("@/Utils");
const Logging_1 = require("@/Logging");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("./Abstract/AbstractViewController");
const CrossControllerEvent_1 = require("./CrossControllerEvent");
const PaneLayout_1 = require("./PaneController/PaneLayout");
const CloseOpenModalsAndPopovers_1 = require("@/Utils/CloseOpenModalsAndPopovers");
class SelectedItemsController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.itemListController = undefined;
    }
    constructor(application, eventBus) {
        super(application, eventBus);
        this.selectedUuids = (0, mobx_1.observable)(new Set());
        this.selectedItems = {};
        this.getPersistableValue = () => {
            return {
                selectedUuids: Array.from(this.selectedUuids),
            };
        };
        this.hydrateFromPersistedValue = (state) => {
            if (!state) {
                return;
            }
            if (!this.selectedUuids.size && state.selectedUuids.length > 0) {
                if (!this.application.options.allowNoteSelectionStatePersistence) {
                    const items = this.application.items.findItems(state.selectedUuids).filter((item) => !(0, snjs_1.isNote)(item));
                    void this.selectUuids((0, snjs_1.Uuids)(items));
                }
                else {
                    void this.selectUuids(state.selectedUuids);
                }
            }
        };
        this.getSelectedItems = () => {
            const uuids = Array.from(this.selectedUuids);
            return uuids.map((uuid) => this.application.items.findSureItem(uuid)).filter((item) => !!item);
        };
        this.getFilteredSelectedItems = (contentType) => {
            return Object.values(this.selectedItems).filter((item) => {
                return !contentType ? true : item.content_type === contentType;
            });
        };
        this.setSelectedItems = () => {
            this.selectedItems = Object.fromEntries(this.getSelectedItems().map((item) => [item.uuid, item]));
        };
        this.setSelectedUuids = (selectedUuids) => {
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Setting selected uuids', selectedUuids);
            this.selectedUuids = new Set(selectedUuids);
            this.setSelectedItems();
        };
        this.removeSelectedItem = (uuid) => {
            this.selectedUuids.delete(uuid);
            this.setSelectedUuids(this.selectedUuids);
            delete this.selectedItems[uuid];
        };
        this.deselectItem = (item) => {
            var _a;
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Deselecting item', item.uuid);
            this.removeSelectedItem(item.uuid);
            if (item.uuid === ((_a = this.lastSelectedItem) === null || _a === void 0 ? void 0 : _a.uuid)) {
                this.lastSelectedItem = undefined;
            }
        };
        this.isItemSelected = (item) => {
            return this.selectedUuids.has(item.uuid);
        };
        this.selectItemsRange = async ({ selectedItem, startingIndex, endingIndex, }) => {
            const items = this.itemListController.renderedItems;
            const lastSelectedItemIndex = startingIndex !== null && startingIndex !== void 0 ? startingIndex : items.findIndex((item) => { var _a; return item.uuid == ((_a = this.lastSelectedItem) === null || _a === void 0 ? void 0 : _a.uuid); });
            const selectedItemIndex = endingIndex !== null && endingIndex !== void 0 ? endingIndex : items.findIndex((item) => item.uuid == (selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.uuid));
            let itemsToSelect = [];
            if (selectedItemIndex > lastSelectedItemIndex) {
                itemsToSelect = items.slice(lastSelectedItemIndex, selectedItemIndex + 1);
            }
            else {
                itemsToSelect = items.slice(selectedItemIndex, lastSelectedItemIndex + 1);
            }
            const authorizedItems = await this.application.protections.authorizeProtectedActionForItems(itemsToSelect, snjs_1.ChallengeReason.SelectProtectedNote);
            for (const item of authorizedItems) {
                (0, mobx_1.runInAction)(() => {
                    this.setSelectedUuids(this.selectedUuids.add(item.uuid));
                    this.lastSelectedItem = item;
                });
            }
        };
        this.cancelMultipleSelection = () => {
            this.keyboardService.cancelAllKeyboardModifiers();
            const firstSelectedItem = this.firstSelectedItem;
            if (firstSelectedItem) {
                this.replaceSelection(firstSelectedItem);
            }
            else {
                this.deselectAll();
            }
        };
        this.replaceSelection = (item) => {
            this.deselectAll();
            (0, mobx_1.runInAction)(() => this.setSelectedUuids(this.selectedUuids.add(item.uuid)));
            this.lastSelectedItem = item;
        };
        this.selectAll = () => {
            void this.selectItemsRange({
                startingIndex: 0,
                endingIndex: this.itemListController.listLength - 1,
            });
        };
        this.deselectAll = () => {
            this.selectedUuids.clear();
            this.setSelectedUuids(this.selectedUuids);
            this.lastSelectedItem = undefined;
        };
        this.openSingleSelectedItem = async ({ userTriggered } = { userTriggered: true }) => {
            if (this.selectedItemsCount === 1) {
                const item = this.firstSelectedItem;
                if (item.content_type === snjs_1.ContentType.TYPES.Note) {
                    await this.itemListController.openNote(item.uuid);
                }
                else if (item.content_type === snjs_1.ContentType.TYPES.File) {
                    await this.itemListController.openFile(item.uuid);
                }
                if (!this.application.paneController.isInMobileView || userTriggered) {
                    void this.application.paneController.setPaneLayout(PaneLayout_1.PaneLayout.Editing);
                }
                if (this.application.paneController.isInMobileView && userTriggered) {
                    (0, CloseOpenModalsAndPopovers_1.requestCloseAllOpenModalsAndPopovers)();
                }
            }
        };
        this.selectItem = async (uuid, userTriggered) => {
            const item = this.application.items.findItem(uuid);
            if (!item) {
                return {
                    didSelect: false,
                };
            }
            (0, Logging_1.log)(Logging_1.LoggingDomain.Selection, 'Select item', item.uuid);
            const supportsMultipleSelection = this.application.options.allowMultipleSelection;
            const hasMeta = this.keyboardService.activeModifiers.has(snjs_1.KeyboardModifier.Meta);
            const hasCtrl = this.keyboardService.activeModifiers.has(snjs_1.KeyboardModifier.Ctrl);
            const hasShift = this.keyboardService.activeModifiers.has(snjs_1.KeyboardModifier.Shift);
            const hasMoreThanOneSelected = this.selectedItemsCount > 1;
            const isAuthorizedForAccess = await this.application.protections.authorizeItemAccess(item);
            if (supportsMultipleSelection && userTriggered && (hasMeta || hasCtrl)) {
                if (this.selectedUuids.has(uuid) && hasMoreThanOneSelected) {
                    this.removeSelectedItem(uuid);
                }
                else if (isAuthorizedForAccess) {
                    this.selectedUuids.add(uuid);
                    this.setSelectedUuids(this.selectedUuids);
                    this.lastSelectedItem = item;
                }
            }
            else if (supportsMultipleSelection && userTriggered && hasShift) {
                await this.selectItemsRange({ selectedItem: item });
            }
            else {
                const shouldSelectNote = hasMoreThanOneSelected || !this.selectedUuids.has(uuid);
                if (shouldSelectNote && isAuthorizedForAccess) {
                    this.replaceSelection(item);
                }
            }
            await this.openSingleSelectedItem({ userTriggered: userTriggered !== null && userTriggered !== void 0 ? userTriggered : false });
            return {
                didSelect: this.selectedUuids.has(uuid),
            };
        };
        this.selectItemWithScrollHandling = async (item, { userTriggered = false, scrollIntoView = true, animated = true }) => {
            const { didSelect } = await this.selectItem(item.uuid, userTriggered);
            const avoidMobileScrollingDueToIncompatibilityWithPaneAnimations = (0, Utils_1.isMobileScreen)();
            if (didSelect && scrollIntoView && !avoidMobileScrollingDueToIncompatibilityWithPaneAnimations) {
                this.scrollToItem(item, animated);
            }
        };
        this.scrollToItem = (item, animated = true) => {
            const itemElement = document.getElementById(item.uuid);
            itemElement === null || itemElement === void 0 ? void 0 : itemElement.scrollIntoView({
                behavior: animated ? 'smooth' : 'auto',
            });
        };
        this.selectUuids = async (uuids, userTriggered = false) => {
            const itemsForUuids = this.application.items.findItems(uuids).filter((item) => !(0, snjs_1.isFile)(item));
            if (itemsForUuids.length < 1) {
                return;
            }
            if (!userTriggered && itemsForUuids.some((item) => item.protected && (0, snjs_1.isFile)(item))) {
                return;
            }
            this.setSelectedUuids(new Set((0, snjs_1.Uuids)(itemsForUuids)));
            if (itemsForUuids.length === 1) {
                void this.openSingleSelectedItem({ userTriggered });
            }
        };
        this.selectNextItem = ({ userTriggered } = { userTriggered: true }) => {
            const displayableItems = this.itemListController.items;
            const currentIndex = displayableItems.findIndex((candidate) => {
                var _a;
                return candidate.uuid === ((_a = this.lastSelectedItem) === null || _a === void 0 ? void 0 : _a.uuid);
            });
            let nextIndex = currentIndex + 1;
            while (nextIndex < displayableItems.length) {
                const nextItem = displayableItems[nextIndex];
                nextIndex++;
                if (nextItem.protected) {
                    continue;
                }
                this.selectItemWithScrollHandling(nextItem, { userTriggered }).catch(console.error);
                const nextNoteElement = document.getElementById(nextItem.uuid);
                nextNoteElement === null || nextNoteElement === void 0 ? void 0 : nextNoteElement.focus();
                return;
            }
        };
        this.selectPreviousItem = () => {
            const displayableItems = this.itemListController.items;
            if (!this.lastSelectedItem) {
                return;
            }
            const currentIndex = displayableItems.indexOf(this.lastSelectedItem);
            let previousIndex = currentIndex - 1;
            while (previousIndex >= 0) {
                const previousItem = displayableItems[previousIndex];
                previousIndex--;
                if (previousItem.protected) {
                    continue;
                }
                this.selectItemWithScrollHandling(previousItem, { userTriggered: true }).catch(console.error);
                const previousNoteElement = document.getElementById(previousItem.uuid);
                previousNoteElement === null || previousNoteElement === void 0 ? void 0 : previousNoteElement.focus();
                return;
            }
        };
        (0, mobx_1.makeObservable)(this, {
            selectedUuids: mobx_1.observable,
            selectedItems: mobx_1.observable,
            selectedItemsCount: mobx_1.computed,
            selectedFiles: mobx_1.computed,
            selectedFilesCount: mobx_1.computed,
            firstSelectedItem: mobx_1.computed,
            selectItem: mobx_1.action,
            setSelectedUuids: mobx_1.action,
            setSelectedItems: mobx_1.action,
            hydrateFromPersistedValue: mobx_1.action,
        });
        this.disposers.push((0, mobx_1.reaction)(() => this.selectedUuids, () => {
            eventBus.publish({
                type: CrossControllerEvent_1.CrossControllerEvent.RequestValuePersistence,
                payload: undefined,
            });
        }));
    }
    setServicesPostConstruction(itemListController) {
        this.itemListController = itemListController;
        this.disposers.push(this.application.streamItems([snjs_1.ContentType.TYPES.Note, snjs_1.ContentType.TYPES.File], ({ changed, inserted, removed }) => {
            (0, mobx_1.runInAction)(() => {
                for (const removedItem of removed) {
                    this.removeSelectedItem(removedItem.uuid);
                }
                for (const item of [...changed, ...inserted]) {
                    if (this.selectedItems[item.uuid]) {
                        this.selectedItems[item.uuid] = item;
                    }
                }
            });
        }));
    }
    get keyboardService() {
        return this.application.keyboardService;
    }
    get selectedItemsCount() {
        return Object.keys(this.selectedItems).length;
    }
    get selectedFiles() {
        return this.getFilteredSelectedItems(snjs_1.ContentType.TYPES.File);
    }
    get selectedFilesCount() {
        return this.selectedFiles.length;
    }
    get firstSelectedItem() {
        return Object.values(this.selectedItems)[0];
    }
}
exports.SelectedItemsController = SelectedItemsController;
