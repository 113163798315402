"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findDefaultItemsKey = void 0;
function findDefaultItemsKey(itemsKeys) {
    if (itemsKeys.length === 1) {
        return itemsKeys[0];
    }
    const defaultKeys = itemsKeys.filter((key) => {
        return key.isDefault;
    });
    if (defaultKeys.length === 0) {
        return undefined;
    }
    if (defaultKeys.length === 1) {
        return defaultKeys[0];
    }
    /**
     * Prioritize one that is synced, as neverSynced keys will likely be deleted after
     * DownloadFirst sync.
     */
    const syncedKeys = defaultKeys.filter((key) => !key.neverSynced);
    if (syncedKeys.length > 0) {
        return syncedKeys[0];
    }
    return undefined;
}
exports.findDefaultItemsKey = findDefaultItemsKey;
