"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricMessageService = void 0;
const responses_1 = require("@standardnotes/responses");
const SyncEvent_1 = require("../Event/SyncEvent");
const AbstractService_1 = require("../Service/AbstractService");
const GetAsymmetricMessageTrustedPayload_1 = require("./UseCase/GetAsymmetricMessageTrustedPayload");
const models_1 = require("@standardnotes/models");
const HandleTrustedSharedVaultRootKeyChangedMessage_1 = require("./UseCase/HandleTrustedSharedVaultRootKeyChangedMessage");
const SessionEvent_1 = require("../Session/SessionEvent");
const api_1 = require("@standardnotes/api");
const SendOwnContactChangeMessage_1 = require("./UseCase/SendOwnContactChangeMessage");
const GetOutboundAsymmetricMessages_1 = require("./UseCase/GetOutboundAsymmetricMessages");
const GetInboundAsymmetricMessages_1 = require("./UseCase/GetInboundAsymmetricMessages");
const GetVault_1 = require("../Vaults/UseCase/GetVault");
class AsymmetricMessageService extends AbstractService_1.AbstractService {
    constructor(http, encryption, contacts, items, mutator, sync, eventBus) {
        super(eventBus);
        this.encryption = encryption;
        this.contacts = contacts;
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.messageServer = new api_1.AsymmetricMessageServer(http);
        eventBus.addEventHandler(this, SyncEvent_1.SyncEvent.ReceivedAsymmetricMessages);
        eventBus.addEventHandler(this, SessionEvent_1.SessionEvent.UserKeyPairChanged);
    }
    async handleEvent(event) {
        if (event.type === SessionEvent_1.SessionEvent.UserKeyPairChanged) {
            void this.messageServer.deleteAllInboundMessages();
            void this.sendOwnContactChangeEventToAllContacts(event.payload);
        }
        if (event.type === SyncEvent_1.SyncEvent.ReceivedAsymmetricMessages) {
            void this.handleRemoteReceivedAsymmetricMessages(event.payload);
        }
    }
    async getOutboundMessages() {
        const usecase = new GetOutboundAsymmetricMessages_1.GetOutboundAsymmetricMessages(this.messageServer);
        return usecase.execute();
    }
    async getInboundMessages() {
        const usecase = new GetInboundAsymmetricMessages_1.GetInboundAsymmetricMessages(this.messageServer);
        return usecase.execute();
    }
    async downloadAndProcessInboundMessages() {
        const messages = await this.getInboundMessages();
        if ((0, responses_1.isClientDisplayableError)(messages)) {
            return;
        }
        await this.handleRemoteReceivedAsymmetricMessages(messages);
    }
    async sendOwnContactChangeEventToAllContacts(data) {
        if (!data.oldKeyPair || !data.oldSigningKeyPair) {
            return;
        }
        const useCase = new SendOwnContactChangeMessage_1.SendOwnContactChangeMessage(this.encryption, this.messageServer);
        const contacts = this.contacts.getAllContacts();
        for (const contact of contacts) {
            if (contact.isMe) {
                continue;
            }
            await useCase.execute({
                senderOldKeyPair: data.oldKeyPair,
                senderOldSigningKeyPair: data.oldSigningKeyPair,
                senderNewKeyPair: data.newKeyPair,
                senderNewSigningKeyPair: data.newSigningKeyPair,
                contact,
            });
        }
    }
    async handleRemoteReceivedAsymmetricMessages(messages) {
        if (messages.length === 0) {
            return;
        }
        const sortedMessages = messages.slice().sort((a, b) => a.created_at_timestamp - b.created_at_timestamp);
        for (const message of sortedMessages) {
            const trustedMessagePayload = this.getTrustedMessagePayload(message);
            if (!trustedMessagePayload) {
                continue;
            }
            if (trustedMessagePayload.data.recipientUuid !== message.user_uuid) {
                continue;
            }
            if (trustedMessagePayload.type === models_1.AsymmetricMessagePayloadType.ContactShare) {
                await this.handleTrustedContactShareMessage(message, trustedMessagePayload);
            }
            else if (trustedMessagePayload.type === models_1.AsymmetricMessagePayloadType.SenderKeypairChanged) {
                await this.handleTrustedSenderKeypairChangedMessage(message, trustedMessagePayload);
            }
            else if (trustedMessagePayload.type === models_1.AsymmetricMessagePayloadType.SharedVaultRootKeyChanged) {
                await this.handleTrustedSharedVaultRootKeyChangedMessage(message, trustedMessagePayload);
            }
            else if (trustedMessagePayload.type === models_1.AsymmetricMessagePayloadType.SharedVaultMetadataChanged) {
                await this.handleVaultMetadataChangedMessage(message, trustedMessagePayload);
            }
            else if (trustedMessagePayload.type === models_1.AsymmetricMessagePayloadType.SharedVaultInvite) {
                throw new Error('Shared vault invites payloads are not handled as part of asymmetric messages');
            }
            await this.deleteMessageAfterProcessing(message);
        }
    }
    getTrustedMessagePayload(message) {
        const useCase = new GetAsymmetricMessageTrustedPayload_1.GetAsymmetricMessageTrustedPayload(this.encryption, this.contacts);
        return useCase.execute({
            privateKey: this.encryption.getKeyPair().privateKey,
            message,
        });
    }
    async deleteMessageAfterProcessing(message) {
        await this.messageServer.deleteMessage({ messageUuid: message.uuid });
    }
    async handleVaultMetadataChangedMessage(_message, trustedPayload) {
        const vault = new GetVault_1.GetVaultUseCase(this.items).execute({ sharedVaultUuid: trustedPayload.data.sharedVaultUuid });
        if (!vault) {
            return;
        }
        await this.mutator.changeItem(vault, (mutator) => {
            mutator.name = trustedPayload.data.name;
            mutator.description = trustedPayload.data.description;
        });
    }
    async handleTrustedContactShareMessage(_message, trustedPayload) {
        await this.contacts.createOrUpdateTrustedContactFromContactShare(trustedPayload.data.trustedContact);
    }
    async handleTrustedSenderKeypairChangedMessage(message, trustedPayload) {
        await this.contacts.createOrEditTrustedContact({
            contactUuid: message.sender_uuid,
            publicKey: trustedPayload.data.newEncryptionPublicKey,
            signingPublicKey: trustedPayload.data.newSigningPublicKey,
        });
    }
    async handleTrustedSharedVaultRootKeyChangedMessage(_message, trustedPayload) {
        const useCase = new HandleTrustedSharedVaultRootKeyChangedMessage_1.HandleTrustedSharedVaultRootKeyChangedMessage(this.mutator, this.items, this.sync, this.encryption);
        await useCase.execute(trustedPayload);
    }
}
exports.AsymmetricMessageService = AsymmetricMessageService;
