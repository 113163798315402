"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeySystemKeyManager = void 0;
const ApplicationStage_1 = require("./../Application/ApplicationStage");
const models_1 = require("@standardnotes/models");
const AbstractService_1 = require("../Service/AbstractService");
const domain_core_1 = require("@standardnotes/domain-core");
const RootKeyStorageKeyPrefix = 'key-system-root-key-';
class KeySystemKeyManager extends AbstractService_1.AbstractService {
    constructor(items, mutator, storage, eventBus) {
        super(eventBus);
        this.items = items;
        this.mutator = mutator;
        this.storage = storage;
        this.rootKeyMemoryCache = {};
    }
    async handleApplicationStage(stage) {
        if (stage === ApplicationStage_1.ApplicationStage.StorageDecrypted_09) {
            this.loadRootKeysFromStorage();
        }
    }
    loadRootKeysFromStorage() {
        const storageKeys = this.storage.getAllKeys().filter((key) => key.startsWith(RootKeyStorageKeyPrefix));
        const keyRawPayloads = storageKeys.map((key) => this.storage.getValue(key));
        const keyPayloads = keyRawPayloads.map((rawPayload) => new models_1.DecryptedPayload(rawPayload));
        const keys = keyPayloads.map((payload) => new models_1.KeySystemRootKey(payload));
        keys.forEach((key) => {
            this.rootKeyMemoryCache[key.systemIdentifier] = key;
        });
    }
    storageKeyForRootKey(systemIdentifier) {
        return `${RootKeyStorageKeyPrefix}${systemIdentifier}`;
    }
    intakeNonPersistentKeySystemRootKey(key, storage) {
        this.rootKeyMemoryCache[key.systemIdentifier] = key;
        if (storage === models_1.KeySystemRootKeyStorageMode.Local) {
            this.storage.setValue(this.storageKeyForRootKey(key.systemIdentifier), key.payload.ejected());
        }
    }
    undoIntakeNonPersistentKeySystemRootKey(systemIdentifier) {
        delete this.rootKeyMemoryCache[systemIdentifier];
        void this.storage.removeValue(this.storageKeyForRootKey(systemIdentifier));
    }
    getAllSyncedKeySystemRootKeys() {
        return this.items.getItems(domain_core_1.ContentType.TYPES.KeySystemRootKey);
    }
    clearMemoryOfKeysRelatedToVault(vault) {
        delete this.rootKeyMemoryCache[vault.systemIdentifier];
        const itemsKeys = this.getKeySystemItemsKeys(vault.systemIdentifier);
        this.items.removeItemsLocally(itemsKeys);
    }
    getSyncedKeySystemRootKeysForVault(systemIdentifier) {
        return this.items.itemsMatchingPredicate(domain_core_1.ContentType.TYPES.KeySystemRootKey, new models_1.Predicate('systemIdentifier', '=', systemIdentifier));
    }
    getAllKeySystemRootKeysForVault(systemIdentifier) {
        const synced = this.getSyncedKeySystemRootKeysForVault(systemIdentifier);
        const memory = this.rootKeyMemoryCache[systemIdentifier] ? [this.rootKeyMemoryCache[systemIdentifier]] : [];
        return [...synced, ...memory];
    }
    async deleteNonPersistentSystemRootKeysForVault(systemIdentifier) {
        delete this.rootKeyMemoryCache[systemIdentifier];
        await this.storage.removeValue(this.storageKeyForRootKey(systemIdentifier));
    }
    async deleteAllSyncedKeySystemRootKeys(systemIdentifier) {
        const keys = this.getSyncedKeySystemRootKeysForVault(systemIdentifier);
        await this.mutator.setItemsToBeDeleted(keys);
    }
    getKeySystemRootKeyWithToken(systemIdentifier, rootKeyToken) {
        const keys = this.getAllKeySystemRootKeysForVault(systemIdentifier).filter((key) => key.token === rootKeyToken);
        if (keys.length > 1) {
            throw new Error('Multiple synced key system root keys found for token');
        }
        return keys[0];
    }
    getPrimaryKeySystemRootKey(systemIdentifier) {
        const keys = this.getAllKeySystemRootKeysForVault(systemIdentifier);
        const sortedByNewestFirst = keys.sort((a, b) => b.keyParams.creationTimestamp - a.keyParams.creationTimestamp);
        return sortedByNewestFirst[0];
    }
    getAllKeySystemItemsKeys() {
        const decryptedItems = this.items.getItems(domain_core_1.ContentType.TYPES.KeySystemItemsKey);
        const encryptedItems = this.items.invalidItems.filter((item) => item.content_type === domain_core_1.ContentType.TYPES.KeySystemItemsKey);
        return [...decryptedItems, ...encryptedItems];
    }
    getKeySystemItemsKeys(systemIdentifier) {
        return this.items
            .getItems(domain_core_1.ContentType.TYPES.KeySystemItemsKey)
            .filter((key) => key.key_system_identifier === systemIdentifier);
    }
    getPrimaryKeySystemItemsKey(systemIdentifier) {
        const rootKey = this.getPrimaryKeySystemRootKey(systemIdentifier);
        if (!rootKey) {
            throw new Error('No primary key system root key found');
        }
        const matchingItemsKeys = this.getKeySystemItemsKeys(systemIdentifier).filter((key) => key.rootKeyToken === rootKey.token);
        const sortedByNewestFirst = matchingItemsKeys.sort((a, b) => b.creationTimestamp - a.creationTimestamp);
        return sortedByNewestFirst[0];
    }
}
exports.KeySystemKeyManager = KeySystemKeyManager;
