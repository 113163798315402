"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrefDefaults = void 0;
const features_1 = require("@standardnotes/features");
const CollectionSort_1 = require("../../Runtime/Collection/CollectionSort");
const EditorFontSize_1 = require("./EditorFontSize");
const EditorLineHeight_1 = require("./EditorLineHeight");
const EditorLineWidth_1 = require("./EditorLineWidth");
const PrefKey_1 = require("./PrefKey");
const NewNoteTitleFormat_1 = require("./NewNoteTitleFormat");
exports.PrefDefaults = {
    [PrefKey_1.PrefKey.TagsPanelWidth]: 220,
    [PrefKey_1.PrefKey.NotesPanelWidth]: 350,
    [PrefKey_1.PrefKey.EditorWidth]: null,
    [PrefKey_1.PrefKey.EditorLeft]: null,
    [PrefKey_1.PrefKey.EditorMonospaceEnabled]: false,
    [PrefKey_1.PrefKey.EditorSpellcheck]: true,
    [PrefKey_1.PrefKey.EditorResizersEnabled]: false,
    [PrefKey_1.PrefKey.EditorLineHeight]: EditorLineHeight_1.EditorLineHeight.Normal,
    [PrefKey_1.PrefKey.EditorLineWidth]: EditorLineWidth_1.EditorLineWidth.FullWidth,
    [PrefKey_1.PrefKey.EditorFontSize]: EditorFontSize_1.EditorFontSize.Normal,
    [PrefKey_1.PrefKey.SortNotesBy]: CollectionSort_1.CollectionSort.CreatedAt,
    [PrefKey_1.PrefKey.SortNotesReverse]: false,
    [PrefKey_1.PrefKey.NotesShowArchived]: false,
    [PrefKey_1.PrefKey.NotesShowTrashed]: false,
    [PrefKey_1.PrefKey.NotesHidePinned]: false,
    [PrefKey_1.PrefKey.NotesHideProtected]: false,
    [PrefKey_1.PrefKey.NotesHideNotePreview]: false,
    [PrefKey_1.PrefKey.NotesHideDate]: false,
    [PrefKey_1.PrefKey.NotesHideTags]: false,
    [PrefKey_1.PrefKey.NotesHideEditorIcon]: false,
    [PrefKey_1.PrefKey.UseSystemColorScheme]: false,
    [PrefKey_1.PrefKey.AutoLightThemeIdentifier]: 'Default',
    [PrefKey_1.PrefKey.AutoDarkThemeIdentifier]: features_1.FeatureIdentifier.DarkTheme,
    [PrefKey_1.PrefKey.NoteAddToParentFolders]: true,
    [PrefKey_1.PrefKey.NewNoteTitleFormat]: NewNoteTitleFormat_1.NewNoteTitleFormat.CurrentDateAndTime,
    [PrefKey_1.PrefKey.CustomNoteTitleFormat]: 'YYYY-MM-DD [at] hh:mm A',
    [PrefKey_1.PrefKey.UpdateSavingStatusIndicator]: true,
    [PrefKey_1.PrefKey.PaneGesturesEnabled]: true,
    [PrefKey_1.PrefKey.MomentsDefaultTagUuid]: undefined,
    [PrefKey_1.PrefKey.ClipperDefaultTagUuid]: undefined,
    [PrefKey_1.PrefKey.DefaultEditorIdentifier]: features_1.FeatureIdentifier.PlainEditor,
    [PrefKey_1.PrefKey.SuperNoteExportFormat]: 'json',
    [PrefKey_1.PrefKey.SystemViewPreferences]: {},
    [PrefKey_1.PrefKey.AuthenticatorNames]: '',
    [PrefKey_1.PrefKey.ComponentPreferences]: {},
    [PrefKey_1.PrefKey.ActiveThemes]: [],
    [PrefKey_1.PrefKey.ActiveComponents]: [],
};
