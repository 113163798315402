"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.clientFeatures = void 0;
const PermissionName_1 = require("../Permission/PermissionName");
const FeatureIdentifier_1 = require("../Feature/FeatureIdentifier");
const domain_core_1 = require("@standardnotes/domain-core");
function clientFeatures() {
    return [
        {
            name: 'Tag Nesting',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.TagNesting,
            permission_name: PermissionName_1.PermissionName.TagNesting,
            description: 'Organize your tags into folders.',
        },
        {
            name: 'Smart Filters',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.SmartFilters,
            permission_name: PermissionName_1.PermissionName.SmartFilters,
            description: 'Create smart filters for viewing notes matching specific criteria.',
        },
        {
            name: 'Encrypted files',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.Files,
            permission_name: PermissionName_1.PermissionName.Files,
            description: '',
        },
        {
            name: 'Extension',
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            identifier: FeatureIdentifier_1.FeatureIdentifier.Extension,
            permission_name: PermissionName_1.PermissionName.Extension,
            description: '',
        },
    ];
}
exports.clientFeatures = clientFeatures;
