"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesController = void 0;
const Utils_1 = require("@/Utils");
const ui_services_1 = require("@standardnotes/ui-services");
const Strings_1 = require("@/Constants/Strings");
const Constants_1 = require("@/Constants/Constants");
const snjs_1 = require("@standardnotes/snjs");
const mobx_1 = require("mobx");
const AbstractViewController_1 = require("../Abstract/AbstractViewController");
class NotesController extends AbstractViewController_1.AbstractViewController {
    deinit() {
        super.deinit();
        this.lastSelectedNote = undefined;
        this.selectionController = undefined;
        this.navigationController = undefined;
        this.itemListController = undefined;
        (0, Utils_1.destroyAllObjectProperties)(this);
    }
    constructor(application, selectionController, navigationController, eventBus) {
        super(application, eventBus);
        this.selectionController = selectionController;
        this.navigationController = navigationController;
        this.contextMenuOpen = false;
        this.contextMenuPosition = {
            top: 0,
            left: 0,
        };
        this.contextMenuClickLocation = { x: 0, y: 0 };
        this.contextMenuMaxHeight = 'auto';
        this.showProtectedWarning = false;
        this.setContextMenuOpen = (open) => {
            this.contextMenuOpen = open;
        };
        (0, mobx_1.makeObservable)(this, {
            contextMenuOpen: mobx_1.observable,
            contextMenuPosition: mobx_1.observable,
            showProtectedWarning: mobx_1.observable,
            selectedNotes: mobx_1.computed,
            firstSelectedNote: mobx_1.computed,
            selectedNotesCount: mobx_1.computed,
            trashedNotesCount: mobx_1.computed,
            setContextMenuOpen: mobx_1.action,
            setContextMenuClickLocation: mobx_1.action,
            setContextMenuPosition: mobx_1.action,
            setContextMenuMaxHeight: mobx_1.action,
            setShowProtectedWarning: mobx_1.action,
            unselectNotes: mobx_1.action,
        });
        this.shouldLinkToParentFolders = application.getPreference(snjs_1.PrefKey.NoteAddToParentFolders, snjs_1.PrefDefaults[snjs_1.PrefKey.NoteAddToParentFolders]);
        this.disposers.push(this.application.keyboardService.addCommandHandler({
            command: ui_services_1.PIN_NOTE_COMMAND,
            onKeyDown: () => {
                this.togglePinSelectedNotes();
            },
        }), this.application.keyboardService.addCommandHandler({
            command: ui_services_1.STAR_NOTE_COMMAND,
            onKeyDown: () => {
                this.toggleStarSelectedNotes();
            },
        }), this.application.addSingleEventObserver(snjs_1.ApplicationEvent.PreferencesChanged, async () => {
            this.shouldLinkToParentFolders = this.application.getPreference(snjs_1.PrefKey.NoteAddToParentFolders, snjs_1.PrefDefaults[snjs_1.PrefKey.NoteAddToParentFolders]);
        }));
    }
    setServicesPostConstruction(itemListController) {
        this.itemListController = itemListController;
        this.disposers.push(this.application.itemControllerGroup.addActiveControllerChangeObserver(() => {
            const controllers = this.application.itemControllerGroup.itemControllers;
            const activeNoteUuids = controllers.map((controller) => controller.item.uuid);
            const selectedUuids = this.getSelectedNotesList().map((n) => n.uuid);
            for (const selectedId of selectedUuids) {
                if (!activeNoteUuids.includes(selectedId)) {
                    this.selectionController.deselectItem({ uuid: selectedId });
                }
            }
        }));
    }
    get selectedNotes() {
        return this.selectionController.getFilteredSelectedItems(snjs_1.ContentType.TYPES.Note);
    }
    get firstSelectedNote() {
        return Object.values(this.selectedNotes)[0];
    }
    get selectedNotesCount() {
        if (this.dealloced) {
            return 0;
        }
        return Object.keys(this.selectedNotes).length;
    }
    get trashedNotesCount() {
        return this.application.items.trashedItems.length;
    }
    setContextMenuClickLocation(location) {
        this.contextMenuClickLocation = location;
    }
    setContextMenuPosition(position) {
        this.contextMenuPosition = position;
    }
    setContextMenuMaxHeight(maxHeight) {
        this.contextMenuMaxHeight = maxHeight;
    }
    reloadContextMenuLayout() {
        var _a;
        const { clientHeight } = document.documentElement;
        const defaultFontSize = window.getComputedStyle(document.documentElement).fontSize;
        const maxContextMenuHeight = parseFloat(defaultFontSize) * 30;
        const footerElementRect = (_a = document.getElementById('footer-bar')) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        const footerHeightInPx = footerElementRect === null || footerElementRect === void 0 ? void 0 : footerElementRect.height;
        // Open up-bottom is default behavior
        let openUpBottom = true;
        if (footerHeightInPx) {
            const bottomSpace = clientHeight - footerHeightInPx - this.contextMenuClickLocation.y;
            const upSpace = this.contextMenuClickLocation.y;
            // If not enough space to open up-bottom
            if (maxContextMenuHeight > bottomSpace) {
                // If there's enough space, open bottom-up
                if (upSpace > maxContextMenuHeight) {
                    openUpBottom = false;
                    this.setContextMenuMaxHeight('auto');
                    // Else, reduce max height (menu will be scrollable) and open in whichever direction there's more space
                }
                else {
                    if (upSpace > bottomSpace) {
                        this.setContextMenuMaxHeight(upSpace - Constants_1.MENU_MARGIN_FROM_APP_BORDER);
                        openUpBottom = false;
                    }
                    else {
                        this.setContextMenuMaxHeight(bottomSpace - Constants_1.MENU_MARGIN_FROM_APP_BORDER);
                    }
                }
            }
            else {
                this.setContextMenuMaxHeight('auto');
            }
        }
        if (openUpBottom) {
            this.setContextMenuPosition({
                top: this.contextMenuClickLocation.y,
                left: this.contextMenuClickLocation.x,
            });
        }
        else {
            this.setContextMenuPosition({
                bottom: clientHeight - this.contextMenuClickLocation.y,
                left: this.contextMenuClickLocation.x,
            });
        }
    }
    async changeSelectedNotes(mutate) {
        await this.application.mutator.changeItems(this.getSelectedNotesList(), mutate, snjs_1.MutationType.NoUpdateUserTimestamps);
        this.application.sync.sync().catch(console.error);
    }
    setHideSelectedNotePreviews(hide) {
        this.changeSelectedNotes((mutator) => {
            mutator.hidePreview = hide;
        }).catch(console.error);
    }
    setLockSelectedNotes(lock) {
        this.changeSelectedNotes((mutator) => {
            mutator.locked = lock;
        }).catch(console.error);
    }
    async setTrashSelectedNotes(trashed) {
        if (trashed) {
            const notesDeleted = await this.deleteNotes(false);
            if (notesDeleted) {
                (0, mobx_1.runInAction)(() => {
                    this.contextMenuOpen = false;
                });
            }
        }
        else {
            await this.changeSelectedNotes((mutator) => {
                mutator.trashed = trashed;
            });
            (0, mobx_1.runInAction)(() => {
                this.contextMenuOpen = false;
            });
        }
    }
    async deleteNotesPermanently() {
        await this.deleteNotes(true);
    }
    async deleteNotes(permanently) {
        if (this.getSelectedNotesList().some((note) => note.locked)) {
            const text = Strings_1.StringUtils.deleteLockedNotesAttempt(this.selectedNotesCount);
            this.application.alertService.alert(text).catch(console.error);
            return false;
        }
        const title = Strings_1.Strings.trashItemsTitle;
        let noteTitle = undefined;
        if (this.selectedNotesCount === 1) {
            const selectedNote = this.getSelectedNotesList()[0];
            noteTitle = selectedNote.title.length ? `'${selectedNote.title}'` : 'this note';
        }
        const text = Strings_1.StringUtils.deleteNotes(permanently, this.selectedNotesCount, noteTitle);
        if (await (0, ui_services_1.confirmDialog)({
            title,
            text,
            confirmButtonStyle: 'danger',
        })) {
            this.selectionController.selectNextItem();
            if (permanently) {
                await this.application.mutator.deleteItems(this.getSelectedNotesList());
                void this.application.sync.sync();
            }
            else {
                await this.changeSelectedNotes((mutator) => {
                    mutator.trashed = true;
                });
            }
            return true;
        }
        return false;
    }
    togglePinSelectedNotes() {
        const notes = this.selectedNotes;
        const pinned = notes.some((note) => note.pinned);
        if (!pinned) {
            this.setPinSelectedNotes(true);
        }
        else {
            this.setPinSelectedNotes(false);
        }
    }
    toggleStarSelectedNotes() {
        const notes = this.selectedNotes;
        const starred = notes.some((note) => note.starred);
        if (!starred) {
            this.setStarSelectedNotes(true);
        }
        else {
            this.setStarSelectedNotes(false);
        }
    }
    setPinSelectedNotes(pinned) {
        this.changeSelectedNotes((mutator) => {
            mutator.pinned = pinned;
        }).catch(console.error);
    }
    setStarSelectedNotes(starred) {
        this.changeSelectedNotes((mutator) => {
            mutator.starred = starred;
        }).catch(console.error);
    }
    async setArchiveSelectedNotes(archived) {
        if (this.getSelectedNotesList().some((note) => note.locked)) {
            this.application.alertService
                .alert(Strings_1.StringUtils.archiveLockedNotesAttempt(archived, this.selectedNotesCount))
                .catch(console.error);
            return;
        }
        await this.changeSelectedNotes((mutator) => {
            mutator.archived = archived;
        });
        (0, mobx_1.runInAction)(() => {
            this.selectionController.deselectAll();
            this.contextMenuOpen = false;
        });
    }
    async setProtectSelectedNotes(protect) {
        const selectedNotes = this.getSelectedNotesList();
        if (protect) {
            await this.application.protections.protectNotes(selectedNotes);
            this.setShowProtectedWarning(true);
        }
        else {
            await this.application.protections.unprotectNotes(selectedNotes);
            this.setShowProtectedWarning(false);
        }
        void this.application.sync.sync();
    }
    unselectNotes() {
        this.selectionController.deselectAll();
    }
    getSpellcheckStateForNote(note) {
        return note.spellcheck != undefined ? note.spellcheck : this.application.isGlobalSpellcheckEnabled();
    }
    async toggleGlobalSpellcheckForNote(note) {
        await this.application.mutator.changeItem(note, (mutator) => {
            mutator.toggleSpellcheck();
        }, snjs_1.MutationType.NoUpdateUserTimestamps);
        this.application.sync.sync().catch(console.error);
    }
    getEditorWidthForNote(note) {
        var _a;
        return ((_a = note.editorWidth) !== null && _a !== void 0 ? _a : this.application.getPreference(snjs_1.PrefKey.EditorLineWidth, snjs_1.PrefDefaults[snjs_1.PrefKey.EditorLineWidth]));
    }
    async setNoteEditorWidth(note, editorWidth) {
        await this.application.mutator.changeItem(note, (mutator) => {
            mutator.editorWidth = editorWidth;
        }, snjs_1.MutationType.NoUpdateUserTimestamps);
        this.application.sync.sync().catch(console.error);
    }
    async addTagToSelectedNotes(tag) {
        const selectedNotes = this.getSelectedNotesList();
        await Promise.all(selectedNotes.map(async (note) => {
            await this.application.mutator.addTagToNote(note, tag, this.shouldLinkToParentFolders);
        }));
        this.application.sync.sync().catch(console.error);
    }
    async removeTagFromSelectedNotes(tag) {
        const selectedNotes = this.getSelectedNotesList();
        await this.application.mutator.changeItem(tag, (mutator) => {
            for (const note of selectedNotes) {
                mutator.removeItemAsRelationship(note);
            }
        });
        this.application.sync.sync().catch(console.error);
    }
    isTagInSelectedNotes(tag) {
        const selectedNotes = this.getSelectedNotesList();
        return selectedNotes.every((note) => this.application.getItemTags(note).find((noteTag) => noteTag.uuid === tag.uuid));
    }
    setShowProtectedWarning(show) {
        this.showProtectedWarning = show;
    }
    async emptyTrash() {
        if (await (0, ui_services_1.confirmDialog)({
            text: (0, Strings_1.StringEmptyTrash)(this.trashedNotesCount),
            confirmButtonStyle: 'danger',
        })) {
            await this.application.mutator.emptyTrash();
            this.application.sync.sync().catch(console.error);
        }
    }
    getSelectedNotesList() {
        return Object.values(this.selectedNotes);
    }
}
exports.NotesController = NotesController;
