"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VaultService = void 0;
const responses_1 = require("@standardnotes/responses");
const models_1 = require("@standardnotes/models");
const VaultServiceEvent_1 = require("./VaultServiceEvent");
const CreateVault_1 = require("./UseCase/CreateVault");
const AbstractService_1 = require("../Service/AbstractService");
const RemoveItemFromVault_1 = require("./UseCase/RemoveItemFromVault");
const DeleteVault_1 = require("./UseCase/DeleteVault");
const MoveItemsToVault_1 = require("./UseCase/MoveItemsToVault");
const RotateVaultRootKey_1 = require("./UseCase/RotateVaultRootKey");
const GetVault_1 = require("./UseCase/GetVault");
const ChangeVaultKeyOptions_1 = require("./UseCase/ChangeVaultKeyOptions");
const domain_core_1 = require("@standardnotes/domain-core");
class VaultService extends AbstractService_1.AbstractService {
    constructor(sync, items, mutator, encryption, files, alerts, eventBus) {
        super(eventBus);
        this.sync = sync;
        this.items = items;
        this.mutator = mutator;
        this.encryption = encryption;
        this.files = files;
        this.alerts = alerts;
        this.lockMap = new Map();
        this.recomputeAllVaultsLockingState = async () => {
            const vaults = this.getVaults();
            for (const vault of vaults) {
                const locked = this.computeVaultLockState(vault) === 'locked';
                if (this.lockMap.get(vault.uuid) !== locked) {
                    this.lockMap.set(vault.uuid, locked);
                    if (locked) {
                        void this.notifyEvent(VaultServiceEvent_1.VaultServiceEvent.VaultLocked, { vault });
                    }
                    else {
                        void this.notifyEvent(VaultServiceEvent_1.VaultServiceEvent.VaultUnlocked, { vault });
                    }
                }
            }
        };
        items.addObserver([domain_core_1.ContentType.TYPES.KeySystemItemsKey, domain_core_1.ContentType.TYPES.KeySystemRootKey, domain_core_1.ContentType.TYPES.VaultListing], () => {
            void this.recomputeAllVaultsLockingState();
        });
    }
    getVaults() {
        return this.items.getItems(domain_core_1.ContentType.TYPES.VaultListing).sort((a, b) => {
            return a.name.localeCompare(b.name);
        });
    }
    getLockedvaults() {
        const vaults = this.getVaults();
        return vaults.filter((vault) => this.isVaultLocked(vault));
    }
    getVault(dto) {
        const usecase = new GetVault_1.GetVaultUseCase(this.items);
        return usecase.execute(dto);
    }
    getSureVault(dto) {
        const vault = this.getVault(dto);
        if (!vault) {
            throw new Error('Vault not found');
        }
        return vault;
    }
    async createRandomizedVault(dto) {
        return this.createVaultWithParameters({
            name: dto.name,
            description: dto.description,
            userInputtedPassword: undefined,
            storagePreference: dto.storagePreference,
        });
    }
    async createUserInputtedPasswordVault(dto) {
        return this.createVaultWithParameters(dto);
    }
    async createVaultWithParameters(dto) {
        const createVault = new CreateVault_1.CreateVaultUseCase(this.mutator, this.encryption, this.sync);
        const result = await createVault.execute({
            vaultName: dto.name,
            vaultDescription: dto.description,
            userInputtedPassword: dto.userInputtedPassword,
            storagePreference: dto.storagePreference,
        });
        return result;
    }
    async moveItemToVault(vault, item) {
        if (this.isVaultLocked(vault)) {
            throw new Error('Attempting to add item to locked vault');
        }
        let linkedFiles = [];
        if ((0, models_1.isNote)(item)) {
            linkedFiles = this.items.getNoteLinkedFiles(item);
            if (linkedFiles.length > 0) {
                const confirmed = await this.alerts.confirmV2({
                    title: 'Linked files will be moved to vault',
                    text: `This note has ${linkedFiles.length} linked files. They will also be moved to the vault. Do you want to continue?`,
                });
                if (!confirmed) {
                    return undefined;
                }
            }
        }
        const useCase = new MoveItemsToVault_1.MoveItemsToVaultUseCase(this.mutator, this.sync, this.files);
        await useCase.execute({ vault, items: [item, ...linkedFiles] });
        return this.items.findSureItem(item.uuid);
    }
    async removeItemFromVault(item) {
        const vault = this.getItemVault(item);
        if (!vault) {
            throw new Error('Cannot find vault to remove item from');
        }
        if (this.isVaultLocked(vault)) {
            throw new Error('Attempting to remove item from locked vault');
        }
        const useCase = new RemoveItemFromVault_1.RemoveItemFromVault(this.mutator, this.sync, this.files);
        await useCase.execute({ item });
        return this.items.findSureItem(item.uuid);
    }
    async deleteVault(vault) {
        if (vault.isSharedVaultListing()) {
            throw new Error('Shared vault must be deleted through SharedVaultService');
        }
        const useCase = new DeleteVault_1.DeleteVaultUseCase(this.items, this.mutator, this.encryption);
        const error = await useCase.execute(vault);
        if ((0, responses_1.isClientDisplayableError)(error)) {
            return false;
        }
        await this.sync.sync();
        return true;
    }
    async changeVaultNameAndDescription(vault, params) {
        const updatedVault = await this.mutator.changeItem(vault, (mutator) => {
            mutator.name = params.name;
            mutator.description = params.description;
        });
        await this.sync.sync();
        return updatedVault;
    }
    async rotateVaultRootKey(vault) {
        if (this.computeVaultLockState(vault) === 'locked') {
            throw new Error('Cannot rotate root key of locked vault');
        }
        const useCase = new RotateVaultRootKey_1.RotateVaultRootKeyUseCase(this.mutator, this.encryption);
        await useCase.execute({
            vault,
            sharedVaultUuid: vault.isSharedVaultListing() ? vault.sharing.sharedVaultUuid : undefined,
            userInputtedPassword: undefined,
        });
        await this.notifyEventSync(VaultServiceEvent_1.VaultServiceEvent.VaultRootKeyRotated, { vault });
        await this.sync.sync();
    }
    isItemInVault(item) {
        return item.key_system_identifier !== undefined;
    }
    getItemVault(item) {
        const latestItem = this.items.findItem(item.uuid);
        if (!latestItem) {
            throw new Error('Cannot find latest version of item to get vault for');
        }
        if (!latestItem.key_system_identifier) {
            return undefined;
        }
        return this.getVault({ keySystemIdentifier: latestItem.key_system_identifier });
    }
    async changeVaultOptions(dto) {
        if (this.isVaultLocked(dto.vault)) {
            throw new Error('Attempting to change vault options on a locked vault');
        }
        const usecase = new ChangeVaultKeyOptions_1.ChangeVaultKeyOptionsUseCase(this.items, this.mutator, this.sync, this.encryption);
        await usecase.execute(dto);
        if (dto.newPasswordType) {
            await this.notifyEventSync(VaultServiceEvent_1.VaultServiceEvent.VaultRootKeyRotated, { vault: dto.vault });
        }
    }
    isVaultLocked(vault) {
        return this.lockMap.get(vault.uuid) === true;
    }
    async lockNonPersistentVault(vault) {
        if (vault.keyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            throw new Error('Vault uses synced root key and cannot be locked');
        }
        this.encryption.keys.clearMemoryOfKeysRelatedToVault(vault);
        this.lockMap.set(vault.uuid, true);
        void this.notifyEventSync(VaultServiceEvent_1.VaultServiceEvent.VaultLocked, { vault });
    }
    async unlockNonPersistentVault(vault, password) {
        if (vault.keyPasswordType !== models_1.KeySystemRootKeyPasswordType.UserInputted) {
            throw new Error('Vault uses randomized password and cannot be unlocked with user inputted password');
        }
        if (vault.keyStorageMode === models_1.KeySystemRootKeyStorageMode.Synced) {
            throw new Error('Vault uses synced root key and cannot be unlocked with user inputted password');
        }
        const derivedRootKey = this.encryption.deriveUserInputtedKeySystemRootKey({
            keyParams: vault.rootKeyParams,
            userInputtedPassword: password,
        });
        this.encryption.keys.intakeNonPersistentKeySystemRootKey(derivedRootKey, vault.keyStorageMode);
        await this.encryption.decryptErroredPayloads();
        if (this.computeVaultLockState(vault) === 'locked') {
            this.encryption.keys.undoIntakeNonPersistentKeySystemRootKey(vault.systemIdentifier);
            return false;
        }
        this.lockMap.set(vault.uuid, false);
        void this.notifyEventSync(VaultServiceEvent_1.VaultServiceEvent.VaultUnlocked, { vault });
        return true;
    }
    computeVaultLockState(vault) {
        const rootKey = this.encryption.keys.getPrimaryKeySystemRootKey(vault.systemIdentifier);
        if (!rootKey) {
            return 'locked';
        }
        const itemsKey = this.encryption.keys.getPrimaryKeySystemItemsKey(vault.systemIdentifier);
        if (!itemsKey) {
            return 'locked';
        }
        return 'unlocked';
    }
    deinit() {
        super.deinit();
        this.sync = undefined;
        this.encryption = undefined;
        this.items = undefined;
    }
}
exports.VaultService = VaultService;
