"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetRemoteImageBlock = void 0;
function GetRemoteImageBlock(onSelect) {
    return {
        name: 'Image from URL',
        iconName: 'file-image',
        keywords: ['image', 'url'],
        onSelect,
    };
}
exports.GetRemoteImageBlock = GetRemoteImageBlock;
