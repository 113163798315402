"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetSharedVaultTrustedContacts = void 0;
const GetSharedVaultUsers_1 = require("./GetSharedVaultUsers");
const utils_1 = require("@standardnotes/utils");
class GetSharedVaultTrustedContacts {
    constructor(contacts, sharedVaultUsersServer) {
        this.contacts = contacts;
        this.sharedVaultUsersServer = sharedVaultUsersServer;
    }
    async execute(vault) {
        const useCase = new GetSharedVaultUsers_1.GetSharedVaultUsersUseCase(this.sharedVaultUsersServer);
        const users = await useCase.execute({ sharedVaultUuid: vault.sharing.sharedVaultUuid });
        if (!users) {
            return undefined;
        }
        const contacts = users.map((user) => this.contacts.findTrustedContact(user.user_uuid)).filter(utils_1.isNotUndefined);
        return contacts;
    }
}
exports.GetSharedVaultTrustedContacts = GetSharedVaultTrustedContacts;
