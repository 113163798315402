"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const ApplicationProvider_1 = require("@/Components/ApplicationProvider");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
const useItem = (uuid) => {
    const application = (0, ApplicationProvider_1.useApplication)();
    const [item, setItem] = (0, react_1.useState)();
    (0, react_1.useEffect)(() => {
        if (!uuid) {
            return;
        }
        const live = new snjs_1.LiveItem(uuid, application, (item) => {
            setItem(item);
        });
        return () => live.deinit();
    }, [uuid, application]);
    if (!uuid) {
        return undefined;
    }
    return item;
};
exports.default = useItem;
