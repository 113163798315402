"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const snjs_1 = require("@standardnotes/snjs");
const react_1 = require("react");
const react_2 = require("@ariakit/react");
const react_slot_1 = require("@radix-ui/react-slot");
const useMediaQuery_1 = require("@/Hooks/useMediaQuery");
const GetPositionedPopoverStyles_1 = require("../Popover/GetPositionedPopoverStyles");
const getAdjustedStylesForNonPortal_1 = require("../Popover/Utils/getAdjustedStylesForNonPortal");
const StyledTooltip = ({ children, className, label, showOnMobile = false, showOnHover = true, ...props }) => {
    const [forceOpen, setForceOpen] = (0, react_1.useState)();
    const tooltip = (0, react_2.useTooltipStore)({
        timeout: 350,
        open: forceOpen,
    });
    const isMobile = (0, useMediaQuery_1.useMediaQuery)(useMediaQuery_1.MutuallyExclusiveMediaQueryBreakpoints.sm);
    if (isMobile && !showOnMobile) {
        return (0, jsx_runtime_1.jsx)(jsx_runtime_1.Fragment, { children: children });
    }
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(react_2.TooltipAnchor, { onFocus: () => setForceOpen(true), onBlur: () => setForceOpen(undefined), store: tooltip, as: react_slot_1.Slot, showOnHover: showOnHover, children: children }), (0, jsx_runtime_1.jsx)(react_2.Tooltip, { autoFocusOnShow: !showOnHover, store: tooltip, className: (0, snjs_1.classNames)('z-tooltip max-w-max rounded border border-border bg-contrast px-3 py-1.5 text-sm text-foreground shadow', className), updatePosition: () => {
                    const { popoverElement, anchorElement, open } = tooltip.getState();
                    const documentElement = document.querySelector('.main-ui-view');
                    if (!popoverElement || !anchorElement || !documentElement || !open) {
                        return;
                    }
                    const anchorRect = anchorElement.getBoundingClientRect();
                    const popoverRect = popoverElement.getBoundingClientRect();
                    const documentRect = documentElement.getBoundingClientRect();
                    const styles = (0, GetPositionedPopoverStyles_1.getPositionedPopoverStyles)({
                        align: 'center',
                        side: 'bottom',
                        anchorRect,
                        popoverRect,
                        documentRect,
                        disableMobileFullscreenTakeover: true,
                        offset: props.gutter ? props.gutter : 6,
                    });
                    if (!styles) {
                        return;
                    }
                    Object.assign(popoverElement.style, styles);
                    if (!props.portal) {
                        const adjustedStyles = (0, getAdjustedStylesForNonPortal_1.getAdjustedStylesForNonPortalPopover)(popoverElement, styles);
                        popoverElement.style.setProperty('--translate-x', adjustedStyles['--translate-x']);
                        popoverElement.style.setProperty('--translate-y', adjustedStyles['--translate-y']);
                    }
                }, ...props, children: label })] }));
};
exports.default = StyledTooltip;
