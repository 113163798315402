"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptTrustedSharedVaultInvite = void 0;
const HandleTrustedSharedVaultInviteMessage_1 = require("../../AsymmetricMessage/UseCase/HandleTrustedSharedVaultInviteMessage");
class AcceptTrustedSharedVaultInvite {
    constructor(vaultInvitesServer, mutator, sync, contacts) {
        this.vaultInvitesServer = vaultInvitesServer;
        this.mutator = mutator;
        this.sync = sync;
        this.contacts = contacts;
    }
    async execute(dto) {
        const useCase = new HandleTrustedSharedVaultInviteMessage_1.HandleTrustedSharedVaultInviteMessage(this.mutator, this.sync, this.contacts);
        await useCase.execute(dto.message, dto.invite.shared_vault_uuid, dto.invite.sender_uuid);
        await this.vaultInvitesServer.acceptInvite({
            sharedVaultUuid: dto.invite.shared_vault_uuid,
            inviteUuid: dto.invite.uuid,
        });
    }
}
exports.AcceptTrustedSharedVaultInvite = AcceptTrustedSharedVaultInvite;
