"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportDataUseCase = void 0;
const common_1 = require("@standardnotes/common");
const models_1 = require("@standardnotes/models");
const responses_1 = require("@standardnotes/responses");
const Challenge_1 = require("../Challenge");
const domain_core_1 = require("@standardnotes/domain-core");
const Strings = {
    UnsupportedBackupFileVersion: 'This backup file was created using a newer version of the application and cannot be imported here. Please update your application and try again.',
    BackupFileMoreRecentThanAccount: "This backup file was created using a newer encryption version than your account's. Please run the available encryption upgrade and try again.",
    FileAccountPassword: 'File account password',
};
class ImportDataUseCase {
    constructor(itemManager, syncService, protectionService, encryption, payloadManager, challengeService, historyService) {
        this.itemManager = itemManager;
        this.syncService = syncService;
        this.protectionService = protectionService;
        this.encryption = encryption;
        this.payloadManager = payloadManager;
        this.challengeService = challengeService;
        this.historyService = historyService;
    }
    /**
     * @returns
     * .affectedItems: Items that were either created or dirtied by this import
     * .errorCount: The number of items that were not imported due to failure to decrypt.
     */
    async execute(data, awaitSync = false) {
        if (data.version) {
            /**
             * Prior to 003 backup files did not have a version field so we cannot
             * stop importing if there is no backup file version, only if there is
             * an unsupported version.
             */
            const version = data.version;
            const supportedVersions = this.encryption.supportedVersions();
            if (!supportedVersions.includes(version)) {
                return { error: new responses_1.ClientDisplayableError(Strings.UnsupportedBackupFileVersion) };
            }
            const userVersion = this.encryption.getUserVersion();
            if (userVersion && (0, common_1.compareVersions)(version, userVersion) === 1) {
                /** File was made with a greater version than the user's account */
                return { error: new responses_1.ClientDisplayableError(Strings.BackupFileMoreRecentThanAccount) };
            }
        }
        let password;
        if (data.auth_params || data.keyParams) {
            /** Get import file password. */
            const challenge = new Challenge_1.Challenge([new Challenge_1.ChallengePrompt(Challenge_1.ChallengeValidation.None, Strings.FileAccountPassword, undefined, true)], Challenge_1.ChallengeReason.DecryptEncryptedFile, true);
            const passwordResponse = await this.challengeService.promptForChallengeResponse(challenge);
            if (passwordResponse == undefined) {
                /** Challenge was canceled */
                return { error: new responses_1.ClientDisplayableError('Import aborted') };
            }
            this.challengeService.completeChallenge(challenge);
            password = passwordResponse === null || passwordResponse === void 0 ? void 0 : passwordResponse.values[0].value;
        }
        if (!(await this.protectionService.authorizeFileImport())) {
            return { error: new responses_1.ClientDisplayableError('Import aborted') };
        }
        data.items = data.items.map((item) => {
            if ((0, models_1.isEncryptedTransferPayload)(item)) {
                return (0, models_1.CreateEncryptedBackupFileContextPayload)(item);
            }
            else {
                return (0, models_1.CreateDecryptedBackupFileContextPayload)(item);
            }
        });
        const decryptedPayloadsOrError = await this.encryption.decryptBackupFile(data, password);
        if (decryptedPayloadsOrError instanceof responses_1.ClientDisplayableError) {
            return { error: decryptedPayloadsOrError };
        }
        const validPayloads = decryptedPayloadsOrError.filter(models_1.isDecryptedPayload).map((payload) => {
            /* Don't want to activate any components during import process in
             * case of exceptions breaking up the import proccess */
            if (payload.content_type === domain_core_1.ContentType.TYPES.Component && payload.content.active) {
                const typedContent = payload;
                return (0, models_1.CopyPayloadWithContentOverride)(typedContent, {
                    active: false,
                });
            }
            else {
                return payload;
            }
        });
        const affectedUuids = await this.payloadManager.importPayloads(validPayloads, this.historyService.getHistoryMapCopy());
        const promise = this.syncService.sync();
        if (awaitSync) {
            await promise;
        }
        const affectedItems = this.itemManager.findItems(affectedUuids);
        return {
            affectedItems: affectedItems,
            errorCount: decryptedPayloadsOrError.length - validPayloads.length,
        };
    }
}
exports.ImportDataUseCase = ImportDataUseCase;
