"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationEvent = void 0;
var ApplicationEvent;
(function (ApplicationEvent) {
    ApplicationEvent["SignedIn"] = "Application:SignedIn";
    ApplicationEvent["SignedOut"] = "Application:SignedOut";
    /** When a full, potentially multi-page sync completes */
    ApplicationEvent["CompletedFullSync"] = "Application:CompletedFullSync";
    ApplicationEvent["FailedSync"] = "Application:FailedSync";
    ApplicationEvent["HighLatencySync"] = "Application:HighLatencySync";
    ApplicationEvent["EnteredOutOfSync"] = "Application:EnteredOutOfSync";
    ApplicationEvent["ExitedOutOfSync"] = "Application:ExitedOutOfSync";
    ApplicationEvent["ApplicationStageChanged"] = "Application:ApplicationStageChanged";
    /**
     * The application has finished its prepareForLaunch state and is now ready for unlock
     * Called when the application has initialized and is ready for launch, but before
     * the application has been unlocked, if applicable. Use this to do pre-launch
     * configuration, but do not attempt to access user data like notes or tags.
     */
    ApplicationEvent["Started"] = "Application:Started";
    /**
     * The applicaiton is fully unlocked and ready for i/o
     * Called when the application has been fully decrypted and unlocked. Use this to
     * to begin streaming data like notes and tags.
     */
    ApplicationEvent["Launched"] = "Application:Launched";
    ApplicationEvent["LocalDataLoaded"] = "Application:LocalDataLoaded";
    /**
     * When the root key or root key wrapper changes. Includes events like account state
     * changes (registering, signing in, changing pw, logging out) and passcode state
     * changes (adding, removing, changing).
     */
    ApplicationEvent["KeyStatusChanged"] = "Application:KeyStatusChanged";
    ApplicationEvent["MajorDataChange"] = "Application:MajorDataChange";
    ApplicationEvent["CompletedRestart"] = "Application:CompletedRestart";
    ApplicationEvent["LocalDataIncrementalLoad"] = "Application:LocalDataIncrementalLoad";
    ApplicationEvent["SyncStatusChanged"] = "Application:SyncStatusChanged";
    ApplicationEvent["WillSync"] = "Application:WillSync";
    ApplicationEvent["InvalidSyncSession"] = "Application:InvalidSyncSession";
    ApplicationEvent["LocalDatabaseReadError"] = "Application:LocalDatabaseReadError";
    ApplicationEvent["LocalDatabaseWriteError"] = "Application:LocalDatabaseWriteError";
    /**
     * When a single roundtrip completes with sync, in a potentially multi-page sync request.
     * If just a single roundtrip, this event will be triggered, along with CompletedFullSync
     */
    ApplicationEvent["CompletedIncrementalSync"] = "Application:CompletedIncrementalSync";
    /**
     * The application has loaded all pending migrations (but not run any, except for the base one),
     * and consumers may now call hasPendingMigrations
     */
    ApplicationEvent["MigrationsLoaded"] = "Application:MigrationsLoaded";
    /** When StorageService is ready (but NOT yet decrypted) to start servicing read/write requests */
    ApplicationEvent["StorageReady"] = "Application:StorageReady";
    ApplicationEvent["PreferencesChanged"] = "Application:PreferencesChanged";
    ApplicationEvent["UnprotectedSessionBegan"] = "Application:UnprotectedSessionBegan";
    ApplicationEvent["UserRolesChanged"] = "Application:UserRolesChanged";
    ApplicationEvent["FeaturesAvailabilityChanged"] = "Application:FeaturesAvailabilityChanged";
    ApplicationEvent["UnprotectedSessionExpired"] = "Application:UnprotectedSessionExpired";
    /** Called when the app first launches and after first sync request made after sign in */
    ApplicationEvent["CompletedInitialSync"] = "Application:CompletedInitialSync";
    ApplicationEvent["BiometricsSoftLockEngaged"] = "Application:BiometricsSoftLockEngaged";
    ApplicationEvent["BiometricsSoftLockDisengaged"] = "Application:BiometricsSoftLockDisengaged";
    ApplicationEvent["DidPurchaseSubscription"] = "Application:DidPurchaseSubscription";
})(ApplicationEvent = exports.ApplicationEvent || (exports.ApplicationEvent = {}));
