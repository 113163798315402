"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsymmetricSignatureVerificationDetachedUseCase = void 0;
const ParseConsistentBase64JsonPayload_1 = require("../Utils/ParseConsistentBase64JsonPayload");
class AsymmetricSignatureVerificationDetachedUseCase {
    constructor(crypto) {
        this.crypto = crypto;
        this.parseBase64Usecase = new ParseConsistentBase64JsonPayload_1.ParseConsistentBase64JsonPayloadUseCase(this.crypto);
    }
    execute(dto) {
        const [_, __, ciphertext, additionalDataString] = dto.encryptedString.split(':');
        const additionalData = this.parseBase64Usecase.execute(additionalDataString);
        try {
            const signatureVerified = this.crypto.sodiumCryptoSignVerify(ciphertext, additionalData.signingData.signature, additionalData.signingData.publicKey);
            return {
                signatureVerified,
                signaturePublicKey: additionalData.signingData.publicKey,
                senderPublicKey: additionalData.senderPublicKey,
            };
        }
        catch (error) {
            return {
                signatureVerified: false,
            };
        }
    }
}
exports.AsymmetricSignatureVerificationDetachedUseCase = AsymmetricSignatureVerificationDetachedUseCase;
