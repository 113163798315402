"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactPublicKeySet = void 0;
class ContactPublicKeySet {
    constructor(encryption, signing, timestamp, isRevoked, previousKeySet) {
        this.encryption = encryption;
        this.signing = signing;
        this.timestamp = timestamp;
        this.isRevoked = isRevoked;
        this.previousKeySet = previousKeySet;
    }
    findKeySet(params) {
        if (this.encryption === params.targetEncryptionPublicKey && this.signing === params.targetSigningPublicKey) {
            return this;
        }
        if (this.previousKeySet) {
            return this.previousKeySet.findKeySet(params);
        }
        return undefined;
    }
    findKeySetWithSigningKey(signingKey) {
        if (this.signing === signingKey) {
            return this;
        }
        if (this.previousKeySet) {
            return this.previousKeySet.findKeySetWithSigningKey(signingKey);
        }
        return undefined;
    }
    findKeySetWithPublicKey(publicKey) {
        if (this.encryption === publicKey) {
            return this;
        }
        if (this.previousKeySet) {
            return this.previousKeySet.findKeySetWithPublicKey(publicKey);
        }
        return undefined;
    }
    static FromJson(json) {
        return new ContactPublicKeySet(json.encryption, json.signing, new Date(json.timestamp), json.isRevoked, json.previousKeySet ? ContactPublicKeySet.FromJson(json.previousKeySet) : undefined);
    }
}
exports.ContactPublicKeySet = ContactPublicKeySet;
