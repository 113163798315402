"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.nativeEditors = void 0;
const domain_core_1 = require("@standardnotes/domain-core");
const NoteType_1 = require("../Component/NoteType");
const FeatureIdentifier_1 = require("../Feature/FeatureIdentifier");
const PermissionName_1 = require("../Permission/PermissionName");
function nativeEditors() {
    return [
        {
            name: 'Super',
            note_type: NoteType_1.NoteType.Super,
            identifier: FeatureIdentifier_1.FeatureIdentifier.SuperEditor,
            spellcheckControl: true,
            file_type: 'json',
            interchangeable: false,
            availableInRoles: [domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            permission_name: PermissionName_1.PermissionName.SuperEditor,
            description: 'The best way to edit notes. Type / to bring up the block selection menu, or @ to embed images or link other tags and notes. Type - then space to start a list, or [] then space to start a checklist. Drag and drop an image or file to embed it in your note. Cmd/Ctrl + F to bring up search and replace.',
        },
        {
            name: 'Plain Text',
            note_type: NoteType_1.NoteType.Plain,
            spellcheckControl: true,
            file_type: 'txt',
            interchangeable: true,
            identifier: FeatureIdentifier_1.FeatureIdentifier.PlainEditor,
            availableInRoles: [domain_core_1.RoleName.NAMES.CoreUser, domain_core_1.RoleName.NAMES.PlusUser, domain_core_1.RoleName.NAMES.ProUser],
            permission_name: PermissionName_1.PermissionName.PlainEditor,
        },
    ];
}
exports.nativeEditors = nativeEditors;
