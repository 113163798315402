"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadPurchaseFlowUrl = exports.getPurchaseFlowUrl = void 0;
const Utils_1 = require("@/Utils");
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const getPurchaseFlowUrl = async (application) => {
    const currentUrl = window.location.origin;
    const successUrl = (0, Utils_1.isDesktopApplication)() ? 'standardnotes://' : currentUrl;
    if (application.noAccount() || application.isThirdPartyHostUsed()) {
        return `${window.purchaseUrl}/offline?&success_url=${successUrl}`;
    }
    const token = await application.getNewSubscriptionToken();
    if (token) {
        return `${window.purchaseUrl}?subscription_token=${token}&success_url=${successUrl}`;
    }
    return undefined;
};
exports.getPurchaseFlowUrl = getPurchaseFlowUrl;
const loadPurchaseFlowUrl = async (application) => {
    var _a;
    const url = await (0, exports.getPurchaseFlowUrl)(application);
    const route = application.routeService.getRoute();
    const params = route.type === ui_services_1.RouteType.Purchase ? route.purchaseParams : { period: null, plan: null };
    const period = params.period ? `&period=${params.period}` : '';
    const plan = params.plan ? `&plan=${params.plan}` : '';
    if (url) {
        const finalUrl = `${url}${period}${plan}`;
        if (application.isNativeMobileWeb()) {
            application.mobileDevice().openUrl(finalUrl);
        }
        else if (application.environment === snjs_1.Environment.Desktop) {
            (_a = application.desktopDevice) === null || _a === void 0 ? void 0 : _a.openUrl(finalUrl);
        }
        else {
            const windowProxy = window.open('', '_blank');
            windowProxy.location = finalUrl;
        }
        return true;
    }
    return false;
};
exports.loadPurchaseFlowUrl = loadPurchaseFlowUrl;
