"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EditorFontSize = void 0;
var EditorFontSize;
(function (EditorFontSize) {
    EditorFontSize["ExtraSmall"] = "ExtraSmall";
    EditorFontSize["Small"] = "Small";
    EditorFontSize["Normal"] = "Normal";
    EditorFontSize["Medium"] = "Medium";
    EditorFontSize["Large"] = "Large";
})(EditorFontSize = exports.EditorFontSize || (exports.EditorFontSize = {}));
