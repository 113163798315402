"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DecryptBackupFileUseCase = void 0;
const common_1 = require("@standardnotes/common");
const encryption_1 = require("@standardnotes/encryption");
const models_1 = require("@standardnotes/models");
const responses_1 = require("@standardnotes/responses");
const utils_1 = require("@standardnotes/utils");
const domain_core_1 = require("@standardnotes/domain-core");
class DecryptBackupFileUseCase {
    constructor(encryption) {
        this.encryption = encryption;
    }
    async execute(file, password) {
        const payloads = file.items.map((item) => {
            if ((0, models_1.isEncryptedTransferPayload)(item)) {
                return new models_1.EncryptedPayload(item);
            }
            else if ((0, models_1.isDecryptedTransferPayload)(item)) {
                return new models_1.DecryptedPayload(item);
            }
            else {
                throw Error('Unhandled case in decryptBackupFile');
            }
        });
        const { encrypted, decrypted } = (0, models_1.CreatePayloadSplit)(payloads);
        const type = this.getBackupFileType(file, payloads);
        switch (type) {
            case encryption_1.BackupFileType.Corrupt:
                return new responses_1.ClientDisplayableError('Invalid backup file.');
            case encryption_1.BackupFileType.Encrypted: {
                if (!password) {
                    throw Error('Attempting to decrypt encrypted file with no password');
                }
                const keyParamsData = (file.keyParams || file.auth_params);
                const rootKey = await this.encryption.computeRootKey(password, (0, encryption_1.CreateAnyKeyParams)(keyParamsData));
                const results = await this.decryptEncrypted({
                    password,
                    payloads: encrypted,
                    rootKey,
                    keyParams: (0, encryption_1.CreateAnyKeyParams)(keyParamsData),
                });
                return [...decrypted, ...results];
            }
            case encryption_1.BackupFileType.EncryptedWithNonEncryptedItemsKey:
                return [...decrypted, ...(await this.decryptEncryptedWithNonEncryptedItemsKey(payloads))];
            case encryption_1.BackupFileType.FullyDecrypted:
                return [...decrypted, ...encrypted];
        }
    }
    getBackupFileType(file, payloads) {
        if (file.keyParams || file.auth_params) {
            return encryption_1.BackupFileType.Encrypted;
        }
        else {
            const hasEncryptedItem = payloads.find(models_1.isEncryptedPayload);
            const hasDecryptedItemsKey = payloads.find((payload) => payload.content_type === domain_core_1.ContentType.TYPES.ItemsKey && (0, models_1.isDecryptedPayload)(payload));
            if (hasEncryptedItem && hasDecryptedItemsKey) {
                return encryption_1.BackupFileType.EncryptedWithNonEncryptedItemsKey;
            }
            else if (!hasEncryptedItem) {
                return encryption_1.BackupFileType.FullyDecrypted;
            }
            else {
                return encryption_1.BackupFileType.Corrupt;
            }
        }
    }
    async decryptEncrypted(dto) {
        const results = [];
        const { rootKeyEncryption, itemsKeyEncryption } = (0, encryption_1.SplitPayloadsByEncryptionType)(dto.payloads);
        const rootKeyBasedDecryptionResults = await this.encryption.decryptSplit({
            usesRootKey: {
                items: rootKeyEncryption || [],
                key: dto.rootKey,
            },
        });
        (0, utils_1.extendArray)(results, rootKeyBasedDecryptionResults);
        const decryptedPayloads = await this.decrypt({
            payloads: itemsKeyEncryption || [],
            availableItemsKeys: rootKeyBasedDecryptionResults
                .filter(encryption_1.isItemsKey)
                .filter(models_1.isDecryptedPayload)
                .map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p)),
            keyParams: dto.keyParams,
            rootKey: dto.rootKey,
        });
        (0, utils_1.extendArray)(results, decryptedPayloads);
        return results;
    }
    async decryptEncryptedWithNonEncryptedItemsKey(payloads) {
        const decryptedItemsKeys = [];
        const encryptedPayloads = [];
        payloads.forEach((payload) => {
            if (payload.content_type === domain_core_1.ContentType.TYPES.ItemsKey && (0, models_1.isDecryptedPayload)(payload)) {
                decryptedItemsKeys.push(payload);
            }
            else if ((0, models_1.isEncryptedPayload)(payload)) {
                encryptedPayloads.push(payload);
            }
        });
        const itemsKeys = decryptedItemsKeys.map((p) => (0, models_1.CreateDecryptedItemFromPayload)(p));
        return this.decrypt({ payloads: encryptedPayloads, availableItemsKeys: itemsKeys, rootKey: undefined });
    }
    findKeyToUseForPayload(dto) {
        if ((0, models_1.ContentTypeUsesRootKeyEncryption)(dto.payload.content_type)) {
            if (!dto.rootKey) {
                throw new Error('Attempting to decrypt root key encrypted payload with no root key');
            }
            return dto.rootKey;
        }
        if ((0, models_1.ContentTypeUsesKeySystemRootKeyEncryption)(dto.payload.content_type)) {
            throw new Error('Backup file key system root key encryption is not supported');
        }
        let itemsKey;
        if (dto.payload.items_key_id) {
            itemsKey = this.encryption.itemsKeyForEncryptedPayload(dto.payload);
            if (itemsKey) {
                return itemsKey;
            }
        }
        itemsKey = dto.availableKeys.find((itemsKeyPayload) => {
            return dto.payload.items_key_id === itemsKeyPayload.uuid;
        });
        if (itemsKey) {
            return itemsKey;
        }
        if (!dto.keyParams) {
            return undefined;
        }
        const payloadVersion = dto.payload.version;
        /**
         * Payloads with versions <= 003 use root key directly for encryption.
         * However, if the incoming key params are >= 004, this means we should
         * have an items key based off the 003 root key. We can't use the 004
         * root key directly because it's missing dataAuthenticationKey.
         */
        if ((0, common_1.leftVersionGreaterThanOrEqualToRight)(dto.keyParams.version, common_1.ProtocolVersion.V004)) {
            itemsKey = this.encryption.defaultItemsKeyForItemVersion(payloadVersion, dto.availableKeys);
        }
        else if ((0, common_1.compareVersions)(payloadVersion, common_1.ProtocolVersion.V003) <= 0) {
            itemsKey = dto.rootKey;
        }
        return itemsKey;
    }
    async decrypt(dto) {
        const results = [];
        for (const encryptedPayload of dto.payloads) {
            try {
                const key = this.findKeyToUseForPayload({
                    payload: encryptedPayload,
                    availableKeys: dto.availableItemsKeys,
                    keyParams: dto.keyParams,
                    rootKey: dto.rootKey,
                });
                if (!key) {
                    results.push(encryptedPayload.copy({
                        errorDecrypting: true,
                    }));
                    continue;
                }
                if ((0, encryption_1.isItemsKey)(key) || (0, encryption_1.isKeySystemItemsKey)(key)) {
                    const decryptedPayload = await this.encryption.decryptSplitSingle({
                        usesItemsKey: {
                            items: [encryptedPayload],
                            key: key,
                        },
                    });
                    results.push(decryptedPayload);
                }
                else if ((0, models_1.isKeySystemRootKey)(key)) {
                    const decryptedPayload = await this.encryption.decryptSplitSingle({
                        usesKeySystemRootKey: {
                            items: [encryptedPayload],
                            key: key,
                        },
                    });
                    results.push(decryptedPayload);
                }
                else {
                    const decryptedPayload = await this.encryption.decryptSplitSingle({
                        usesRootKey: {
                            items: [encryptedPayload],
                            key: key,
                        },
                    });
                    results.push(decryptedPayload);
                }
            }
            catch (e) {
                results.push(encryptedPayload.copy({
                    errorDecrypting: true,
                }));
                console.error('Error decrypting payload', encryptedPayload, e);
            }
        }
        return results;
    }
}
exports.DecryptBackupFileUseCase = DecryptBackupFileUseCase;
