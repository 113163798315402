"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAllThemesUseCase = void 0;
const features_1 = require("@standardnotes/features");
const models_1 = require("@standardnotes/models");
class GetAllThemesUseCase {
    constructor(items) {
        this.items = items;
    }
    execute(options) {
        const nativeThemes = (0, features_1.GetNativeThemes)().filter((feature) => (options.excludeLayerable ? !feature.layerable : true));
        const allThirdPartyThemes = this.items
            .getDisplayableComponents()
            .filter((component) => component.isTheme() && (0, features_1.FindNativeTheme)(component.identifier) === undefined);
        const filteredThirdPartyThemes = allThirdPartyThemes.filter((theme) => {
            return options.excludeLayerable ? !theme.layerable : true;
        });
        return {
            thirdParty: filteredThirdPartyThemes.map((theme) => new models_1.UIFeature(theme)),
            native: nativeThemes.map((theme) => new models_1.UIFeature(theme)),
        };
    }
}
exports.GetAllThemesUseCase = GetAllThemesUseCase;
