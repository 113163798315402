"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteSharedVaultUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const DeleteVault_1 = require("../../Vaults/UseCase/DeleteVault");
class DeleteSharedVaultUseCase {
    constructor(sharedVaultServer, items, mutator, sync, encryption) {
        this.sharedVaultServer = sharedVaultServer;
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.encryption = encryption;
    }
    async execute(params) {
        const response = await this.sharedVaultServer.deleteSharedVault({
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to delete vault ${response}`);
        }
        const deleteUsecase = new DeleteVault_1.DeleteVaultUseCase(this.items, this.mutator, this.encryption);
        await deleteUsecase.execute(params.sharedVault);
        await this.sync.sync();
    }
}
exports.DeleteSharedVaultUseCase = DeleteSharedVaultUseCase;
