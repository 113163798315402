"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportModalController = void 0;
const snjs_1 = require("@standardnotes/snjs");
const ui_services_1 = require("@standardnotes/ui-services");
const mobx_1 = require("mobx");
class ImportModalController {
    constructor(application, navigationController) {
        this.application = application;
        this.navigationController = navigationController;
        this.isVisible = false;
        this.files = [];
        this.importTag = undefined;
        this.setIsVisible = (isVisible) => {
            this.isVisible = isVisible;
        };
        this.setFiles = (files, service) => {
            this.files = files.map((file) => ({
                id: snjs_1.UuidGenerator.GenerateUuid(),
                file,
                service,
                status: service ? 'ready' : 'pending',
            }));
        };
        this.updateFile = (file) => {
            this.files = this.files.map((f) => (f.id === file.id ? file : f));
        };
        this.removeFile = (id) => {
            this.files = this.files.filter((f) => f.id !== id);
        };
        this.setImportTag = (tag) => {
            this.importTag = tag;
        };
        this.close = () => {
            this.setIsVisible(false);
            if (this.importTag) {
                this.navigationController
                    .setSelectedTag(this.importTag, 'all', {
                    userTriggered: true,
                })
                    .catch(console.error);
            }
            this.setFiles([]);
            this.setImportTag(undefined);
        };
        this.importFromPayloads = async (file, payloads) => {
            this.updateFile({
                ...file,
                status: 'importing',
            });
            try {
                await this.importer.importFromTransferPayloads(payloads);
                const notesImported = payloads.filter((payload) => payload.content_type === snjs_1.ContentType.TYPES.Note);
                const tagsImported = payloads.filter((payload) => payload.content_type === snjs_1.ContentType.TYPES.Tag);
                const successMessage = `Successfully imported ${notesImported.length} ` +
                    (0, snjs_1.pluralize)(notesImported.length, 'note', 'notes') +
                    (tagsImported.length > 0 ? ` and ${tagsImported.length} ${(0, snjs_1.pluralize)(tagsImported.length, 'tag', 'tags')}` : '');
                this.updateFile({
                    ...file,
                    status: 'success',
                    successMessage,
                });
            }
            catch (error) {
                this.updateFile({
                    ...file,
                    status: 'error',
                    error: error instanceof Error ? error : new Error('Could not import file'),
                });
                console.error(error);
            }
        };
        this.parseAndImport = async () => {
            if (this.files.length === 0) {
                return;
            }
            const importedPayloads = [];
            for (const file of this.files) {
                if (!file.service) {
                    return;
                }
                if (file.status === 'ready' && file.payloads) {
                    await this.importFromPayloads(file, file.payloads);
                    importedPayloads.push(...file.payloads);
                    continue;
                }
                this.updateFile({
                    ...file,
                    status: 'parsing',
                });
                try {
                    const payloads = await this.importer.getPayloadsFromFile(file.file, file.service);
                    await this.importFromPayloads(file, payloads);
                    importedPayloads.push(...payloads);
                }
                catch (error) {
                    this.updateFile({
                        ...file,
                        status: 'error',
                        error: error instanceof Error ? error : new Error('Could not import file'),
                    });
                    console.error(error);
                }
            }
            const currentDate = new Date();
            const importTagItem = this.application.items.createTemplateItem(snjs_1.ContentType.TYPES.Tag, {
                title: `Imported on ${currentDate.toLocaleString()}`,
                expanded: false,
                iconString: '',
                references: importedPayloads
                    .filter((payload) => payload.content_type === snjs_1.ContentType.TYPES.Note)
                    .map((payload) => ({
                    content_type: snjs_1.ContentType.TYPES.Note,
                    uuid: payload.uuid,
                })),
            });
            const importTag = await this.application.mutator.insertItem(importTagItem);
            if (importTag) {
                this.setImportTag(importTag);
            }
        };
        (0, mobx_1.makeObservable)(this, {
            isVisible: mobx_1.observable,
            setIsVisible: mobx_1.action,
            files: mobx_1.observable,
            setFiles: mobx_1.action,
            updateFile: mobx_1.action,
            removeFile: mobx_1.action,
            importTag: mobx_1.observable,
            setImportTag: mobx_1.action,
        });
        this.importer = new ui_services_1.Importer(application);
    }
}
exports.ImportModalController = ImportModalController;
