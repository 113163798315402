"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetAsymmetricMessageTrustedPayload = void 0;
class GetAsymmetricMessageTrustedPayload {
    constructor(encryption, contacts) {
        this.encryption = encryption;
        this.contacts = contacts;
    }
    execute(dto) {
        const trustedContact = this.contacts.findTrustedContact(dto.message.sender_uuid);
        if (!trustedContact) {
            return undefined;
        }
        const decryptionResult = this.encryption.asymmetricallyDecryptMessage({
            encryptedString: dto.message.encrypted_message,
            trustedSender: trustedContact,
            privateKey: dto.privateKey,
        });
        return decryptionResult;
    }
}
exports.GetAsymmetricMessageTrustedPayload = GetAsymmetricMessageTrustedPayload;
