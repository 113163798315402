"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConvertToSharedVaultUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const MoveItemsToVault_1 = require("../../Vaults/UseCase/MoveItemsToVault");
class ConvertToSharedVaultUseCase {
    constructor(items, mutator, sync, files, sharedVaultServer) {
        this.items = items;
        this.mutator = mutator;
        this.sync = sync;
        this.files = files;
        this.sharedVaultServer = sharedVaultServer;
    }
    async execute(dto) {
        if (dto.vault.isSharedVaultListing()) {
            throw new Error('Cannot convert a shared vault to a shared vault');
        }
        const serverResult = await this.sharedVaultServer.createSharedVault();
        if ((0, responses_1.isErrorResponse)(serverResult)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to create shared vault ${serverResult}`);
        }
        const serverVaultHash = serverResult.data.sharedVault;
        const sharedVaultListing = await this.mutator.changeItem(dto.vault, (mutator) => {
            mutator.sharing = {
                sharedVaultUuid: serverVaultHash.uuid,
                ownerUserUuid: serverVaultHash.user_uuid,
            };
        });
        const vaultItems = this.items.itemsBelongingToKeySystem(sharedVaultListing.systemIdentifier);
        const moveToVaultUsecase = new MoveItemsToVault_1.MoveItemsToVaultUseCase(this.mutator, this.sync, this.files);
        await moveToVaultUsecase.execute({ vault: sharedVaultListing, items: vaultItems });
        return sharedVaultListing;
    }
}
exports.ConvertToSharedVaultUseCase = ConvertToSharedVaultUseCase;
