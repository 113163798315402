"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserEventService = void 0;
const SyncEvent_1 = require("../Event/SyncEvent");
const AbstractService_1 = require("../Service/AbstractService");
const UserEventServiceEvent_1 = require("./UserEventServiceEvent");
class UserEventService extends AbstractService_1.AbstractService {
    constructor(internalEventBus) {
        super(internalEventBus);
        internalEventBus.addEventHandler(this, SyncEvent_1.SyncEvent.ReceivedUserEvents);
    }
    async handleEvent(event) {
        if (event.type === SyncEvent_1.SyncEvent.ReceivedUserEvents) {
            return this.handleReceivedUserEvents(event.payload);
        }
    }
    async handleReceivedUserEvents(userEvents) {
        if (userEvents.length === 0) {
            return;
        }
        for (const serverEvent of userEvents) {
            const serviceEvent = {
                eventPayload: JSON.parse(serverEvent.event_payload),
            };
            await this.notifyEventSync(UserEventServiceEvent_1.UserEventServiceEvent.UserEventReceived, serviceEvent);
        }
    }
}
exports.UserEventService = UserEventService;
