"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotifySharedVaultUsersOfRootKeyRotationUseCase = void 0;
const ReuploadSharedVaultInvitesAfterKeyRotation_1 = require("./ReuploadSharedVaultInvitesAfterKeyRotation");
const SendSharedVaultRootKeyChangedMessageToAll_1 = require("./SendSharedVaultRootKeyChangedMessageToAll");
class NotifySharedVaultUsersOfRootKeyRotationUseCase {
    constructor(sharedVaultUsersServer, sharedVaultInvitesServer, messageServer, encryption, contacts) {
        this.sharedVaultUsersServer = sharedVaultUsersServer;
        this.sharedVaultInvitesServer = sharedVaultInvitesServer;
        this.messageServer = messageServer;
        this.encryption = encryption;
        this.contacts = contacts;
    }
    async execute(params) {
        const errors = [];
        const updatePendingInvitesUseCase = new ReuploadSharedVaultInvitesAfterKeyRotation_1.ReuploadSharedVaultInvitesAfterKeyRotationUseCase(this.encryption, this.contacts, this.sharedVaultInvitesServer, this.sharedVaultUsersServer);
        const updateExistingResults = await updatePendingInvitesUseCase.execute({
            sharedVault: params.sharedVault,
            senderUuid: params.userUuid,
            senderEncryptionKeyPair: this.encryption.getKeyPair(),
            senderSigningKeyPair: this.encryption.getSigningKeyPair(),
        });
        errors.push(...updateExistingResults);
        const shareKeyUseCase = new SendSharedVaultRootKeyChangedMessageToAll_1.SendSharedVaultRootKeyChangedMessageToAll(this.encryption, this.contacts, this.sharedVaultUsersServer, this.messageServer);
        const shareKeyResults = await shareKeyUseCase.execute({
            keySystemIdentifier: params.sharedVault.systemIdentifier,
            sharedVaultUuid: params.sharedVault.sharing.sharedVaultUuid,
            senderUuid: params.userUuid,
            senderEncryptionKeyPair: this.encryption.getKeyPair(),
            senderSigningKeyPair: this.encryption.getSigningKeyPair(),
        });
        errors.push(...shareKeyResults);
        return errors;
    }
}
exports.NotifySharedVaultUsersOfRootKeyRotationUseCase = NotifySharedVaultUsersOfRootKeyRotationUseCase;
