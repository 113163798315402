"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateNewItemsKeyWithRollbackUseCase = void 0;
const encryption_1 = require("@standardnotes/encryption");
const CreateNewDefaultItemsKey_1 = require("./CreateNewDefaultItemsKey");
const RemoveItemsLocally_1 = require("../../../UseCase/RemoveItemsLocally");
class CreateNewItemsKeyWithRollbackUseCase {
    constructor(mutator, items, storage, operatorManager, rootKeyManager) {
        this.mutator = mutator;
        this.items = items;
        this.storage = storage;
        this.operatorManager = operatorManager;
        this.rootKeyManager = rootKeyManager;
        this.createDefaultItemsKeyUseCase = new CreateNewDefaultItemsKey_1.CreateNewDefaultItemsKeyUseCase(this.mutator, this.items, this.operatorManager, this.rootKeyManager);
        this.removeItemsLocallyUsecase = new RemoveItemsLocally_1.RemoveItemsLocallyUseCase(this.items, this.storage);
    }
    async execute() {
        const currentDefaultItemsKey = (0, encryption_1.findDefaultItemsKey)(this.items.getDisplayableItemsKeys());
        const newDefaultItemsKey = await this.createDefaultItemsKeyUseCase.execute();
        const rollback = async () => {
            await this.removeItemsLocallyUsecase.execute([newDefaultItemsKey]);
            if (currentDefaultItemsKey) {
                await this.mutator.changeItem(currentDefaultItemsKey, (mutator) => {
                    mutator.isDefault = true;
                });
            }
        };
        return rollback;
    }
}
exports.CreateNewItemsKeyWithRollbackUseCase = CreateNewItemsKeyWithRollbackUseCase;
