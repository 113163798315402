"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReuploadSharedVaultInvitesAfterKeyRotationUseCase = void 0;
const responses_1 = require("@standardnotes/responses");
const InviteContactToSharedVault_1 = require("./InviteContactToSharedVault");
const GetSharedVaultTrustedContacts_1 = require("./GetSharedVaultTrustedContacts");
class ReuploadSharedVaultInvitesAfterKeyRotationUseCase {
    constructor(encryption, contacts, vaultInvitesServer, vaultUserServer) {
        this.encryption = encryption;
        this.contacts = contacts;
        this.vaultInvitesServer = vaultInvitesServer;
        this.vaultUserServer = vaultUserServer;
    }
    async execute(params) {
        const keySystemRootKey = this.encryption.keys.getPrimaryKeySystemRootKey(params.sharedVault.systemIdentifier);
        if (!keySystemRootKey) {
            throw new Error(`Vault key not found for keySystemIdentifier ${params.sharedVault.systemIdentifier}`);
        }
        const existingInvites = await this.getExistingInvites(params.sharedVault.sharing.sharedVaultUuid);
        if ((0, responses_1.isClientDisplayableError)(existingInvites)) {
            return [existingInvites];
        }
        const deleteResult = await this.deleteExistingInvites(params.sharedVault.sharing.sharedVaultUuid);
        if ((0, responses_1.isClientDisplayableError)(deleteResult)) {
            return [deleteResult];
        }
        const vaultContacts = await this.getVaultContacts(params.sharedVault);
        if (vaultContacts.length === 0) {
            return [];
        }
        const errors = [];
        for (const invite of existingInvites) {
            const contact = this.contacts.findTrustedContact(invite.user_uuid);
            if (!contact) {
                errors.push(responses_1.ClientDisplayableError.FromString(`Contact not found for invite ${invite.user_uuid}`));
                continue;
            }
            const result = await this.sendNewInvite({
                usecaseDTO: params,
                contact: contact,
                previousInvite: invite,
                keySystemRootKeyData: keySystemRootKey.content,
                sharedVaultContacts: vaultContacts,
            });
            if ((0, responses_1.isClientDisplayableError)(result)) {
                errors.push(result);
            }
        }
        return errors;
    }
    async getVaultContacts(sharedVault) {
        const usecase = new GetSharedVaultTrustedContacts_1.GetSharedVaultTrustedContacts(this.contacts, this.vaultUserServer);
        const contacts = await usecase.execute(sharedVault);
        if (!contacts) {
            return [];
        }
        return contacts;
    }
    async getExistingInvites(sharedVaultUuid) {
        const response = await this.vaultInvitesServer.getOutboundUserInvites();
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to get outbound user invites ${response}`);
        }
        const invites = response.data.invites;
        return invites.filter((invite) => invite.shared_vault_uuid === sharedVaultUuid);
    }
    async deleteExistingInvites(sharedVaultUuid) {
        const response = await this.vaultInvitesServer.deleteAllSharedVaultInvites({
            sharedVaultUuid: sharedVaultUuid,
        });
        if ((0, responses_1.isErrorResponse)(response)) {
            return responses_1.ClientDisplayableError.FromString(`Failed to delete existing invites ${response}`);
        }
    }
    async sendNewInvite(params) {
        const signatureResult = this.encryption.asymmetricSignatureVerifyDetached(params.previousInvite.encrypted_message);
        if (!signatureResult.signatureVerified) {
            return responses_1.ClientDisplayableError.FromString('Failed to verify signature of previous invite');
        }
        if (signatureResult.signaturePublicKey !== params.usecaseDTO.senderSigningKeyPair.publicKey) {
            return responses_1.ClientDisplayableError.FromString('Sender public key does not match signature');
        }
        const usecase = new InviteContactToSharedVault_1.InviteContactToSharedVaultUseCase(this.encryption, this.vaultInvitesServer);
        const result = await usecase.execute({
            senderKeyPair: params.usecaseDTO.senderEncryptionKeyPair,
            senderSigningKeyPair: params.usecaseDTO.senderSigningKeyPair,
            sharedVault: params.usecaseDTO.sharedVault,
            sharedVaultContacts: params.sharedVaultContacts,
            recipient: params.contact,
            permissions: params.previousInvite.permissions,
        });
        if ((0, responses_1.isClientDisplayableError)(result)) {
            return result;
        }
    }
}
exports.ReuploadSharedVaultInvitesAfterKeyRotationUseCase = ReuploadSharedVaultInvitesAfterKeyRotationUseCase;
